import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';

class NotFound extends Component {
    render() {
        return (
            <main className="page" role="main">
                <Helmet>
                    <title>404 Page not found</title>
                </Helmet>
                <header className="text-header || constrain-width">
                    <h1>I’m sorry, we couldn’t find that page.</h1>
                    <p>Not to worry though, you can either head to our homepage or get in touch so we can help find what you are looking for.</p>
                    <Link className="button" to="/">View homepage</Link>
                </header>
            </main>
        );
    }
}

export default NotFound;
