import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Button extends Component {
    render() {
        const { title, url } = this.props;

        return (
            <section className="button-content-block || content-section || constrain-width">
                <a className="button primary large" href={url}>{title}</a>
            </section>
        );
    }
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default Button;
