import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={39} height={39} viewBox="0 0 39 39" {...props}>
        <title>
            Size
        </title>
        <path fill="currentColor" d="M30.5,27.9v0.8c0,0.4-0.4,0.8-0.8,0.8h-0.8c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8c0-0.4,0.4-0.8,0.8-0.8 C30.1,27.1,30.5,27.4,30.5,27.9z M29.7,17.4c-0.4,0-0.8,0.4-0.8,0.8v1.7c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-1.7 C30.5,17.7,30.1,17.4,29.7,17.4z M29.7,12.5c-0.4,0-0.8,0.4-0.8,0.8V15c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-1.7 C30.5,12.9,30.1,12.5,29.7,12.5z M29.7,22.2c-0.4,0-0.8,0.4-0.8,0.8v1.7c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8V23 C30.5,22.6,30.1,22.2,29.7,22.2z M29.7,8.5h-0.8c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8c0,0.4,0.4,0.8,0.8,0.8 c0.4,0,0.8-0.4,0.8-0.8V9.3C30.5,8.9,30.1,8.5,29.7,8.5z M24,10.1h1.7c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8H24 c-0.4,0-0.8,0.4-0.8,0.8S23.6,10.1,24,10.1z M14.3,10.1H16c0.4,0,0.8-0.4,0.8-0.8S16.4,8.5,16,8.5h-1.7c-0.4,0-0.8,0.4-0.8,0.8 S13.9,10.1,14.3,10.1z M19.2,10.1h1.7c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-1.7c-0.4,0-0.8,0.4-0.8,0.8S18.7,10.1,19.2,10.1z  M10.3,10.9c0.4,0,0.8-0.4,0.8-0.8c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-0.8c-0.4,0-0.8,0.4-0.8,0.8v0.8 C9.5,10.6,9.9,10.9,10.3,10.9z M10.3,15.8c0.4,0,0.8-0.4,0.8-0.8v-1.7c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8V15 C9.5,15.4,9.9,15.8,10.3,15.8z M25.7,27.9H24c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h1.7c0.4,0,0.8-0.4,0.8-0.8 S26.1,27.9,25.7,27.9z M26.9,11.8l-5,0.6c-0.2,0-0.3,0.2-0.1,0.3l1.7,1.7l-5.4,5.4h-7v-1.6c0-0.4-0.4-0.8-0.8-0.8 c-0.4,0-0.8,0.4-0.8,0.8v1.7c0,0.1,0,0.3,0.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.4V23v1.7v3.2v0.8c0,0.4,0.4,0.8,0.8,0.8h0.8h3.2H16h2.4 c0.1,0,0.3,0,0.4-0.1c0.1,0.1,0.2,0.1,0.4,0.1h1.7c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-1.6v-7l5.4-5.4l1.7,1.7 c0.2,0.2,0.3,0.1,0.3-0.1l0.6-5C27.2,11.9,27.1,11.8,26.9,11.8z" />
    </svg>
);
