import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <title>
            u-shape
        </title>
        <defs>
            <path id="a" d="M.081.519h5.482V6H.081z" />
            <path id="c" d="M.824.518h5.481V6H.824z" />
            <path id="e" d="M.567.518h5.481V6H.567z" />
            <path id="g" d="M.841.518h5.481V6H.841z" />
            <path id="i" d="M.461.518h5.481V6H.461z" />
            <path id="k" d="M.162.954h5.481v5.481H.162z" />
            <path id="m" d="M.162.454h5.481v5.481H.162z" />
            <path id="o" d="M.162.954h5.481v5.481H.162z" />
            <path id="q" d="M.162.454h5.481v5.481H.162z" />
            <path id="s" d="M.162.954h5.481v5.481H.162z" />
            <path id="u" d="M.162.454h5.481v5.481H.162z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="currentColor" d="M50.488 11.53v39.832a1.53 1.53 0 0 1-1.53 1.529H30.684a1.53 1.53 0 0 1-1.529-1.53V11.53a1.53 1.53 0 0 0-1.53-1.53h-7.774a1.53 1.53 0 0 0-1.53 1.53v50.665c0 .845.685 1.53 1.53 1.53h39.941a1.53 1.53 0 0 0 1.53-1.53V11.53a1.53 1.53 0 0 0-1.53-1.53h-7.774a1.53 1.53 0 0 0-1.53 1.53m-34.007 1.21a2.74 2.74 0 1 1-5.481.001 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0" />
            <g transform="translate(37 65.047)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path fill="currentColor" d="M5.555 3.06a2.742 2.742 0 1 1-5.468.398 2.742 2.742 0 0 1 5.468-.398" mask="url(#b)" />
            </g>
            <g transform="translate(27 65.047)">
                <mask id="d" fill="#fff">
                    <use xlinkHref="#c" />
                </mask>
                <path fill="currentColor" d="M6.305 3.26a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0" mask="url(#d)" />
            </g>
            <g transform="translate(18 65.047)">
                <mask id="f" fill="#fff">
                    <use xlinkHref="#e" />
                </mask>
                <path fill="currentColor" d="M6.048 3.26a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0" mask="url(#f)" />
            </g>
            <g transform="translate(55 65.047)">
                <mask id="h" fill="#fff">
                    <use xlinkHref="#g" />
                </mask>
                <path fill="currentColor" d="M6.322 3.26a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0" mask="url(#h)" />
            </g>
            <g transform="translate(46 65.047)">
                <mask id="j" fill="#fff">
                    <use xlinkHref="#i" />
                </mask>
                <path fill="currentColor" d="M5.942 3.26a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0" mask="url(#j)" />
            </g>
            <g transform="translate(63 9.046)">
                <mask id="l" fill="#fff">
                    <use xlinkHref="#k" />
                </mask>
                <path fill="currentColor" d="M5.643 3.694a2.74 2.74 0 1 1-5.48 0 2.74 2.74 0 0 1 5.48 0" mask="url(#l)" />
            </g>
            <g transform="translate(63 19.046)">
                <mask id="n" fill="#fff">
                    <use xlinkHref="#m" />
                </mask>
                <path fill="currentColor" d="M5.643 3.194a2.74 2.74 0 1 1-5.48 0 2.74 2.74 0 0 1 5.48 0" mask="url(#n)" />
            </g>
            <g transform="translate(63 28.047)">
                <mask id="p" fill="#fff">
                    <use xlinkHref="#o" />
                </mask>
                <path fill="currentColor" d="M5.643 3.694a2.74 2.74 0 1 1-5.48 0 2.74 2.74 0 0 1 5.48 0" mask="url(#p)" />
            </g>
            <g transform="translate(63 38.047)">
                <mask id="r" fill="#fff">
                    <use xlinkHref="#q" />
                </mask>
                <path fill="currentColor" d="M5.643 3.194a2.74 2.74 0 1 1-5.48 0 2.74 2.74 0 0 1 5.48 0" mask="url(#r)" />
            </g>
            <g transform="translate(63 47.047)">
                <mask id="t" fill="#fff">
                    <use xlinkHref="#s" />
                </mask>
                <path fill="currentColor" d="M5.643 3.694a2.74 2.74 0 1 1-5.48 0 2.74 2.74 0 0 1 5.48 0" mask="url(#t)" />
            </g>
            <g transform="translate(63 57.047)">
                <mask id="v" fill="#fff">
                    <use xlinkHref="#u" />
                </mask>
                <path fill="currentColor" d="M5.643 3.194a2.74 2.74 0 1 1-5.48 0 2.74 2.74 0 0 1 5.48 0" mask="url(#v)" />
            </g>
        </g>
    </svg>
);
