import PropTypes from 'prop-types';
import React from 'react';

export const YOUTUBE_STATE = {
    BUFFERING: 3,
    CUED: 5,
    ENDED: 0,
    PAUSED: 2,
    PLAYING: 1,
    UNSTARTED: -1
};

class YoutubePlayer extends React.Component {
    constructor(props) {
        super(props);

        this.youTubePlayer = null;

        this.onPlayerReady = this.onPlayerReady.bind(this);
    }


    // Life Cycle
    componentDidMount() {
        // Creates a new youtube player once the API is ready
        const { url } = this.props;

        const videoId = url.replace(/.+\/?v(?:=|\/)([^&]+)/gi, '$1');

        const mountTheVideo = () => {
            this.youTubePlayer = new window.YT.Player('player', {
                videoId,
                host: 'https://www.youtube.com',
                playerVars: {
                    autoplay: 1,
                    controls: 0,
                    loop: 1,
                    showinfo: 0,
                    mute: 1,
                    playlist: videoId
                },
                events: {
                    onReady: this.onPlayerReady
                }
            });
        };

        if (window.YT && window.YT.Player) {
            mountTheVideo();
        } else {
            window.onYouTubeIframeAPIReady = mountTheVideo;
        }
    }

    componentWillUnmount() {
        this.youTubePlayer.destroy();
    }


    // Helpers
    onPlayerReady(event) {
        event.target.playVideo();
    }


    // Render
    render() {
        return (
            <div className="youtube-player">
                <div id="player" className="youtube-player-frame"></div>
            </div>
        );
    }
}

YoutubePlayer.propTypes = {
    url: PropTypes.string.isRequired
};

export default YoutubePlayer;
