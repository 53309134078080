import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Plus
        </title>
        <path fill="currentColor" fillRule="evenodd" d="M17.645 6h5.91v11.703H35.2v5.91H23.555v11.703h-5.91V23.613H6v-5.91h11.645z" />
    </svg>
);
