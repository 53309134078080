import PropTypes from 'prop-types';
import React from 'react';
import Tile from 'app/components/partials/tile';

class ContentSet extends React.Component {
    renderItem(item, index) {
        const {
            name,
            short_description: description,
            thumbnail,
            featured
        } = item.data;

        return (
            <Tile
                key={index}
                item={item}
                title={name}
                description={description}
                thumbnail={thumbnail}
                featured={featured}
            />
        );
    }

    renderItems(items) {
        return (
            <div className="tile-block-inner">
                {items.map((item, index) => {
                    return this.renderItem(item, index);
                })}
            </div>
        );
    }

    render() {
        const { items, title } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
            <section className="tile-block || block-section">
                { title &&
                    <header className="section-header || constrain-width">
                        <h2 className="heading-1">{title}</h2>
                    </header>
                }
                <div className="constrain-width">
                    {this.renderItems(items)}
                </div>
            </section>
        );
    }
}

ContentSet.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string
};

export default ContentSet;
