import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Close
        </title>
        <path fill="currentColor" d="M35 8l-3-3-12 12L8 5 5 8l12 12L5 32l3 3 12-12 12 12 3-3-12-12z" />
    </svg>
);
