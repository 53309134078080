import classnames from 'classnames';
import { clientEnv } from 'config/env';
import Headroom from 'react-headroom';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import logo from 'static/images/auckland-conventions-logo.svg';
import Navigation from 'app/components/partials/navigation';
import NotificationBanner from 'app/components/partials/notification-banner.container';
import PropTypes from 'prop-types';
import React from 'react';
import Search from 'app/components/partials/search';
import updateBody from 'app/utilities/update-body';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.defaultState();
    }

    defaultState() {
        return {
            isNavigationActive: false,
            isSearchActive: false,
            navigationItemOpen: null
        };
    }


    // Life Cycle
    componentDidMount() {
        if (this.header) {
            window.addEventListener('scroll', this.scrollListener);
        }

        this.props.fetchNotifications();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.status.isRouting) {
            // Reset Header State
            this.setState(this.defaultState(), updateBody(false));

            // Scroll to top only if location has no hash
            if (!window.location.hash) {
                window.scrollTo(0, 0);
            }
        }
    }


    // Handler
    handleToggleNavigation() {
        let { navigationItemsOpen } = this.state;
        const { isNavigationActive } = this.state;

        // On close reset child navigation items
        if (isNavigationActive) {
            navigationItemsOpen = this.defaultState().navigationItemsOpen;
        }

        this.setState({
            isNavigationActive: !isNavigationActive,
            navigationItemOpen: navigationItemsOpen
        }, updateBody(!isNavigationActive));
    }

    handleToggleSearch() {
        const { isSearchActive } = this.state;

        this.setState({
            isSearchActive: !isSearchActive,
        }, updateBody(!isSearchActive));
    }

    handleUpdateNavigationItem(index = null) {
        this.setState({ navigationItemOpen: index });
    }

    handleCloseNavigation() {
        this.resetHeaderState();
    }


    // Helper
    resetHeaderState() {
        this.setState(this.defaultState());
    }


    // Render
    render() {
        const {
            isNavigationActive,
            isSearchActive,
            navigationItemOpen
        } = this.state;
        const {
            location,
            notifications,
            isNotificationsActive,
            toggleNotifications
        } = this.props;

        // Disable the drawer behavior of the header of the /capacities page.
        const headroomIsDisabled = location.pathname === '/capacities';

        const bellButtonIsVisible = notifications.length > 0;

        return (
            <Headroom
                disable={headroomIsDisabled}
                downTolerance={20} // Scroll tolerance in px when scrolling down before component is pinned
                disableInlineStyles={true}
                className={classnames({ 'is-unfixed': headroomIsDisabled })}
            >
                {isNotificationsActive && <NotificationBanner notifications={notifications} />}
                <header className="header" ref={(header) => { this.header = header; }}>
                    <div className="header-inner || constrain-width">
                        <Link className="logo" to="/">
                            <img className="image" src={logo} width="145" height="51" alt="Auckland Conventions" title="Auckland Conventions Logo"/>
                        </Link>
                        <div className="header-side-actions">
                            {bellButtonIsVisible && (
                                <button
                                    type="button"
                                    aria-label="Toggle Notifications"
                                    className={classnames('icon-button || header-notification-toggle', {
                                        'is-active': isNotificationsActive
                                    })}
                                    onClick={() => toggleNotifications()}>
                                    <Icon name="bell" title="Notifications" />
                                </button>
                            )}
                        </div>
                        <Navigation
                            isActive={isNavigationActive}
                            itemOpen={navigationItemOpen}
                            toggleActive={this.handleToggleNavigation.bind(this)}
                            updateOpenItem={this.handleUpdateNavigationItem.bind(this)}
                        />
                    </div>
                    <Search
                        isActive={isSearchActive}
                        toggleActive={this.handleToggleSearch.bind(this)}
                    />
                </header>
            </Headroom>
        );
    }
}

Header.propTypes = {
    location: PropTypes.object.isRequired,
    status: PropTypes.shape({
        isRouting: PropTypes.bool.isRequired
    }).isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    isNotificationsActive: PropTypes.bool.isRequired,
    toggleNotifications: PropTypes.func.isRequired
};

export default Header;
