import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={39} height={39} viewBox="0 0 39 39" {...props}>
        <title>
            Height
        </title>
        <polygon fill="currentColor" points="26,8.5 14,8.5 14,10.1 19.9,10.1 15.7,14.3 16.9,15.5 19.1,13.3 19.1,24.8 16.9,22.6 15.7,23.8 19.9,28 14,28 14,29.6 26,29.6 26,28 20,28 24.2,23.8 23,22.6 20.8,24.8 20.8,13.3 23,15.5 24.2,14.3 20,10.1 26,10.1 " />
    </svg>
);
