/* eslint-disable require-jsdoc */
import canUseDom from './utilities/dom';
import { configureStore } from './store';
import { ENTITY_TYPE } from './utilities/url';
import { HistoryRouter } from 'redux-first-history/rr6';
import { Provider } from 'react-redux';
import React from 'react';
import Routes from './router';
import { StaticRouter } from 'react-router-dom/server';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { fetchRoomsSuccess, formatResult } from './ducks/rooms';
import { filterResultFetchSuccessAction, formatFilterResults } from './ducks/filter';

// Import styles
import './sass/index';

export default function getApp(context, url, initialState, extraState) {
    const history = canUseDom() ?
        createBrowserHistory() :
        createMemoryHistory({ initialEntries: [url] });

    const { reduxStore, reduxHistory } = configureStore(initialState, history);

    /**
     * Re-build the correct state for SSR
     */
    if (context === 'server' && extraState) {
        // Dispatch different action here to setup the extra state

        // For the `rooms` state
        if (extraState[ENTITY_TYPE.ROOMS]) {
            const newRoomsAction = fetchRoomsSuccess('', formatResult(extraState[ENTITY_TYPE.ROOMS]));
            reduxStore.dispatch(newRoomsAction);
        }

        // For the `filter` state
        if (extraState[ENTITY_TYPE.ROOMS_FILTER]) {
            const queryParams = extraState[ENTITY_TYPE.ROOMS_FILTER].queryParams;
            const eventType = queryParams && queryParams.event_type ? queryParams.event_type : '';
            const capacity = queryParams && queryParams.capacity ? queryParams.capacity : '';
            const layoutType = queryParams && queryParams.layout_type ? queryParams.layout_type : '';
            const newFilterAction = filterResultFetchSuccessAction(eventType, capacity, layoutType, formatFilterResults(extraState[ENTITY_TYPE.ROOMS_FILTER]));

            // Super important here: Make sure set this `eventType` manually, (it's bug of the `filter.js` state management which should be improve) !!!
            newFilterAction.eventType = eventType;

            reduxStore.dispatch(newFilterAction);
        }
    }

    const Router = {
        server: StaticRouter,
        client: HistoryRouter
    }[context];

    const routerProps = {
        server: { context: {}, location: url },
        client: { history: reduxHistory }
    }[context];

    const app = (
        <Provider store={reduxStore}>
            <Router {...routerProps}>
                <Routes />
            </Router>
        </Provider>
    );

    return {
        app: app,
        latestState: reduxStore.getState()
    };
}
