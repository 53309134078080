import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Menu
        </title>
        <path d="M35.056 9.988H3.976c-1.166 0-1.943-.777-1.943-1.942 0-1.166.777-1.943 1.942-1.943h31.08c1.166 0 1.943.777 1.943 1.943 0 1.165-.777 1.942-1.942 1.942zm0 23.31H3.976c-1.166 0-1.943-.777-1.943-1.942 0-1.166.777-1.943 1.942-1.943h31.08c1.166 0 1.943.777 1.943 1.943 0 1.165-.777 1.942-1.942 1.942zm0-11.655H3.976c-1.166 0-1.943-.777-1.943-1.942 0-1.166.777-1.943 1.942-1.943h31.08c1.166 0 1.943.777 1.943 1.943 0 1.165-.777 1.942-1.942 1.942z" fill="currentColor" />
    </svg>
);
