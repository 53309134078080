export const CAPACITY_RANGES = [
    {
        label: '0 - 100',
        value: '0,100'
    },
    {
        label: '100 - 250',
        value: '100,250'
    },
    {
        label: '250 - 500',
        value: '250,500'
    },
    {
        label: '500 - 1000',
        value: '500,1000'
    },
    {
        label: '1000+',
        value: '1000,999999'
    }
];

export const DEFAULT_CAPACITY_RANGE = {
    label: 'any capacity',
    value: ''
};

export const EVENT_TYPES = [
    {
        label: 'any event type',
        value: '',
        apiLabel: undefined,
        allowsLayoutTypeFilter: false,
    },
    {
        label: 'christmas party',
        value: 'christmas-party',
        apiLabel: 'Christmas party',
        allowsLayoutTypeFilter: false,
    },
    {
        label: 'cocktail function',
        value: 'cocktail-function',
        apiLabel: 'Cocktail function',
        allowsLayoutTypeFilter: false,
    },
    {
        label: 'conference',
        value: 'conference',
        apiLabel: 'Conference',
        allowsLayoutTypeFilter: true,
    },
    {
        label: 'gala dinner',
        value: 'gala-dinner',
        apiLabel: 'Gala dinner',
        allowsLayoutTypeFilter: false,
    },
    {
        label: 'meeting',
        value: 'meeting',
        apiLabel: 'Meeting',
        allowsLayoutTypeFilter: true,
    },
    {
        label: 'school ball',
        value: 'school-ball',
        apiLabel: 'School ball',
        allowsLayoutTypeFilter: false,
    },
    {
        label: 'wedding',
        value: 'wedding',
        apiLabel: 'Wedding',
        allowsLayoutTypeFilter: false,
    }
];

export const LAYOUT_TYPES = [
    {
        // Default
        label: 'View All',
        value: '',
        apiLabel: null,
        icon: null
    },
    {
        label: 'Cabaret',
        value: 'cabaret',
        apiLabel: 'Cabaret', // API accepts uppercase
        icon: 'cabaret'
    },
    {
        label: 'Theatre',
        value: 'theatre',
        apiLabel: 'Theatre',
        icon: 'theatre'
    },
    {
        label: 'U-Shape',
        value: 'u-shape',
        apiLabel: 'U-Shape',
        icon: 'u-shape'
    },
    {
        label: 'Classroom',
        value: 'classroom',
        apiLabel: 'Classroom',
        icon: 'classroom'
    },
    {
        label: 'Boardroom',
        value: 'boardroom',
        apiLabel: 'Boardroom',
        icon: 'boardroom'
    }
];

export const EVENT_TYPES_WITH_LAYOUT_VARIATIONS = EVENT_TYPES.reduce((results, eventType) => {
    if (eventType.allowsLayoutTypeFilter === true) results.push(eventType.value);

    return results;
}, []);
