import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
        <title>
            Plus (capacity)
        </title>
        <polygon fill="currentColor" fillRule="evenodd" points="22 16 16 16 16 22 14 22 14 16 8 16 8 14 14 14 14 8 16 8 16 14 22 14" />
    </svg>
);
