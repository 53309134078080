import PropTypes from 'prop-types';
import React from 'react';

const PRE = 2;

class Data extends React.Component {
    render() {
        const { data, title } = this.props;

        return (
            <div className="placeholder-component">
                <code>{title}</code>
                {data && <pre>{JSON.stringify(data, null, PRE)}</pre>}
            </div>
        );
    }
}

Data.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string.isRequired
};

export default Data;
