import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <title>
            classroom
        </title>
        <defs>
            <path id="a" d="M.945.246h19.906v7.833H.945z" />
            <path id="c" d="M.945.163h19.906v7.833H.945z" />
            <path id="e" d="M.945.13h19.906v7.833H.945z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="currentColor" d="M22.198 24.898a3.884 3.884 0 0 1-3.885 3.884 3.883 3.883 0 1 1 0-7.768 3.884 3.884 0 0 1 3.885 3.884m10.833 0a3.884 3.884 0 1 1-7.77 0 3.884 3.884 0 0 1 7.77 0m21.335 0a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884m10.833 0a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884M22.198 44.797a3.884 3.884 0 0 1-3.885 3.884 3.883 3.883 0 1 1 0-7.768 3.884 3.884 0 0 1 3.885 3.884m10.833 0a3.884 3.884 0 1 1-7.77 0 3.884 3.884 0 0 1 7.77 0m21.335 0a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884m10.833 0a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884M22.198 64.87a3.884 3.884 0 0 1-3.885 3.884 3.883 3.883 0 1 1 0-7.768 3.884 3.884 0 0 1 3.885 3.884m10.833 0a3.884 3.884 0 1 1-7.77 0 3.884 3.884 0 0 1 7.77 0m21.335 0a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884m10.833 0a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768A3.884 3.884 0 0 1 65.2 64.87M32.033 19.833h-16.16A1.873 1.873 0 0 1 14 17.96v-4.087c0-1.035.838-1.873 1.872-1.873h16.161c1.034 0 1.873.838 1.873 1.873v4.087a1.874 1.874 0 0 1-1.873 1.873m0 19.917h-16.16A1.872 1.872 0 0 1 14 37.877V33.79c0-1.035.838-1.873 1.872-1.873h16.161c1.034 0 1.873.838 1.873 1.873v4.087a1.873 1.873 0 0 1-1.873 1.873m0 19.967h-16.16A1.872 1.872 0 0 1 14 57.844v-4.087c0-1.035.838-1.873 1.872-1.873h16.161c1.034 0 1.873.838 1.873 1.873v4.087a1.873 1.873 0 0 1-1.873 1.873" />
            <g transform="translate(45 11.754)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path fill="currentColor" d="M18.978 8.08H2.818A1.874 1.874 0 0 1 .945 6.205V2.12c0-1.035.84-1.873 1.873-1.873h16.16c1.035 0 1.873.838 1.873 1.873v4.087a1.873 1.873 0 0 1-1.873 1.873" mask="url(#b)" />
            </g>
            <g transform="translate(45 31.754)">
                <mask id="d" fill="#fff">
                    <use xlinkHref="#c" />
                </mask>
                <path fill="currentColor" d="M18.978 7.996H2.818A1.873 1.873 0 0 1 .945 6.123V2.036C.945 1 1.785.163 2.818.163h16.16c1.035 0 1.873.838 1.873 1.873v4.087a1.872 1.872 0 0 1-1.873 1.873" mask="url(#d)" />
            </g>
            <g transform="translate(45 51.754)">
                <mask id="f" fill="#fff">
                    <use xlinkHref="#e" />
                </mask>
                <path fill="currentColor" d="M18.978 7.963H2.818A1.873 1.873 0 0 1 .945 6.09V2.003C.945.968 1.785.13 2.818.13h16.16c1.035 0 1.873.838 1.873 1.873V6.09a1.872 1.872 0 0 1-1.873 1.873" mask="url(#f)" />
            </g>
        </g>
    </svg>
);
