/**
 * Updates the body element with classes
 * @param  {Boolean} add            - Whether to add or remove the classNames
 * @param  {Array}   classNames     - the classNames that's going to be added
 * @return {null}
 */
export default function(add = true, classNames = ['is-fixed']) {
    const body = document.body;

    // Add or remove the 'is-fixed' class to the body
    classNames.forEach((className) => {
        if (add) {
            body.classList.add(className);
        } else {
            body.classList.remove(className);
        }
    });
}
// TODO: Consider re-implementing using RXJS and managing the 'body' Node?
