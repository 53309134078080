/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import classnames from 'classnames';
import { clientEnv } from 'config/env';
import { createValidationDataObject } from 'app/utilities/form-validation';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import Moment from 'moment';
import PropTypes from 'prop-types';
import RelatedRooms from 'app/components/partials/related-rooms.container';
import updateBody from 'app/utilities/update-body';
import {
    AVAILABILITY_FIELDS,
    CATERING_FIELDS,
    CATERING_OPTIONS,
    CONTACT_FIELDS,
    EQUIPMENT_FIELDS,
    EQUIPMENT_HIRE,
    EQUIPMENT_INCLUDED,
    FIELD_NAMES,
    STEP_NAMES,
    STEPS,
} from 'config/enquiry-modal-form-fields';
import {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import React, { Component } from 'react';


const BREAKPOINTS = {
    DESKTOP: 1024
};

const DATE_FORMAT = 'DD/MM/YYYY';

class EnquiryModal extends Component {
    constructor(props) {
        super(props);

        this.modal = null;

        this.state = {
            overviewIsOpen: false,
            isDesktopView: false
        };

        this.handlePreviousStep = this.handlePreviousStep.bind(this);
        this.handleNextStep = this.handleNextStep.bind(this);
        this.handleOverviewToggle = this.handleOverviewToggle.bind(this);
        this.goToStep = this.goToStep.bind(this);
    }

    // Life cycle
    componentDidMount() {
        updateBody(this.props.isVisible);
        window.addEventListener('keyup', this.handleKeyUp.bind(this), false);
        window.addEventListener('deviceorientation', this.updateOrientation.bind(this));
        this.updateOrientation();

        this.loadDefaultFieldValues();
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp, false);
        window.removeEventListener('deviceorientation', this.updateOrientation);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.isVisible && !nextProps.isVisible) {
            updateBody(false);
        } else if (!this.props.isVisible && nextProps.isVisible) {
            updateBody(true);
        }

        if (!this.props.submittedSuccessfully && nextProps.submittedSuccessfully) {
            this.modal.scrollTo(0, 0);
        }
    }

    componentDidUpdate(prevsProps) {
        // Set focus on the selected tab element whenever the user switches between steps
        if (this.props.selectedStep !== prevsProps.selectedStep) {
            document.getElementById(STEPS[this.props.selectedStep].name).focus();
        }

        if (prevsProps.selectedRoom.id !== this.props.selectedRoom.id) {
            this.loadDefaultFieldValues();
        }
    }

    // Handlers
    handleKeyUp(e) {
        // Handles "Esc" keypress, calling the onClose method.
        const keys = {
            27: () => {
                e.preventDefault();
                this.handleClose();
                window.removeEventListener('keyup', this.handleKeyUp, false);
            },
        };

        if (keys[e.keyCode]) { keys[e.keyCode](); }
    }

    handleSubmit(event) {
        event.preventDefault();

        const { getFieldValue, submitForm } = this.props;

        const equipmentsFields = [];
        const cateringFields = [];

        EQUIPMENT_HIRE.forEach(({ name, label }) => {
            const value = getFieldValue(name, STEP_NAMES.EQUIPMENT);

            if (value) equipmentsFields.push(label);
        });

        CATERING_OPTIONS.forEach(({ name, label }) => {
            const value = getFieldValue(name, STEP_NAMES.CATERING);

            if (value) cateringFields.push(label);
        });

        const data = {
            room_id: this.props.selectedRoom.id,
            date: getFieldValue(FIELD_NAMES.date, STEP_NAMES.AVAILABILITY),
            open_to_other_days: getFieldValue(FIELD_NAMES.otherDays, STEP_NAMES.AVAILABILITY),
            layout: getFieldValue(FIELD_NAMES.layout, STEP_NAMES.AVAILABILITY),
            number_of_attendees: getFieldValue(FIELD_NAMES.attendees, STEP_NAMES.AVAILABILITY),
            equipment: equipmentsFields,
            extra_equipment: getFieldValue(FIELD_NAMES.extraEquipment, STEP_NAMES.EQUIPMENT),
            catering: cateringFields,
            extra_catering: getFieldValue(FIELD_NAMES.extraCatering, STEP_NAMES.CATERING),
            contact: {
                first_name: getFieldValue(FIELD_NAMES.firstName, STEP_NAMES.CONTACT),
                last_name: getFieldValue(FIELD_NAMES.lastName, STEP_NAMES.CONTACT),
                phone_number: getFieldValue(FIELD_NAMES.phoneNumber, STEP_NAMES.CONTACT),
                email: getFieldValue(FIELD_NAMES.email, STEP_NAMES.CONTACT),
                additional_information: getFieldValue(FIELD_NAMES.additionalInformation, STEP_NAMES.CONTACT)
            }
        };

        // eslint-disable-next-line no-undef
        grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'submit' }).then(function (token) {
                submitForm(data, token);
            });
        });
    }

    handleClose() {
        this.props.resetForm();
        this.props.setSelectedStep(0);
        updateBody(false);
        this.props.toggleModal();
    }

    handleOverviewToggle() {
        this.setState({ overviewIsOpen: !this.state.overviewIsOpen });
    }

    handlePreviousStep() {
        this.props.setSelectedStep(this.props.selectedStep - 1);

        this.modal.scrollTo(0, 0);
    }

    handleNextStep() {
        this.props.setSelectedStep(this.props.selectedStep + 1);

        this.modal.scrollTo(0, 0);
    }

    // Helpers
    goToStep(step) {
        const index = STEPS.findIndex(({ name }) => name === step);

        this.props.setSelectedStep(index);

        this.modal.scrollTo(0, 0);
    }

    isStepValid(fields, step) {
        return fields.every(({ name, type, validator }) => {
            if (type === 'number') {
                if (document.getElementById(name)) {
                    const { min, max } = document.getElementById(name);

                    return validator(this.props.getFieldValue(name, step), min, max).valid;
                }
            }

            return validator(this.props.getFieldValue(name, step)).valid;
        });
    }

    updateOrientation() {
        this.setState({ isDesktopView: window.innerWidth > BREAKPOINTS.DESKTOP });
    }

    // Should be used to validate if a step has any field input with value.
    stepHasValue(fields, step) {
        return fields.some(({ name }) => {
            const value = this.props.getFieldValue(name, step);

            return value;
        });
    }

    loadDefaultFieldValues() {
        // Set a default value for the layout field based on the selected layout type (filter)
        const layoutType = this.props.selectedLayoutType || this.props.selectedRoom.meetingLayoutTypes[0].name;
        this.props.setFieldValue(FIELD_NAMES.layout, layoutType, STEP_NAMES.AVAILABILITY);
        this.props.setFieldValue(FIELD_NAMES.date, new Moment().format(DATE_FORMAT), STEP_NAMES.AVAILABILITY);
        // Set focus on the selected step tab
        document.getElementById(STEPS[this.props.selectedStep].name).focus();
    }

    // Renders
    renderField({ name, label, placeholder, type, validator, radioOptions, step, min, max }) {
        let input = null;

        const { getFieldTouched, getFieldValue, setFieldValue, touchedField } = this.props;

        const touched = getFieldTouched(name, step);
        const value = getFieldValue(name, step);
        const { valid, message } = touched ? validator(value, min, max) : createValidationDataObject();

        const checkboxClass = classnames('field checkbox', {
            'list-item': type === 'checkbox-list'
        });

        const validationClass = classnames({ 'is-invalid': !valid });

        const renderLabel = (
            <label className="label" htmlFor={name}>
                <span className="label-static">{label}</span>
                {!valid && (
                    <span className="label-validation">{message}</span>
                )}
            </label>
        );

        switch (type) {
            case 'date':
                const dateInputClass = classnames('field', { 'is-invalid': !valid });

                input = (
                    <div className={dateInputClass} key={name}>
                        {renderLabel}

                        <DayPickerInput
                            placeholder={new Moment().format(DATE_FORMAT)}
                            id={name}
                            name={name}
                            onDayChange={(value) => {
                                setFieldValue(name, value ? new Moment(value).format(DATE_FORMAT) : '', step);
                                touchedField(name, step);
                            }}
                            value={value}
                            onBlur={() => touchedField(name, step)}
                            dayPickerProps={{ disabledDays: { before: new Date() } }}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            format={DATE_FORMAT}
                        />
                    </div>
                );
                break;
            case 'text':
            case 'tel':
            case 'email':
                input = (
                    <div className="field" key={name}>
                        {renderLabel}
                        <input
                            type={type}
                            id={name}
                            name={name}
                            value={value}
                            onChange={(event) => {
                                setFieldValue(name, event.target.value, step);
                                touchedField(name, step);
                            }}
                            placeholder={placeholder}
                            onBlur={() => touchedField(name, step)}
                            className={validationClass}
                        />
                    </div>
                );
                break;
            case 'number':
                input = (
                    <div className="field" key={name}>
                        {renderLabel}
                        <input
                            type="number"
                            id={name}
                            name={name}
                            value={value}
                            onChange={(event) => {
                                setFieldValue(name, event.target.value, step);
                                touchedField(name, step);
                            }}
                            placeholder={placeholder}
                            onBlur={() => touchedField(name, step)}
                            className={validationClass}
                            min={min}
                            max={max}
                        />
                    </div>
                );
                break;
            case 'checkbox':
            case 'checkbox-list':
                input = (
                    <div className={checkboxClass} key={name}>
                        {renderLabel}
                        <input
                            type="checkbox"
                            id={name}
                            name={name}
                            checked={value}
                            onChange={(event) => {
                                setFieldValue(name, event.target.checked, step);
                                touchedField(name, step);
                            }}
                            placeholder={placeholder}
                            onBlur={() => touchedField(name, step)}
                            className={validationClass}
                        />
                        <span className="checkbox-button" aria-hidden="true">
                            <Icon className="icon" name="tick" />
                        </span>
                    </div>
                );
                break;
            case 'radio':
                // This is a custom radio only for LAYOUT TYPES
                input = (
                    <div className="field" key={name}>
                        <label className="label">
                            <span className="label-static">{label}</span>
                        </label>

                        {radioOptions.map((radio, index) => {
                            const radioName = radio.name.toLowerCase();
                            const radioCapacity = `${radio.capacity_min} - ${radio.capacity_max}`;
                            const checked = value.toLowerCase() === radioName.toLowerCase();

                            return (
                                <div className="radio" key={index}>
                                    <input
                                        type="radio"
                                        id={radioName}
                                        name={name}
                                        checked={checked}
                                        value={radioName}
                                        onChange={(event) => {
                                            setFieldValue(name, event.target.checked ? radioName : '', step);
                                            touchedField(name, step);
                                        }}
                                        placeholder={placeholder}
                                        onBlur={() => touchedField(name, step)}
                                    />
                                    <span className="radio-button" aria-hidden="true"></span>
                                    <label className="label" htmlFor={radioName}>
                                        <span className="label-static">
                                            <Icon className="icon || layout" type="filter" name={radioName} />
                                            {radioName}
                                        </span>
                                        <span className="label-static">
                                            <Icon className="icon || capacity" name="capacity" />
                                            {radioCapacity}
                                        </span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                );
                break;
            case 'multi-line':
                input = (
                    <div className="field" key={name}>
                        {renderLabel}
                        <textarea
                            id={name}
                            name={name}
                            value={value}
                            onChange={(event) => {
                                setFieldValue(name, event.target.value, step);
                                touchedField(name, step);
                            }}
                            placeholder={placeholder}
                            onBlur={() => touchedField(name, step)}
                            className={validationClass}
                        />
                    </div>
                );
                break;
            default:
                break;
        }

        return input;
    }

    renderIncludedItems(list) {
        return (
            <ul className="list">
                {list.map((item, index) => {
                    return (
                        <li className="list-item" key={index}>
                            <span>{item.label}</span>
                            <Icon className="icon" name="tick" title="Item included" />
                        </li>
                    );
                })}
            </ul>
        );
    }

    renderAvailabilitySection() {
        const { selectedLayoutType, selectedRoom } = this.props;

        return AVAILABILITY_FIELDS.map((field) => {
            const layoutTypes = selectedRoom.meetingLayoutTypes;
            const radioOptions = layoutTypes || null;

            if (field.name === FIELD_NAMES.layout) {
                if (!layoutTypes) return; // Some rooms have no layout, it should be fixed by RFA
            }


            if (field.name === FIELD_NAMES.attendees) {
                let max = 9999,
                    min = 0,
                    placeholder = 'Enter number of attendees';

                // Gets the value from the layout field or from the filter selected layout type or from the radio radioOptions first index if this exist
                const selectedLayout = this.props.getFieldValue(FIELD_NAMES.layout, STEP_NAMES.AVAILABILITY) || selectedLayoutType || (radioOptions && radioOptions[0].name);

                // Uses the selectedLayout to set a custom placeholder 'Number of attendees capacityMin - capacityMax'
                if (layoutTypes && selectedLayout) {
                    const layout = layoutTypes.find((layout) => {
                        return layout.name.toLowerCase() === selectedLayout.toLowerCase();
                    });

                    min = layout.capacity_min;
                    max = layout.capacity_max;

                    placeholder = `Enter amount between ${min} - ${max}`;
                }

                return this.renderField({ ...field, placeholder, step: STEP_NAMES.AVAILABILITY, min, max });
            }

            return this.renderField({ ...field, radioOptions, selectedValue: selectedLayoutType, step: STEP_NAMES.AVAILABILITY });
        });
    }

    renderEquipmentSection() {
        return (
            <div>
                <div className="list-section">
                    <p className="list-section-title">Included with the room</p>
                    {this.renderIncludedItems(EQUIPMENT_INCLUDED)}
                </div>

                <div className="list-section">
                    <p className="list-section-title">Available hire options</p>
                    {EQUIPMENT_HIRE.map((input) => {
                        return this.renderField({ ...input, step: STEP_NAMES.EQUIPMENT });
                    })}
                </div>

                {EQUIPMENT_FIELDS.map((field) => this.renderField({ ...field, step: STEP_NAMES.EQUIPMENT }))}
            </div>
        );
    }

    renderCateringSection() {
        return (
            <div>
                <div className="list-section">
                    <p className="list-section-title">Available options</p>
                    {CATERING_OPTIONS.map((input) => this.renderField({ ...input, step: STEP_NAMES.CATERING }))}
                </div>

                {CATERING_FIELDS.map((field) => this.renderField({ ...field, step: STEP_NAMES.CATERING }))}
            </div>
        );
    }

    renderContactSection() {
        return (
            <div className="enquiry-modal-form-contact">
                <div className="enquiry-modal-form-contact-fields">
                    {CONTACT_FIELDS.map((field) => {
                        return this.renderField({ ...field, step: STEP_NAMES.CONTACT });
                    })}
                </div>
                {this.state.isDesktopView ? this.renderOverviewSection() : this.renderMobileOverviewSection()}
            </div>
        );
    }

    //  Handles custom description for some specific fields that are rendered on Overview section
    renderCustomDescription({ name, label, type }, value) {
        switch (name) {
            case FIELD_NAMES.attendees:
                return `${value} attendees`;
            case FIELD_NAMES.layout:
                return `${value.charAt(0).toUpperCase()}${value.substr(1)} layout`;
            default:
                if (type === 'checkbox' || type === 'checkbox-list') return label;

                return value;
        }
    }

    renderOverviewItem(field, step, key) {
        const { getFieldValue } = this.props;

        const value = getFieldValue(field.name, step);

        if (value) {
            return (
                <li key={key}>
                    <span className="overview-section-item-description">{this.renderCustomDescription(field, value)}</span>
                </li>
            );
        }

        return null;
    }

    renderMobileOverviewSection() {
        const itemClassName = 'accordion-item';
        const itemClass = classnames(itemClassName, {
            'is-active': this.state.overviewIsOpen
        });

        return (
            <div className="accordion">
                <div className={itemClass}>
                    <button
                        className={`${itemClassName}-header`}
                        onClick={() => this.handleOverviewToggle()}
                        type="button"
                    >
                        <h5 className={`${itemClassName}-title`}>Overview</h5>
                        <div className="icon-button small">
                            <Icon name="plus" />
                        </div>
                    </button>
                    <div className={`${itemClassName}-content`}>
                        {this.renderOverviewSection()}
                    </div>
                </div>
            </div>
        );
    }

    renderOverviewSection() {
        const equipmentStepHasValue = this.stepHasValue([...EQUIPMENT_FIELDS, ...EQUIPMENT_HIRE], STEP_NAMES.EQUIPMENT);
        const cateringHasValue = this.stepHasValue([...CATERING_FIELDS, ...CATERING_OPTIONS], STEP_NAMES.CATERING);

        return (
            <div className="overview-section">
                {this.state.isDesktopView && <h5 className="overview-section-title">Overview</h5>}
                <ol className="overview-section-list">
                    <li className="overview-section-list-item">
                        <h5 className="overview-section-item-title">Availability</h5>
                        <button className="overview-section-item-button" type="button" disabled={this.props.isBusy} onClick={() => this.goToStep(STEP_NAMES.AVAILABILITY)}>Change</button>
                        <ul>
                            {AVAILABILITY_FIELDS.map((field, index) => this.renderOverviewItem(field, STEP_NAMES.AVAILABILITY, index))}
                        </ul>
                    </li>
                    <li className="overview-section-list-item">
                        <h5 className="overview-section-item-title">Equipment</h5>
                        <button className="overview-section-item-button" type="button" disabled={this.props.isBusy} onClick={() => this.goToStep(STEP_NAMES.EQUIPMENT)}>Change</button>
                        {/* Checks whether equipment has value or not */}
                        {equipmentStepHasValue ?
                            <ul>
                                {EQUIPMENT_HIRE.map((field, index) => this.renderOverviewItem(field, STEP_NAMES.EQUIPMENT, index))}

                                {EQUIPMENT_FIELDS.map((field, index) => this.renderOverviewItem(field, STEP_NAMES.EQUIPMENT, index))}
                            </ul>
                            : <span className="overview-section-empty">You don’t need any extra equipment</span>
                        }
                    </li>
                    <li className="overview-section-list-item">
                        <h5 className="overview-section-item-title">Catering</h5>
                        <button className="overview-section-item-button" type="button" disabled={this.props.isBusy} onClick={() => this.goToStep(STEP_NAMES.CATERING)}>Change</button>
                        {/* Checks whether catering has value or not */}
                        {cateringHasValue ?
                            <ul>
                                {CATERING_OPTIONS.map((field, index) => this.renderOverviewItem(field, STEP_NAMES.CATERING, index))}
                                {CATERING_FIELDS.map((field, index) => this.renderOverviewItem(field, STEP_NAMES.CATERING, index))}
                            </ul>
                            : <span className="overview-section-empty">You don’t need any extra catering</span>
                        }
                    </li>
                </ol>
            </div>
        );
    }

    renderSelectedStepSection() {
        const selectedStep = STEPS[this.props.selectedStep];

        switch (selectedStep.name) {
            case STEP_NAMES.AVAILABILITY:
                return this.renderAvailabilitySection();
            case STEP_NAMES.EQUIPMENT:
                return this.renderEquipmentSection();
            case STEP_NAMES.CATERING:
                return this.renderCateringSection();
            case STEP_NAMES.CONTACT:
                return this.renderContactSection();
            default:
                return null;
        }
    }

    renderSubmitButton(valid) {
        const { isBusy } = this.props;

        if (isBusy) {
            return (
                <button
                    className="button || submit"
                    type="button"
                    aria-disabled={true}>
                    <Loader type="small" />
                </button>
            );
        }

        return (
            <button
                className="button || submit"
                type="submit"
                disabled={!valid}
                aria-disabled={!valid}>
                Send
            </button>
        );
    }

    renderSubmitSection() {
        const { isBusy, submitted, submittedSuccessfully, selectedStep } = this.props;

        let showMandatoryMessage = false,
            valid = true;
        const stepName = STEPS[selectedStep].name;

        if (stepName === STEP_NAMES.AVAILABILITY) {
            valid = this.isStepValid(AVAILABILITY_FIELDS, STEP_NAMES.AVAILABILITY);
            showMandatoryMessage = true;
        } else if (stepName === STEP_NAMES.CONTACT) {
            valid = this.isStepValid(CONTACT_FIELDS, STEP_NAMES.CONTACT);
            showMandatoryMessage = true;
        }

        return (
            <div className="submit-section">
                {showMandatoryMessage &&
                    <span className="mandatory-description">*Mandatory fields, so we can provide the best response</span>
                }
                {!isBusy && submitted && !submittedSuccessfully &&
                    <span className="error-description">Sorry, we didn’t get your message. Please try again later.</span>
                }
                {selectedStep === STEPS.length - 1 &&
                    <span className="mandatory-description">We manage your personal information in accordance with our  <Link className="privacy-link" to="/privacy-policy">Privacy Policy</Link>
                    </span>
                }
                {selectedStep === STEPS.length - 1 &&
                    <p className="recaptcha-description">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </p>
                }

                {selectedStep > 0 &&
                    <button
                        className="button || back"
                        type="button"
                        disabled={isBusy}
                        onClick={() => this.handlePreviousStep()}>
                        Back
                    </button>
                }
                {selectedStep < STEPS.length - 1 &&
                    <button
                        className="button || continue"
                        type="button"
                        disabled={!valid}
                        aria-disabled={!valid}
                        onClick={() => this.handleNextStep()}>
                        Continue
                    </button>
                }
                {selectedStep === STEPS.length - 1 && this.renderSubmitButton(valid)}
            </div>
        );
    }

    renderTabs() {
        let valid = true;
        const { selectedStep } = this.props;

        const stepName = STEPS[selectedStep].name;

        return (
            <ol className="enquiry-modal-header-steps" role="tablist" aria-label="Room enquiry steps">
                {STEPS.map((step, index) => {
                    const tabIsSelected = selectedStep === index;
                    const stepIsCompleted = selectedStep > index;
                    const stepClass = classnames({
                        'step-completed': stepIsCompleted,
                        'step-selected': tabIsSelected
                    });

                    if (!tabIsSelected && !stepIsCompleted) {
                        if (stepName === STEP_NAMES.AVAILABILITY) {
                            valid = this.isStepValid(AVAILABILITY_FIELDS, STEP_NAMES.AVAILABILITY);
                        } else if (stepName === STEP_NAMES.CONTACT) {
                            valid = this.isStepValid(CONTACT_FIELDS, STEP_NAMES.CONTACT);
                        }
                    }

                    // If form is submitting, buttons must be disabled.
                    if (this.props.isBusy) {
                        valid = false;
                    }

                    return (
                        <li className={stepClass} key={index}>
                            <button
                                id={STEPS[index].name}
                                role="tab"
                                aria-selected={tabIsSelected}
                                aria-disabled={!valid}
                                disabled={!valid}
                                onClick={() => this.props.setSelectedStep(index)}>
                                {step.title}
                            </button>
                        </li>
                    );
                })}
            </ol>
        );
    }

    render() {
        const { selectedRoom, getFieldValue, submitted, submittedSuccessfully } = this.props;

        const {
            title: roomName,
            venue,
            thumbnail,
            description,
            slug
        } = selectedRoom;

        const formIsSubmitted = submitted && submittedSuccessfully;

        const { title, subtitle, name } = STEPS[this.props.selectedStep];

        return (
            <div className={'enquiry-modal'} ref={(modal) => { this.modal = modal; }}>
                <div className="enquiry-modal-header || constrain-width large no-pad">
                    <div aria-hidden="true" style={{ backgroundImage: `url(${thumbnail})` }} className="enquiry-modal-header-image-overlay"></div>
                    <img className="enquiry-modal-header-image" src={thumbnail} alt={description} />
                    <p className="enquiry-modal-header-title">
                        <Link to={`/rooms/${slug}`}>{roomName}</Link> at <Link to={`/venues/${venue.attributes.slug}`}>{venue.attributes.name}</Link>
                    </p>
                    {!formIsSubmitted && this.renderTabs()}
                    <button
                        type="button"
                        className="enquiry-modal-header-close-button"
                        onClick={this.handleClose.bind(this)}>
                        <span>
                            <Icon name="close" />
                            Close
                        </span>
                    </button>
                </div>

                {formIsSubmitted ?
                    <div className="enquiry-modal-success">
                        <div className="enquiry-modal-success-title || constrain-width">
                            <h1 className="heading-2">Thanks {getFieldValue(FIELD_NAMES.firstName, STEP_NAMES.CONTACT)}, for your enquiry!</h1>
                            <span>
                                Email confirmation with all the details you provided has been sent to you. A member of our team will be in touch to discuss your requirements as soon as possible.
                            </span>
                        </div>
                        <RelatedRooms selectedRoom={selectedRoom} title="Similar rooms" />
                    </div>
                    :
                    <div className="enquiry-modal-content || constrain-width" >
                        <div className="enquiry-modal-content-title">
                            <span>{title}</span>
                            <h1>{subtitle}</h1>
                        </div>
                        <form
                            action="#"
                            method="POST"
                            className={`form || enquiry-modal-form ${name}`}
                            onSubmit={this.handleSubmit.bind(this)}
                        >
                            {this.renderSelectedStepSection()}
                            {this.renderSubmitSection()}
                        </form>
                    </div>
                }
            </div>
        );
    }
}

EnquiryModal.propTypes = {
    getFieldTouched: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    selectedLayoutType: PropTypes.string.isRequired,
    selectedStep: PropTypes.number.isRequired,
    selectedRoom: PropTypes.object.isRequired,
    setSelectedStep: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    submittedSuccessfully: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    touchedField: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
};

export default EnquiryModal;
