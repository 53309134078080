import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <title>
            cabaret
        </title>
        <defs>
            <path id="a" d="M.787.913h5.481v5.482H.787z" />
            <path id="c" d="M0 .7h5.481v5.481H0z" />
            <path id="e" d="M.823.913h5.481v5.482H.823z" />
            <path id="g" d="M.609.7H6.09v5.481H.609z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="currentColor" d="M27.802 29.527a8.275 8.275 0 1 1-16.551 0 8.275 8.275 0 0 1 16.551 0" />
            <g transform="translate(16 12.087)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path fill="currentColor" d="M6.268 3.654a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0" mask="url(#b)" />
            </g>
            <path fill="currentColor" d="M11.717 17.84a2.741 2.741 0 1 1-3.878 3.877 2.741 2.741 0 0 1 3.878-3.876" />
            <g transform="translate(3 26.087)">
                <mask id="d" fill="#fff">
                    <use xlinkHref="#c" />
                </mask>
                <path fill="currentColor" d="M2.74.7a2.74 2.74 0 1 1 .001 5.481 2.74 2.74 0 0 1 0-5.481" mask="url(#d)" />
            </g>
            <path fill="currentColor" d="M33.313 32.268a2.74 2.74 0 1 1 0-5.482 2.74 2.74 0 0 1 0 5.482m-2.1-10.551a2.741 2.741 0 1 1-3.875-3.878 2.741 2.741 0 0 1 3.876 3.878m-19.962-3.412a2.084 2.084 0 1 1-2.949 2.946 2.084 2.084 0 0 1 2.949-2.946M5.74 27.444a2.083 2.083 0 1 1 0 4.165 2.083 2.083 0 0 1 0-4.165" />
            <path fill="currentColor" d="M33.313 31.61a2.084 2.084 0 1 1 .001-4.167 2.084 2.084 0 0 1 0 4.167m-2.565-10.358a2.084 2.084 0 1 1-2.946-2.949 2.084 2.084 0 0 1 2.946 2.949m37.09 8.275a8.275 8.275 0 1 1-16.55 0 8.275 8.275 0 0 1 16.55 0" />
            <g transform="translate(56 12.087)">
                <mask id="f" fill="#fff">
                    <use xlinkHref="#e" />
                </mask>
                <path fill="currentColor" d="M6.304 3.654a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0" mask="url(#f)" />
            </g>
            <path fill="currentColor" d="M51.753 17.84a2.741 2.741 0 1 1-3.878 3.877 2.741 2.741 0 0 1 3.878-3.876m-5.976 8.945a2.74 2.74 0 1 1 0 5.482 2.74 2.74 0 0 1 0-5.482" />
            <g transform="translate(70 26.087)">
                <mask id="h" fill="#fff">
                    <use xlinkHref="#g" />
                </mask>
                <path fill="currentColor" d="M3.35 6.181A2.74 2.74 0 1 1 3.35.7a2.74 2.74 0 0 1 0 5.481" mask="url(#h)" />
            </g>
            <path fill="currentColor" d="M71.25 21.717a2.741 2.741 0 1 1-3.876-3.878 2.741 2.741 0 0 1 3.876 3.878m-19.962-3.411a2.084 2.084 0 1 1-2.948 2.946 2.084 2.084 0 0 1 2.948-2.946m-5.51 9.138a2.084 2.084 0 1 1-.002 4.167 2.084 2.084 0 0 1 .001-4.167" />
            <path fill="currentColor" d="M70.785 21.252a2.084 2.084 0 1 1-2.946-2.949 2.084 2.084 0 0 1 2.946 2.949m-22.946 38.56a8.275 8.275 0 1 1-16.55-.001 8.275 8.275 0 0 1 16.55 0m-5.535-13.786a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0m-10.551 2.1A2.741 2.741 0 1 1 27.875 52a2.741 2.741 0 0 1 3.878-3.876m-5.976 8.946a2.74 2.74 0 1 1 0 5.481 2.74 2.74 0 0 1 0-5.481m27.573 5.481a2.74 2.74 0 1 1 0-5.482 2.74 2.74 0 0 1 0 5.482M51.25 52a2.741 2.741 0 1 1-3.876-3.878A2.741 2.741 0 0 1 51.25 52" />
            <path fill="currentColor" d="M31.288 48.59a2.084 2.084 0 1 1-2.948 2.946 2.084 2.084 0 0 1 2.948-2.946M53.35 61.895a2.083 2.083 0 1 1 0-4.166 2.083 2.083 0 0 1 0 4.166m-2.565-10.36a2.084 2.084 0 1 1-2.946-2.948 2.084 2.084 0 0 1 2.946 2.949" />
        </g>
    </svg>
);
