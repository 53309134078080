import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            PDF
        </title>
        <path fill="currentColor" fillRule="evenodd" d="M32.633 11.34l-6.217-7.006a.902.902 0 0 0-.675-.303H8.712a.9.9 0 0 0-.901.901v30.05a.9.9 0 0 0 .901.902h23.246a.9.9 0 0 0 .901-.901V11.938a.91.91 0 0 0-.226-.598zm-6.005-4.052l3.366 3.794h-3.366V7.288zm4.43 26.793H9.612V5.834h15.213v6.149a.9.9 0 0 0 .901.9h5.33v21.198zM12.84 20.827a.9.9 0 0 0 .9.901h13.188a.9.9 0 1 0 0-1.802H13.741a.902.902 0 0 0-.901.901zm14.09 3.61H13.742a.9.9 0 1 0 0 1.802H26.93a.9.9 0 1 0 0-1.802zm0 4.508H13.742a.9.9 0 1 0 0 1.802H26.93a.9.9 0 1 0 0-1.802zM20.335 16.56a3.337 3.337 0 0 0 3.334-3.334 3.337 3.337 0 0 0-3.334-3.334 3.337 3.337 0 0 0-3.334 3.334 3.337 3.337 0 0 0 3.334 3.334zm0-4.866c.845 0 1.532.688 1.532 1.532 0 .845-.687 1.532-1.532 1.532a1.533 1.533 0 0 1-1.532-1.532c0-.844.687-1.532 1.532-1.532z" />
    </svg>
);
