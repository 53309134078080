import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Phone
        </title>
        <path fill="currentColor" d="M31.8 26.8c0 .3-.1.7-.2 1.2s-.2.9-.4 1.1c-.2.6-.9 1.1-2 1.8-1 .6-2.1.9-3.1.9-.3 0-.6 0-.9-.1s-.6-.1-1-.2-.6-.2-.8-.2c-.2-.1-.5-.2-.9-.3s-.7-.3-.8-.3c-1.1-.4-2.1-.9-2.9-1.4-1.4-.9-2.9-2.1-4.4-3.6-1.5-1.5-2.7-3-3.6-4.4-.5-.9-1-1.8-1.4-2.9 0-.1-.1-.4-.3-.8-.2-.4-.3-.8-.3-.9-.1-.2-.1-.4-.2-.8s-.2-.7-.2-1c0-.3-.1-.6-.1-.9 0-1 .3-2.1.9-3.1.6-1.1 1.2-1.8 1.8-2 .3-.1.7-.2 1.1-.4.5-.1.9-.2 1.2-.2.2 0 .3 0 .4.1.2.1.5.5.9 1.3.1.2.3.5.5.9s.4.7.6 1.1c.2.3.4.6.5.9 0 0 .1.2.3.4.2.2.3.4.4.6.1.2.1.3.1.5s-.2.5-.5.8c-.3.3-.7.6-1 .9-.4.3-.7.6-1 .9-.3.3-.5.6-.5.8 0 .1 0 .2.1.4-.2-.1-.1.1-.1.1 0 .1.1.2.2.4s.2.3.2.3c.8 1.5 1.8 2.8 2.9 3.9 1.1 1.1 2.4 2.1 3.9 2.9 0 0 .1.1.3.2s.4.3.5.3c.1 0 .2.1.3.1.2.1.3.1.4.1.2 0 .5-.2.8-.5.3-.3.6-.7.9-1 .3-.4.6-.7.9-1 .3-.3.6-.5.8-.5.2 0 .3 0 .5.1s.4.2.6.4.4.3.4.3c.3.2.6.3.9.5.3.2.7.4 1.1.6.4.2.7.4.9.5.8.4 1.2.7 1.3.9-.1 0 0 .2 0 .3z" />
    </svg>
);
