import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
        <title>
            Cocktail
        </title>
        <g>
            <path d="M11.792,9.181a1.933,1.933,0,1,1-2.734,0,1.933,1.933,0,0,1,2.734,0" fill="currentColor" />
            <path d="M6.979,9.685a1.16,1.16,0,1,1-1.64,0,1.159,1.159,0,0,1,1.64,0" fill="currentColor" />
            <path d="M11.252,13.958a1.16,1.16,0,1,1-1.64,0,1.159,1.159,0,0,1,1.64,0" fill="currentColor" />
            <path d="M15.464,9.746a1.16,1.16,0,1,1-1.64,0,1.159,1.159,0,0,1,1.64,0" fill="currentColor" />
            <path d="M11.245,5.34a1.16,1.16,0,1,1-1.64,0,1.16,1.16,0,0,1,1.64,0" fill="currentColor" />
        </g>
        <g>
            <path d="M20.637,18.244a1.933,1.933,0,1,1-2.734,0,1.933,1.933,0,0,1,2.734,0" fill="currentColor" />
            <path d="M15.825,18.747a1.16,1.16,0,1,1-1.64,0,1.159,1.159,0,0,1,1.64,0" fill="currentColor" />
            <path d="M20.1,23.02a1.16,1.16,0,1,1-1.64,0,1.159,1.159,0,0,1,1.64,0" fill="currentColor" />
            <path d="M24.309,18.808a1.16,1.16,0,1,1-1.64,0,1.159,1.159,0,0,1,1.64,0" fill="currentColor" />
            <path d="M20.09,14.4a1.16,1.16,0,1,1-1.64,0,1.16,1.16,0,0,1,1.64,0" fill="currentColor" />
        </g>
    </svg>
);
