/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import Rooms from 'app/components/partials/rooms';
import { selectEventType as selectFilterEventType } from 'app/selectors/filter';
import { fetchRooms, setEventType } from 'app/ducks/rooms';
import { selectEventType, selectIsBusy, selectRoomsResults } from 'app/selectors/rooms';

function mapStateToProps(state) {
    return {
        filterEventType: selectFilterEventType(state),
        eventType: selectEventType(state),
        isBusy: selectIsBusy(state),
        getRooms: (eventType) => selectRoomsResults(eventType, state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchRooms: (venueId, eventType) => dispatch(fetchRooms(venueId, eventType)),
        setEventType: (eventType) => dispatch(setEventType(eventType))
    };
}

const VisibleRooms = connect(
    mapStateToProps,
    mapDispatchToProps
)(Rooms);

export default VisibleRooms;
