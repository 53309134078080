import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
        <title>
            Chevron (capacity)
        </title>
        <path fill="currentColor" d="M3.85632332,6.5000002 L0.198536349,1.59804349 C-0.13175395,1.15540734 -0.0406796422,0.528826648 0.40195651,0.198536349 C0.844592662,-0.13175395 1.47117335,-0.0406796422 1.80146365,0.40195651 L5.72312895,5.65754828 C6.09229035,6.15153738 6.09229035,6.84846262 5.72355825,7.34187682 L1.80146365,12.5980435 C1.47117335,13.0406796 0.844592662,13.131754 0.40195651,12.8014637 C-0.0406796422,12.4711734 -0.13175395,11.8445927 0.198536349,11.4019565 L3.85632332,6.5000002 Z" transform="translate(12 8)" />
    </svg>
);

