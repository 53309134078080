import ContentPage from './components/pages/content-page.container';
import EnquirySection from './components/partials/enquiry-section.container';
import FilterResultsPage from './components/pages/filter-results-page.container';
import Footer from './components/partials/footer.container';
import Header from './components/partials/header.container';
import NotFoundPage from './components/pages/error/not-found-page';
import React from 'react';
import StyleGuidePage from './components/pages/style-guide-page';
import throttleUpdateWhileRouting from './utilities/throttle-update-while-routing';
import { Navigate, Route, Routes } from 'react-router-dom';

const Router = () => (
    <div>
        <Header />
        <Routes>
            {/* Redirects */}
            <Route
                path="/venues/anz-viaduct-events-centre"
                element={<Navigate to="/" replace />}
            />
            {/* Custom */}
            <Route path="/style-guide" element={<StyleGuidePage />} />
            {/* Filter */}
            <Route path="/filter" element={<FilterResultsPage />} />
            {/* API Based Routes */}
            <Route path="/" element={<ContentPage />} />
            <Route path="/rooms/:slug" element={<ContentPage />} />
            <Route path="/venues/:slug" element={<ContentPage />} />
            <Route path="/news/:slug" element={<ContentPage />} />
            <Route path="/:slug" element={<ContentPage />} />
            <Route path="/:parent/:slug" element={<ContentPage />} />

            {/* Catch */}
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <EnquirySection />
        <Footer />
    </div>
);

export default throttleUpdateWhileRouting(Router);
