/**
 * Format the entity type
 *
 * @param  {string} type currently stored as plural from CMS
 *
 * @return {string} type re-formatted as single
 */
export function entityType(type) {
    switch (type) {
        case 'pages':
            return 'page';
        case 'rooms':
            return 'room';
        case 'venues':
            return 'venue';
        case 'news':
            return 'news';
        default:
            return type;
    }
}

/**
 * Returns a string of comma seperated values in order of delcaration of an ordered list
 * @param  {[string]} orderList    - The ordered list the format will follow
 * @param  {[string]} valueList    - A list of the corresponding values
 * @return {string}
 */
export function formatArrayToOrderedString(orderList, valueList) {
    // Return empty string if no values
    if (valueList.length === 0) return '';

    const valuesLabels = [];

    // Render the labels in order of appearance in the options
    orderList.forEach(({ value, label }) => {
        if (valueList.includes(value)) valuesLabels.push(label);
    });

    return valuesLabels.join(', ');
}

export const JSON_SPACE = 4;

export function getJsonStr(jsonObject) {
    return JSON.stringify(jsonObject, null, JSON_SPACE);
}
