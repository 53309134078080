import formatContent from 'app/utilities/format-content';
import { GOOGLE_API_KEY } from 'config/maps';
import Map from './map';
import PropTypes from 'prop-types';
import React from 'react';

class Location extends React.Component {
    render() {
        const { long, lat, place, placeId, content } = this.props;

        // Google Map Urls
        const mapLink = `https://www.google.com/maps/search/?api=1&query=${place}&query_place_id=${placeId}`;
        const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

        return (
            <section className="content-section">
                <div className="constrain-width">
                    <h2 className="heading-1">Location</h2>
                    <div className="location-map">
                        <Map
                            googleMapURL={googleMapURL}
                            loadingElement={<div className="location-map-element" />}
                            containerElement={<div className="location-map-container" />}
                            mapElement={<div className="location-map-element" />}
                            showMarker={true}
                            lat={lat}
                            lng={long}
                        />
                    </div>
                    {content &&
                        <div className="content-columns">
                            <div className="content-columns-inner">
                                {content.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="rte-content"
                                            dangerouslySetInnerHTML={{ __html: formatContent(item) }}></div>
                                    );
                                })}
                            </div>
                        </div>
                    }
                    <div className="button-content-block">
                        <a href={mapLink} className="button">Get directions</a>
                    </div>
                </div>
            </section>
        );
    }
}

Location.propTypes = {
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
    place: PropTypes.string.isRequired,
    placeId: PropTypes.string.isRequired,
    content: PropTypes.array
};

export default Location;
