import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Pinterest
        </title>
        <path fill="currentColor" d="M21.291 25.359c-1.21-.094-1.717-.692-2.666-1.266-.521 2.727-1.159 5.341-3.046 6.707-.583-4.122.855-7.217 1.523-10.503-1.138-1.91.137-5.756 2.539-4.809 2.955 1.165-2.56 7.104 1.143 7.846 3.865.775 5.442-6.685 3.046-9.111-3.464-3.503-10.08-.08-9.267 4.935.198 1.226 1.47 1.598.508 3.29-2.217-.49-2.878-2.232-2.793-4.555.137-3.803 3.428-6.466 6.728-6.834 4.174-.466 8.092 1.528 8.633 5.442.608 4.417-1.885 9.201-6.348 8.858z" />
    </svg>
);
