import Capacities from './capacities';
import Home from './home';
import News from './news';
import Page from './page';
import PropTypes from 'prop-types';
import Room from './room';
import Venue from './venue';
import React, { Component } from 'react';

class Template extends Component {
    render() {
        const { data, type } = this.props;

        // Home page identified by slug
        if (data.slug === 'home') {
            return <Home data={data} />;
        }

        // Capacities page identified by slug
        if (data.slug === 'capacities') {
            return <Capacities data={data} />;
        }

        switch (type) {
            case 'room':
                return <Room data={data} />;
            case 'venue':
                return <Venue data={data} />;
            case 'news':
                return <News data={data} />;
            default:
                return <Page data={data} />;
        }
    }
}

Template.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string.isRequired
};

export default Template;
