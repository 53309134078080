
export const INITIAL_STATE = {
    selectedStep: 0,
    isVisible: false,
    selectedRoom: null
};

// Actions
export const SET_SELECTED_STEP = 'rfa-conventions-website/enquiry-modal/SET_SELECTED_STEP';
export const SET_SELECTED_ROOM = 'rfa-conventions-website/enquiry-modal/SET_SELECTED_ROOM';
export const TOGGLE_MODAL = 'rfa-conventions-website/enquiry-modal/TOGGLE_MODAL';

// Action Creators
export const setSelectedRoomAction = (selectedRoom) => ({
    type: SET_SELECTED_ROOM,
    selectedRoom
});

export const setSelectedStepAction = (selectedStep) => ({
    type: SET_SELECTED_STEP,
    selectedStep
});

export const toggleModalAction = () => ({
    type: TOGGLE_MODAL
});

// Reducer creator
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SELECTED_ROOM:
            return { ...state, selectedRoom: action.selectedRoom };
        case SET_SELECTED_STEP:
            return { ...state, selectedStep: action.selectedStep };
        case TOGGLE_MODAL:
            return { ...state, isVisible: !state.isVisible };
        default:
            return state;
    }
};

