import CapacitiesVenue from './capacities-venue';
import Disclaimer from 'app/components/partials/disclaimer';
import Icon from 'app/components/partials/icon';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';


/**
 * Used on the /capacities page.
 */
class CapacitiesSection extends Component {
    // Lifecycle
    componentDidMount() {
        this.props.initiateCapacitiesFetch();
    }


    // Renderers
    renderLayoutTypes() {
        const { layoutTypes } = this.props;

        return (
            <div className="capacities-grid-heading-wrapper">
                <div className="capacities-grid-heading capacities-grid-row">
                    <div className="capacities-grid-text-cell">
                        <div className="capacities-grid-text-cell-inner">
                            Venue / room capacity
                        </div>
                    </div>
                    {layoutTypes.map((layoutType, layoutTypeIndex) => (
                        <div
                            key={layoutTypeIndex}
                            className="capacities-grid-cell"
                        >
                            <div className="capacities-grid-cell-inner">
                                <Icon name={layoutType.toLowerCase()} type="filter" />
                                {layoutType}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderVenue({ name, capacities, rooms, slug }, key) {
        // Hide the venue if it does not have any rooms.
        if (!rooms.length) {
            return null;
        }

        const { layoutTypes } = this.props;

        return (
            <CapacitiesVenue
                key={key}
                name={name}
                capacities={capacities}
                rooms={rooms}
                slug={slug}
                layoutTypes={layoutTypes}
            />
        );
    }

    renderVenues() {
        const { venues } = this.props;

        return <div className="capacities-grid-venues-wrapper">
            {venues().map(this.renderVenue.bind(this))}
        </div>;
    }

    render() {
        const { capacitiesAreReady } = this.props;

        if (capacitiesAreReady) {
            return (
                <div className="constrain-width no-pad">
                    <Disclaimer />
                    {this.renderLayoutTypes()}
                    {this.renderVenues()}
                </div>
            );
        }

        return <Loader type="block primary" />;
    }
}

CapacitiesSection.propTypes = {
    initiateCapacitiesFetch: PropTypes.func.isRequired,
    layoutTypes: PropTypes.array.isRequired,
    venues: PropTypes.func.isRequired,
    capacitiesAreReady: PropTypes.bool.isRequired
};

export default CapacitiesSection;
