import canUseDom from 'app/utilities/dom';

const env = canUseDom() ? window.__ENV__ : process.env;

const {
    API_BASE_URL,
    CLIENT_ID,
    CLIENT_SECRET,
    NODE_ENV,
    APP_ENV,
    SENTRY_DSN,
    APP_PORT,
    GOOGLE_TAG_MANAGER_CONTAINER_ID,
    GOOGLE_OPTIMIZE_CONTAINER_ID,
    GOOGLE_SITE_VERIFICATION,
    BROWSER_SUPPORTS_ALL_FEATURES,
    RECAPTCHA_SECRET_KEY,
    RECAPTCHA_KEY,
    ACTIVE_CAMPAIGN_ACCOUNT,
    FACEBOOK_SITE_VERIFICATION
} = env;

// Don't expose any of the following: CLIENT_ID, CLIENT_SECRET
export const clientEnv = {
    API_BASE_URL: '/api',
    NODE_ENV,
    APP_ENV,
    SENTRY_DSN,
    APP_PORT,
    GOOGLE_TAG_MANAGER_CONTAINER_ID,
    GOOGLE_OPTIMIZE_CONTAINER_ID,
    GOOGLE_SITE_VERIFICATION,
    BROWSER_SUPPORTS_ALL_FEATURES,
    RECAPTCHA_KEY,
    ACTIVE_CAMPAIGN_ACCOUNT,
    FACEBOOK_SITE_VERIFICATION
};

export default {
    API_BASE_URL,
    CLIENT_ID,
    CLIENT_SECRET,
    NODE_ENV,
    APP_ENV,
    SENTRY_DSN,
    APP_PORT,
    GOOGLE_TAG_MANAGER_CONTAINER_ID,
    GOOGLE_OPTIMIZE_CONTAINER_ID,
    GOOGLE_SITE_VERIFICATION,
    BROWSER_SUPPORTS_ALL_FEATURES,
    RECAPTCHA_SECRET_KEY,
    ACTIVE_CAMPAIGN_ACCOUNT,
    FACEBOOK_SITE_VERIFICATION
};

export const isProd = env.NODE_ENV === 'production';
export const isAppProd = env.APP_ENV === 'prod';
