/* eslint-disable require-jsdoc */
export function selectedRoom(state) {
    return state.enquiryModal.selectedRoom;
}

export function selectedStep(state) {
    return state.enquiryModal.selectedStep;
}

export function selectIsVisible(state) {
    return state.enquiryModal.isVisible;
}
