import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

class PageHeaderCarousel extends React.Component {
    render() {
        const { homepage, images } = this.props;

        const isSingleSlide = images.length < 2;

        return (
            <Slider
                className={classnames('page-header-carousel', { homepage, 'is-single-slide': isSingleSlide })}
                {...IMAGE_CAROUSEL_CONFIG}
            >
                {images.map((imageSrc, imageIndex) => {
                    return (
                        <div key={imageIndex}>
                            <div
                                className="page-header-carousel-item"
                                style={{ backgroundImage: `url(${imageSrc})` }}
                            />
                        </div>
                    );
                })}
            </Slider>
        );
    }
}

PageHeaderCarousel.propTypes = {
    homepage: PropTypes.bool,
    images: PropTypes.array.isRequired,
};

export default PageHeaderCarousel;


const IMAGE_CAROUSEL_CONFIG = {
    accessibility: false,
    adaptiveHeight: false,
    dots: true,
    draggable: false,
    infinite: true,
    fade: true,
    pauseOnHover: true,
    speed: 500,
    swipe: false,
    usecSS: true,
    arrows: false,
    autoplay: true
};
