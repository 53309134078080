import Data from 'app/components/partials/data';
import PropTypes from 'prop-types';
import React from 'react';

class Pricing extends React.Component {
    render() {
        const { data: plans } = this.props;

        return (
            <Data title="Content Block: Pricing" data={{ plans }} />
        );
    }
}

Pricing.propTypes = {
    data: PropTypes.array.isRequired
};

export default Pricing;
