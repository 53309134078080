import banquet from './banquet';
import boardroom from './boardroom';
import cabaret from './cabaret';
import classroom from './classroom';
import cocktail from './cocktail';
import theatre from './theatre';
import uShape from './u-shape';

export default {
    banquet,
    boardroom,
    cabaret,
    classroom,
    cocktail,
    theatre,
    'u-shape': uShape
};
