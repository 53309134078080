import Disclaimer from 'app/components/partials/disclaimer';
import formatContent from 'app/utilities/format-content';
import Icon from './icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const ITEMS_PER_ROW = 4;

class RoomCapacitiesBlock extends Component {
    constructor(props) {
        super(props);

        this.aside = React.createRef();
    }

    componentDidMount() {
        if (this.aside.current) {
            this.hashLinkClickHandler();
        }
    }

    hashLinkClickHandler() {
        const aTagArray = this.aside.current.getElementsByTagName('a');
        if (aTagArray) {
            for (const item of aTagArray) {
                const itemHref = item.getAttribute('href');
                if (itemHref.startsWith('#')) {
                    item.addEventListener('click', (event) => {
                        event.preventDefault();
                        const targetId = itemHref.slice(1);
                        const targetElement = document.getElementById(targetId);

                        if (targetElement) {
                            targetElement.scrollIntoView({ behavior: 'smooth' });
                            window.location.hash = targetId;
                        }
                    });
                }
            }
        }
    }

    renderCapacitiesList(capacitiesList) {
        return capacitiesList.map((list, key) => {
            return (
                <div key={key}>
                    <div className="room-capacities-block-list">
                        {list.map((item, itemKey) => {
                            return (
                                <div key={itemKey} className="room-capacities-block-list-item">
                                    <div className="title">
                                        <Icon name={item.name.toLowerCase()} type="filter" />
                                        <span>{item.name}</span>
                                    </div>
                                    <span className="label">{item.value}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        });
    }

    render() {
        const { capacities, squareMeters, height, content, aside } = this.props;

        const layoutCapacities = [];

        // Format capacities to be splitted by 4 layouts per row
        for (let i = 0; i < capacities.length; i += ITEMS_PER_ROW) {
            layoutCapacities.push(capacities.slice(i, i + ITEMS_PER_ROW));
        }

        return (
            <section className="room-capacities-block || text-content-block || content-section">
                <div className="constrain-width">
                    <div className="content-section-inner">
                        <main
                            className="content-section-main || rte-content || rich-editor-content"
                            dangerouslySetInnerHTML={{ __html: formatContent(content) }}
                        ></main>
                        <aside className="content-section-aside || rte-content">
                            {aside && <div className="room-capacities-block-aside" ref={this.aside} dangerouslySetInnerHTML={{ __html: formatContent(aside) }}></div>}
                            <Disclaimer />
                            <div className="room-capacities-block-inner">
                                <div className="room-capacities-block-layouts">
                                    <h3 className="room-capacities-block-title">Capacity</h3>
                                    {this.renderCapacitiesList(layoutCapacities)}
                                </div>

                                <div className="room-capacities-block-dimensions">
                                    <h3 className="room-capacities-block-title">Room dimensions</h3>
                                    <div className="room-capacities-block-list">
                                        <div className="room-capacities-block-list-item">
                                            <div className="title">
                                                <Icon name={'size'} />
                                                <span>Size</span>
                                            </div>
                                            <span className="label">{squareMeters ? `${squareMeters}m²` : '–'}</span>
                                        </div>
                                    </div>
                                    <div className="room-capacities-block-list">
                                        <div className="room-capacities-block-list-item">
                                            <div className="title">
                                                <Icon name={'height'} />
                                                <span>Ceiling height</span>
                                            </div>
                                            <span className="label">{height ? `${height}m` : '–'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </section>
        );
    }
}

RoomCapacitiesBlock.propTypes = {
    capacities: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,
    aside: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    height: PropTypes.number,
    squareMeters: PropTypes.number,
};

RoomCapacitiesBlock.defaultProps = {
    description: ''
};

export default RoomCapacitiesBlock;
