import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={30} height={30} viewBox="0 0 113 100" {...props}>
        <title>
            Banquet (dancefloor)
        </title>
        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M502 878 c-26 -26 1 -65 38 -53 16 5 20 14 18 33 -3 30 -35 41 -56
20z"/>
            <path d="M410 849 c-9 -15 -8 -24 1 -35 27 -33 79 1 58 39 -14 23 -46 21 -59
-4z"/>
            <path d="M591 856 c-19 -23 -4 -56 25 -56 28 0 42 25 27 49 -14 22 -37 25 -52
7z"/>
            <path d="M340 779 c-9 -15 -8 -24 1 -35 27 -33 79 1 58 39 -14 23 -46 21 -59
-4z"/>
            <path d="M460 778 c-65 -44 -74 -133 -18 -188 78 -78 208 -24 208 86 0 97
-110 156 -190 102z m130 -43 c34 -37 33 -90 -4 -121 -45 -39 -107 -31 -131 17
-21 41 -18 72 10 103 33 36 92 36 125 1z"/>
            <path d="M659 784 c-17 -21 0 -49 32 -49 27 0 37 28 19 50 -16 19 -34 19 -51
-1z"/>
            <path d="M322 698 c-26 -26 1 -65 38 -53 16 5 20 14 18 33 -3 30 -35 41 -56
20z"/>
            <path d="M679 694 c-16 -20 -1 -48 29 -52 27 -4 49 33 31 54 -15 19 -43 18
-60 -2z"/>
            <path d="M341 606 c-9 -11 -10 -20 -1 -35 13 -26 46 -27 59 -3 20 38 -31 71
-58 38z"/>
            <path d="M653 604 c-8 -22 10 -49 32 -49 10 0 22 7 28 16 15 24 1 49 -28 49
-15 0 -28 -7 -32 -16z"/>
            <path d="M410 531 c-11 -22 9 -51 35 -51 22 0 39 38 24 56 -16 20 -46 17 -59
-5z"/>
            <path d="M583 534 c-8 -22 10 -49 32 -49 10 0 22 7 28 16 15 24 1 49 -28 49
-15 0 -28 -7 -32 -16z"/>
            <path d="M500 515 c-18 -22 -1 -50 31 -50 20 0 24 5 24 30 0 23 -5 31 -21 33
-11 2 -27 -4 -34 -13z"/>
            <path d="M277 493 c-13 -12 -7 -51 8 -57 34 -13 66 24 44 50 -12 15 -41 19
-52 7z"/>
            <path d="M725 479 c-10 -30 18 -58 43 -43 9 6 17 22 17 35 0 34 -50 41 -60 8z" />
            <path d="M184 466 c-11 -28 1 -51 25 -51 32 0 49 28 32 49 -17 20 -50 21 -57
2z"/>
            <path d="M360 465 c-18 -22 -1 -50 31 -50 20 0 24 5 24 30 0 23 -5 31 -21 33
-11 2 -27 -4 -34 -13z"/>
            <path d="M640 460 c-14 -27 4 -52 34 -48 31 4 30 62 -1 66 -14 2 -26 -4 -33
-18z"/>
            <path d="M820 466 c-27 -33 18 -72 48 -42 13 13 14 20 6 34 -15 23 -38 26 -54
8z"/>
            <path d="M122 401 c-8 -4 -12 -19 -10 -32 4 -29 45 -34 61 -7 19 30 -19 60
-51 39z"/>
            <path d="M239 393 c-38 -23 -59 -64 -59 -113 0 -34 6 -48 33 -75 67 -67 162
-52 199 32 17 40 18 49 6 86 -24 75 -115 110 -179 70z m96 -28 c60 -24 72 -89
26 -136 -35 -35 -77 -38 -114 -9 -51 40 -42 115 16 144 39 19 31 19 72 1z"/>
            <path d="M431 392 c-12 -23 4 -52 29 -52 25 0 41 30 29 53 -13 22 -46 22 -58
-1z"/>
            <path d="M570 395 c-18 -22 -1 -50 31 -50 20 0 24 5 24 30 0 23 -5 31 -21 33
-11 2 -27 -4 -34 -13z"/>
            <path d="M692 390 c-67 -41 -77 -133 -20 -190 78 -79 208 -20 208 94 0 31 -7
45 -39 77 -32 32 -46 39 -77 39 -22 0 -54 -9 -72 -20z m125 -42 c30 -28 36
-63 18 -98 -38 -74 -141 -64 -161 17 -10 40 7 76 46 95 38 20 64 16 97 -14z"/>
            <path d="M890 395 c-25 -30 15 -70 45 -45 20 16 19 46 -1 54 -23 9 -30 8 -44
-9z"/>
            <path d="M91 302 c-12 -23 4 -52 29 -52 26 0 45 36 29 56 -16 19 -46 18 -58
-4z"/>
            <path d="M451 302 c-20 -38 31 -71 59 -37 6 8 9 23 5 35 -8 25 -51 27 -64 2z" />
            <path d="M551 306 c-26 -31 14 -71 45 -45 8 7 14 22 12 33 -4 26 -40 33 -57
12z"/>
            <path d="M912 308 c-26 -26 1 -65 38 -53 16 5 20 14 18 33 -3 30 -35 41 -56
20z"/>
            <path d="M897 229 c-23 -14 -21 -46 4 -59 15 -9 24 -8 35 1 33 27 -1 79 -39
58z"/>
            <path d="M113 214 c-8 -22 10 -49 32 -49 22 0 40 27 32 49 -8 21 -56 21 -64 0z" />
            <path d="M430 211 c-7 -15 -6 -23 6 -35 19 -20 29 -20 48 0 19 19 20 26 4 42
-18 18 -46 14 -58 -7z"/>
            <path d="M572 218 c-16 -16 -15 -23 4 -42 19 -20 29 -20 48 0 12 12 13 20 6
35 -12 21 -40 25 -58 7z"/>
            <path d="M183 144 c-8 -22 10 -49 32 -49 22 0 40 27 32 49 -8 21 -56 21 -64 0z" />
            <path d="M361 146 c-22 -26 10 -63 43 -50 35 13 24 64 -14 64 -10 0 -23 -6
-29 -14z"/>
            <path d="M641 146 c-22 -26 10 -63 43 -50 35 13 24 64 -14 64 -10 0 -23 -6
-29 -14z"/>
            <path d="M813 144 c-14 -38 38 -67 60 -33 15 24 1 49 -28 49 -15 0 -28 -7 -32
-16z"/>
            <path d="M274 126 c-11 -28 1 -51 25 -51 32 0 49 28 32 49 -17 20 -50 21 -57
2z"/>
            <path d="M730 125 c-18 -22 -1 -50 31 -50 20 0 24 5 24 30 0 23 -5 31 -21 33
-11 2 -27 -4 -34 -13z"/>
        </g>
    </svg>
);
