import CapacitiesSection from './capacities-section';
import { connect } from 'react-redux';
import { initiateCapacitiesFetchAction } from 'app/ducks/capacities';
import { LAYOUT_TYPES } from 'config/capacities';
import { selectVenues } from 'app/selectors/capacities';


function mapStateToProps(state) { // eslint-disable-line require-jsdoc
    return {
        capacitiesAreReady: Boolean(state.capacities.data && state.capacities.data.length),
        layoutTypes: LAYOUT_TYPES,
        venues: () => selectVenues(state)
    };
}

function mapDispatchToProps(dispatch) { // eslint-disable-line require-jsdoc
    return {
        initiateCapacitiesFetch: () => dispatch(initiateCapacitiesFetchAction())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CapacitiesSection);
