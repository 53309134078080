import arrowRight from './arrow-right';
import bell from './bell';
import building from './building';
import capacity from './capacity';
import chevronCapacity from './chevron-capacity';
import chevronRight from './chevron-right';
import close from './close';
import cross from './cross';
import download from './download';
import email from './email';
import emailFooter from './email-footer';
import expand from './expand';
import facebook from './facebook';
import height from './height';
import instagram from './instagram';
import linkedin from './linkedin';
import mail from './mail';
import menu from './menu';
import negative from './negative';
import neutral from './neutral';
import pdf from './pdf';
import phone from './phone';
import pintrest from './pintrest';
import plus from './plus';
import plusCapacity from './plus-capacity';
import positive from './positive';
import size from './size';
import tick from './tick';
import tumblr from './tumblr';
import twitter from './twitter';

export default {
    'arrow-right': arrowRight,
    bell,
    building,
    capacity,
    'chevron-capacity': chevronCapacity,
    'chevron-right': chevronRight,
    close,
    cross,
    download,
    email,
    expand,
    'email-footer': emailFooter,
    facebook,
    height,
    instagram,
    linkedin,
    mail,
    menu,
    negative,
    neutral,
    pdf,
    phone,
    pintrest,
    plus,
    'plus-capacity': plusCapacity,
    positive,
    size,
    tick,
    tumblr,
    twitter
};
