import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import Tile from 'app/components/partials/tile';
import React, { Component } from 'react';

class OtherVenues extends Component {
    constructor(props) {
        super(props);

        this.state = {
            venues: null
        };
    }


    // Lifecycle
    componentDidMount() {
        this.fetchVenues();
    }

    fetchVenues() {
        fetch(ENDPOINTS.VENUES_ALL)
            .then((response) => {
                return response && response.data && response.data.length && response.data || [];
            })
            .catch(() => {
                return [];
            })
            .then((venues) => {
                this.setState({ venues });
            });
    }


    // Renders
    renderVenue(data, index) {
        const {
            name: title,
            short_description: description,
        } = data.attributes;

        const venueDetails = {
            ...data.attributes,
            description,
            title
        };

        return (
            <Tile
                key={index}
                item={{
                    type: 'venue',
                    data: data.attributes
                }}
                {...venueDetails}
            />
        );
    }

    renderVenues() {
        const { venues } = this.state;
        const { external_venue_id: externalVenueId } = this.props.selectedRoom;

        if (venues) {
            const venuesFiltered = venues.filter((data) => data.attributes.is_published && data.attributes.external_venue_id !== externalVenueId);

            if (venuesFiltered.length) {
                return (
                    <div className="tile-block-inner">
                        {venuesFiltered.map((venue, index) => this.renderVenue(venue, index))}
                    </div>
                );
            }

            return (
                <div className="rooms-filter-empty">
                    <p className="heading-3">
                        Nothing to show here.
                    </p>
                </div>
            );
        }

        return (
            <Loader type="block primary || rooms-filter-empty" />
        );
    }

    render() {
        return (
            <section className="tile-block || block-section">
                <header className="section-header || rooms-filter || constrain-width">
                    <h2 className="heading-1">Other venues in our portfolio</h2>
                </header>
                <div className="constrain-width">
                    {this.renderVenues()}
                </div>
            </section>
        );
    }
}

OtherVenues.propTypes = {
    selectedRoom: PropTypes.object.isRequired
};

export default OtherVenues;
