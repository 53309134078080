import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class CapacitiesVenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingRooms: false
        };
    }


    // Handlers
    showRoomsOrCloseButtonClick(event) {
        const { showingRooms } = this.state;

        if (showingRooms) {
            const nextScrollPosition = event.target.parentNode.getBoundingClientRect().top - document.documentElement.getBoundingClientRect().top - (document.documentElement.clientHeight / 2);
            document.body.scrollTop = nextScrollPosition;
            document.documentElement.scrollTop = nextScrollPosition;
        }
        this.setState({ showingRooms: !showingRooms });
    }


    // Renderers
    renderRoom({ name, capacities, squareMeters, height, slug }, key) {
        const { layoutTypes } = this.props;

        return (
            <div key={key} className="capacities-grid-room capacities-grid-row">
                <div className="capacities-grid-text-cell">
                    <div className="capacities-grid-text-cell-inner">
                        <Link to={`/rooms/${slug}`}>
                            {name}
                            <Icon name="chevron-capacity" />
                        </Link>
                        <div className="capacities-grid-room-meta-desktop">
                            Size: <span className="capacities-grid-room-meta-desktop-value">{squareMeters ? `${squareMeters}m²` : '–'}</span><br />
                            Ceiling height: <span className="capacities-grid-room-meta-desktop-value">{height ? `${height}m` : '–'}</span>
                        </div>
                    </div>
                </div>
                {capacities.map((capacity, capacityIndex) => (
                    <div
                        key={capacityIndex}
                        className="capacities-grid-cell"
                    >
                        <div className="capacities-grid-cell-label">
                            <div className="capacities-grid-cell-label-inner">
                                <Icon name={layoutTypes[capacityIndex].toLowerCase()} type="filter" />
                                {layoutTypes[capacityIndex]}
                            </div>
                        </div>
                        <div className="capacities-grid-cell-value">
                            <div className="capacities-grid-cell-value-inner">{capacity || '–'}</div>
                        </div>
                    </div>
                ))}
                <div className="capacities-grid-room-meta-mobile">
                    <div className="capacities-grid-cell">
                        <div className="capacities-grid-cell-label">
                            <div className="capacities-grid-cell-label-inner">
                                <Icon name="size" />
                                Size
                            </div>
                        </div>
                        <div className="capacities-grid-cell-value">
                            <div className="capacities-grid-cell-value-inner">{squareMeters ? `${squareMeters}m²` : '–'}</div>
                        </div>
                    </div>
                    <div className="capacities-grid-cell">
                        <div className="capacities-grid-cell-label">
                            <div className="capacities-grid-cell-label-inner">
                                <Icon name="height" />
                                Ceiling height
                            </div>
                        </div>
                        <div className="capacities-grid-cell-value">
                            <div className="capacities-grid-cell-value-inner">{height ? `${height}m` : '–'}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderRooms() {
        const { rooms } = this.props;

        return (
            <div style={{ overflow: 'hidden' }}>
                {rooms.map(this.renderRoom.bind(this))}
            </div>
        );
    }

    render() {
        const { name, capacities, slug } = this.props;
        const { showingRooms } = this.state;

        return (
            <div className={classnames('capacities-grid-venue-and-rooms', { 'is-active': this.state.showingRooms })}>
                <div className="capacities-grid-venue capacities-grid-row">
                    <div className="capacities-grid-text-cell">
                        <div className="capacities-grid-text-cell-inner">
                            <Link to={`/venues/${slug}`}>
                                {name}
                                <Icon name="chevron-capacity" />
                            </Link>
                        </div>
                    </div>
                    {capacities.map((capacity, capacityIndex) => (
                        <div
                            key={capacityIndex}
                            className="capacities-grid-cell"
                        >
                            <div className="capacities-grid-cell-value">
                                <div className="capacities-grid-cell-value-inner">{capacity || '–'}</div>
                            </div>
                        </div>
                    ))}
                </div>
                {showingRooms && this.renderRooms()}
                <button
                    type="button"
                    onClick={this.showRoomsOrCloseButtonClick.bind(this)}
                    className="capacities-grid-venue-and-rooms-button || button primary"
                    style={{
                        minWidth: 'auto'
                    }}
                >
                    <Icon name="plus-capacity" />
                    {showingRooms ? 'Close' : 'Show rooms'}
                </button>
            </div>
        );
    }
}

CapacitiesVenue.propTypes = {
    name: PropTypes.string.isRequired,
    capacities: PropTypes.array.isRequired,
    rooms: PropTypes.array.isRequired,
    slug: PropTypes.string.isRequired,
    layoutTypes: PropTypes.array.isRequired
};

export default CapacitiesVenue;
