/* eslint-disable require-jsdoc */
import { buildUrl } from 'app/utilities/url';
import { connect } from 'react-redux';
import FilterResultsPage from './filter-results-page';
import { push } from 'redux-first-history';
import { selectIsVisible } from 'app/selectors/enquiry-modal';
import { withLocation } from 'app/utilities/router-hoc.js';
import {
    initiateFilterResultFetchAction,
    setIsLayoutTypeSelectorAction,
    setLayoutTypeAction
} from 'app/ducks/filter';
import {
    selectCapacity,
    selectEventType,
    selectFilterResults,
    selectIsBusy,
    selectIsLayoutTypeSelectorActive,
    selectLayoutType,
} from 'app/selectors/filter';
import {
    setSelectedRoomAction,
    toggleModalAction
} from 'app/ducks/enquiry-modal';

function mapStateToProps(state) {
    return {
        eventType: selectEventType(state),
        capacity: selectCapacity(state),
        isBusy: (eventType, capacity, layoutType) => selectIsBusy(eventType, capacity, layoutType, state),
        getFilterResults: (eventType, capacity, layoutType) => selectFilterResults(eventType, capacity, layoutType, state),
        layoutType: selectLayoutType(state),
        isLayoutTypeSelectorActive: selectIsLayoutTypeSelectorActive(state),
        isModalVisible: selectIsVisible(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setLayoutType: (layoutType) => {
            dispatch(setLayoutTypeAction(layoutType));
        },
        setIsLayoutTypeSelectorActive: (isActive) => {
            dispatch(setIsLayoutTypeSelectorAction(isActive));
        },
        initiateFilter: (eventType, capacity, layoutType) => {
            dispatch(initiateFilterResultFetchAction(eventType, capacity, layoutType));
        },
        nagivateToFilterResults: (eventType, capacity, layoutType) => {
            dispatch(push(buildUrl('/filter', {
                event_type: eventType, // eslint-disable-line camelcase
                capacity,
                layout_type: layoutType // eslint-disable-line camelcase
            })));
        },
        toggleModal: () => dispatch(toggleModalAction()),
        setSelectedRoom: (selectedRoom) => dispatch(setSelectedRoomAction(selectedRoom))
    };
}

const VisibleFilterResultsPage = withLocation(connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: true }
)(FilterResultsPage));

export default VisibleFilterResultsPage;
