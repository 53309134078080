import canUseDom from './dom';
import { ENDPOINTS } from 'config/api';
import { getJsonStr } from 'app/utilities/format';
import { url } from 'rfa-react-core';

const CMS_PAGE_DEPTH = 2;
const VENUES_DETAIL_DEPTH = 3;
const ROOMS_FILTER_DEPTH = 2;
export const ENTITY_TYPE = {
    PAGE: 'page',
    ROOMS: 'rooms',
    VENUES: 'venues',
    NEWS: 'news',
    VENUES_DETAIL: 'venues-detail',
    ROOMS_FILTER: 'rooms-filter'
};

export const getSlug = (reqUrl) => {
    return url.getSlug(reqUrl);
};

export const getParent = (reqUrl) => {
    return url.getParent(reqUrl);
};

/**
 * Returns page view type based off the requrest URL
 * @param  {string} requestURL
 * @return {string} requestType
 */
export const getEntityType = (reqUrl) => {
    const arr = reqUrl.split('/');
    const base = arr.length > CMS_PAGE_DEPTH ? arr[1] : '';

    if (!canUseDom()) {
        console.log('url --> getEntityType', 'arr', getJsonStr(arr));
    }

    /**
     * Venes detail like '/venues/xxxxxxxx'
     */
    if (arr.length === VENUES_DETAIL_DEPTH && base === ENTITY_TYPE.VENUES) {
        return ENTITY_TYPE.VENUES_DETAIL;
    }

    /**
     * Rooms filter like '/filter' or '/filter?xxxx=yyyy'
     */
    if (arr.length === ROOMS_FILTER_DEPTH && arr[1].indexOf('filter') === 0) {
        return ENTITY_TYPE.ROOMS_FILTER;
    }

    if (base === ENTITY_TYPE.NEWS) {
        return ENTITY_TYPE.NEWS;
    }

    switch (base) {
        case ENTITY_TYPE.ROOMS:
            return ENTITY_TYPE.ROOMS;
        case ENTITY_TYPE.VENUES:
            return ENTITY_TYPE.VENUES;
        default:
            return ENTITY_TYPE.PAGE;
    }
};

/**
 * Returns the correct endpoint based on page type
 * @param  {string} requestType e.g animal, page, news
 * @return {string} one of API { ENDPOINTS.X }
 */
export const getEndpoint = (requestType) => {
    console.log(`getEndpoint called, requestType: ${requestType}`);

    switch (requestType) {
        case ENTITY_TYPE.ROOMS:
            return ENDPOINTS.ROOMS;
        case ENTITY_TYPE.VENUES:
            return ENDPOINTS.VENUES;
        case ENTITY_TYPE.NEWS:
            return ENDPOINTS.NEWS;
        case ENTITY_TYPE.VENUES_DETAIL:
            return ENDPOINTS.VENUES;
        default:
            return ENDPOINTS.PAGES;
    }
};

export const getApiUrl = (reqUrl) => {
    return url.getApiUrl(reqUrl, getEntityType, getEndpoint);
};

export const getRouteArray = (reqUrl) => {
    const reqUrlWithoutTrailingSlash = reqUrl.endsWith('/') && reqUrl.slice(0, -1) || reqUrl;

    return url.getRouteArray(reqUrlWithoutTrailingSlash);
};

export const buildUrl = (path, params) => {
    const paramsString = Object.keys(params)
        .filter((param) => Boolean(params[param]))
        .map((param) => {
            return `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`;
        })
        .join('&');

    return `${path}${paramsString ? '?' : ''}${paramsString}`;
};

/**
 * `Entity` is one of the concepts from `CMS`, it looks like a `category` under every `project` inside `CMS`.
 *
 * @param {any} entityType
 */
const getEndpointArrByEntity = (entityType, queryParams) => {
    const roomEndpoints = [{
        dataKey: ENTITY_TYPE.PAGE,
        endPoint: ENDPOINTS.ROOMS,
        apiUrl: ''
    }];

    const venueEnpoints = [{
        dataKey: ENTITY_TYPE.PAGE,
        endPoint: ENDPOINTS.VENUES,
        apiUrl: ''
    }];

    const newsEnpoints = [{
        dataKey: ENTITY_TYPE.PAGE,
        endPoint: ENDPOINTS.NEWS,
        apiUrl: ''
    }];

    const venueDetailEnpoints = [{
        dataKey: ENTITY_TYPE.PAGE,
        endPoint: ENDPOINTS.VENUES,
        apiUrl: '',
        subRequest: {
            requestType: ENTITY_TYPE.VENUES_DETAIL,
            dataKey: ENTITY_TYPE.ROOMS,
            endPoint: ENDPOINTS.ROOMS_FILTER_BY_VENUES_ID,
            apiUrl: ''
        }
    }];

    const roomsFilterEnpoints = [
        {
            dataKey: ENTITY_TYPE.PAGE,
            endPoint: ENDPOINTS.PAGES,
            apiUrl: ''
        },
        {
            dataKey: ENTITY_TYPE.ROOMS_FILTER,
            endPoint: ENDPOINTS.ROOMS_FILTER_BY_CONDITION,
            apiUrl: '',
            queryParams
        }
    ];

    const pageEnpoints = [{
        dataKey: ENTITY_TYPE.PAGE,
        endPoint: ENDPOINTS.PAGES,
        apiUrl: ''
    }];

    switch (entityType) {
        case ENTITY_TYPE.ROOMS:
            return roomEndpoints;
        case ENTITY_TYPE.VENUES:
            return venueEnpoints;
        case ENTITY_TYPE.NEWS:
            return newsEnpoints;
        case ENTITY_TYPE.VENUES_DETAIL:
            return venueDetailEnpoints;
        case ENTITY_TYPE.ROOMS_FILTER:
            return roomsFilterEnpoints;
        default:
            return pageEnpoints;
    }
};

const getApiRequest = (reqUrl, entityTypeHandler, endpointHandler, queryParams) => {
    const entityType = entityTypeHandler(reqUrl);
    const endpointArr = endpointHandler(entityType, queryParams);
    const slug = getSlug(reqUrl);

    if (!canUseDom()) {
        console.log('getApiRequest', 'entityType', entityType);
        console.log('getApiRequest', 'endpointArr', getJsonStr(endpointArr));
    }

    // Calulate the `apiUrl` for each endpoit
    const requestEndpoints = endpointArr.map((tempEndpoint) => {
        // `enpointArr[?].enpoint` can be `string | function`
        if (typeof tempEndpoint.endPoint === 'function') {
            let apiUrl = '';

            if (tempEndpoint.endPoint === ENDPOINTS.PAGES) {
                apiUrl = tempEndpoint.endPoint(slug, getParent(reqUrl));
            } else if (tempEndpoint.endPoint === ENDPOINTS.ROOMS_FILTER_BY_CONDITION) {
                apiUrl = tempEndpoint.endPoint(queryParams);
            } else {
                apiUrl = tempEndpoint.endPoint(slug);
            }

            return Object.assign({}, tempEndpoint, { apiUrl: apiUrl });
        }

        return Object.assign({}, tempEndpoint, { apiUrl: tempEndpoint.endPoint });
    });

    return requestEndpoints;
};

export const getApiRequestArr = (reqUrl, queryParams) => {
    const reqUrlWithoutTrailingSlash = reqUrl.endsWith('/') && reqUrl.slice(0, -1) || reqUrl;

    return getApiRequest(reqUrlWithoutTrailingSlash, getEntityType, getEndpointArrByEntity, queryParams);
};
