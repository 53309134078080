import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={30} height={30} viewBox="0 0 30 30" {...props}>
        <title>
            Neutral
        </title>
        <path fill="currentColor" d="M15 29c7.728 0 14-6.272 14-14S22.728 1 15 1 1 7.272 1 15s6.272 14 14 14zM14 8h3v3h-3V8zm0 5h3v10h-3V13z" />
    </svg>
);
