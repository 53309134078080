export default {
    linkedin: 'https://www.linkedin.com/company/auckland-conventions',
    facebook: 'https://www.facebook.com/AklConventions',
    instagram: 'https://www.instagram.com/aucklandconventions'
};

export const FACEBOOK_APP_ID = '219812285222195';

export const CLICK_TRACKING_ATTRIBUTES = {
    'data-id': 'micro_conversion',
    'data-type': 'engaging_content',
    'data-action': 'social_click'
};
