import { connect } from 'react-redux';
import Footer from './footer';
import selectContactInformation from 'app/selectors/contact-information';
import { showPhoneNumberAction } from 'app/ducks/contact-information';

function mapStateToProps(state) { // eslint-disable-line require-jsdoc
    return {
        phoneNumberIsVisible: selectContactInformation(state).phoneNumberIsVisible
    };
}

function mapDispatchToProps(dispatch) { // eslint-disable-line require-jsdoc
    return {
        showPhoneNumber: () => dispatch(showPhoneNumberAction())
    };
}

const VisibleFooter = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: true }
)(Footer);

export default VisibleFooter;
