import { EVENT_TYPES_WITH_LAYOUT_VARIATIONS } from 'config/filter';

/* eslint-disable require-jsdoc */
export function selectIsBusy(eventType, capacity, layoutType, { filter }) {
    const { cache } = filter;

    // Make sure to only allow filter by layoutType for certain eventTypes
    if (EVENT_TYPES_WITH_LAYOUT_VARIATIONS.includes(eventType) && layoutType && layoutType !== '') {
        return !cache.some((cacheItem) => (cacheItem.eventType === eventType && cacheItem.capacity === capacity && cacheItem.layoutType === layoutType));
    }

    return !cache.some((cacheItem) => (cacheItem.eventType === eventType && cacheItem.capacity === capacity));
}

export function selectEventType({ filter }) {
    return filter.eventType;
}

export function selectCapacity({ filter }) {
    return filter.capacity;
}

export function selectFilterResults(eventType, capacity, layoutType, { filter }) {
    const { cache } = filter;

    const filterResultCandidates = cache.filter((cacheItem) => {
        // Make sure to only allow filter by layoutType for certain eventTypes
        if (EVENT_TYPES_WITH_LAYOUT_VARIATIONS.includes(eventType) && layoutType && layoutType !== '') {
            return cacheItem.eventType === eventType && cacheItem.capacity === capacity && cacheItem.layoutType === layoutType;
        }

        return cacheItem.eventType === eventType && cacheItem.capacity === capacity;
    });

    if (filterResultCandidates.length) {
        return filterResultCandidates[0].filterResults;
    }

    return [];
}

export function selectLayoutType({ filter }) {
    return filter.layoutType;
}

export function selectIsLayoutTypeSelectorActive({ filter }) {
    return filter.isLayoutTypeSelectorActive;
}
