import Helmet from 'react-helmet';
import React, { Component } from 'react';

class Error extends Component {
    render() {
        return (
            <main className="page" role="main">
                <Helmet>
                    <title>Error 500</title>
                </Helmet>
                <header className="text-header || constrain-width">
                    <h1>Oops! It looks like we have a server error.</h1>
                    <p>Not to worry though, our team are busy looking into it for you. If you have an urgent enquiry please contact: <a href="mailto:enquire@aucklandconventions.co.nz">enquire@aucklandconventions.co.nz</a> or call <a href="tel:+6493092677">+64 9 309 2677</a></p>
                </header>
            </main>
        );
    }
}

export default Error;
