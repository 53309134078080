import { connect } from 'react-redux';
import EnquiryModal from './enquiry-modal';
import { selectLayoutType } from 'app/selectors/filter';
import {
    resetEnquiryFormAction,
    setEnquiryFormFieldValueAction,
    submitEnquiryFormAction,
    touchedEnquiryFormFieldAction,
} from 'app/ducks/enquiry-modal-form';
import {
    selectedRoom,
    selectedStep,
    selectIsVisible
} from 'app/selectors/enquiry-modal';
import {
    selectFieldTouched,
    selectFieldValue,
    selectIsBusy,
    selectSubmitted,
    selectSubmittedSuccessfully
} from 'app/selectors/enquiry-modal-form';
import {
    setSelectedStepAction,
    toggleModalAction
} from 'app/ducks/enquiry-modal';

function mapStateToProps(state) { // eslint-disable-line require-jsdoc
    return {
        getFieldTouched: (fieldName, step) => selectFieldTouched(state, fieldName, step),
        getFieldValue: (fieldName, step) => selectFieldValue(state, fieldName, step),
        submitted: selectSubmitted(state),
        isBusy: selectIsBusy(state),
        submittedSuccessfully: selectSubmittedSuccessfully(state),
        selectedRoom: selectedRoom(state),
        selectedStep: selectedStep(state),
        isVisible: selectIsVisible(state),
        selectedLayoutType: selectLayoutType(state)
    };
}

function mapDispatchToProps(dispatch) { // eslint-disable-line require-jsdoc
    return {
        setFieldValue: (fieldName, value, step) => dispatch(setEnquiryFormFieldValueAction(fieldName, value, step)),
        touchedField: (fieldName, step) => dispatch(touchedEnquiryFormFieldAction(fieldName, step)),
        submitForm: (formData, captchaToken) => dispatch(submitEnquiryFormAction(formData, captchaToken)),
        resetForm: () => dispatch(resetEnquiryFormAction()),
        setSelectedStep: (selectedStep) => dispatch(setSelectedStepAction(selectedStep)),
        toggleModal: () => dispatch(toggleModalAction()),
    };
}

const VisibleEnquiryModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(EnquiryModal);

export default VisibleEnquiryModal;
