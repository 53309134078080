export const CARPARKS = [
    {
        id: 'Civic',
        name: 'The Civic',
        url: 'https://at.govt.nz/driving-parking/parking-in-auckland/civic-car-park/'
    },
    {
        id: 'Downtown',
        name: 'Downtown',
        url: 'https://at.govt.nz/driving-parking/parking-in-auckland/downtown-car-park/'
    },
    {
        id: 'Victoria St',
        name: 'Victoria St',
        url: 'https://at.govt.nz/driving-parking/parking-in-auckland/victoria-st-car-park/'
    },
    {
        id: 'Toka Puia',
        name: 'Toka Puia',
        url: 'https://at.govt.nz/driving-parking/find-parking/parking-in-north-auckland/toka-puia-car-park',
    }
];
