import pageParents from 'config/page-parents';

export default (slug) => {
    const pageParent = pageParents.find((pageParent) => pageParent.slug === slug);
    if (pageParent) {
        return pageParent;
    }

    // If the parent is unknown, fall back to this.
    return {
        slug,
        name: 'Pages'
    };
};
