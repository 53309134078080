import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <title>
            boardroom
        </title>
        <defs>
            <path id="a" d="M.813.519h5.482V6H.813z" />
            <path id="c" d="M.073.518h5.481V6H.073z" />
            <path id="e" d="M.555.518h5.481V6H.555z" />
            <path id="g" d="M.769.776H6.25v5.482H.769z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path fill="currentColor" d="M17.481 22.42a2.74 2.74 0 1 1-5.481.001 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0m0 9.5a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0" />
            <g transform="translate(36 66.224)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path fill="currentColor" d="M6.288 3.06a2.74 2.74 0 1 1-5.466.4 2.74 2.74 0 0 1 5.466-.4" mask="url(#b)" />
            </g>
            <g transform="translate(24 66.224)">
                <mask id="d" fill="#fff">
                    <use xlinkHref="#c" />
                </mask>
                <path fill="currentColor" d="M5.554 3.26a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0" mask="url(#d)" />
            </g>
            <g transform="translate(49 66.224)">
                <mask id="f" fill="#fff">
                    <use xlinkHref="#e" />
                </mask>
                <path fill="currentColor" d="M6.036 3.26a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0" mask="url(#f)" />
            </g>
            <path fill="currentColor" d="M42.288 11.624a2.74 2.74 0 1 1-5.466.4 2.74 2.74 0 0 1 5.466-.4" />
            <g transform="translate(23 8.224)">
                <mask id="h" fill="#fff">
                    <use xlinkHref="#g" />
                </mask>
                <path fill="currentColor" d="M6.25 3.517a2.74 2.74 0 1 1-5.481 0 2.74 2.74 0 0 1 5.481 0" mask="url(#h)" />
            </g>
            <path fill="currentColor" d="M55.036 11.824a2.74 2.74 0 1 1-5.48 0 2.74 2.74 0 0 1 5.48 0M67.109 22.42a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0m0 9.5a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0m0 9.5a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0m0 9.5a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0m0 9.5a2.74 2.74 0 1 1-5.482 0 2.74 2.74 0 0 1 5.482 0m-19.582-6.155H31.583c-.737 0-1.335-.58-1.335-1.298v-24.74c0-.837.718-1.515 1.604-1.515h15.406c.886 0 1.604.678 1.604 1.515v24.74c0 .717-.598 1.298-1.335 1.298m9.453-36.387H22.13c-.737 0-1.334.581-1.334 1.298v42.982c0 .716.597 1.297 1.335 1.297H56.98c.737 0 1.334-.58 1.334-1.297V19.176c0-.717-.597-1.298-1.334-1.298" />
        </g>
    </svg>
);
