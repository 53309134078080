import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Twitter
        </title>
        <path fill="currentColor" d="M29.574 15.499c-.68.304-1.41.51-2.177.601a3.829 3.829 0 0 0 1.667-2.115 7.549 7.549 0 0 1-2.407.928 3.772 3.772 0 0 0-2.768-1.208c-2.093 0-3.79 1.713-3.79 3.825 0 .3.033.591.098.871a10.735 10.735 0 0 1-7.814-3.996 3.834 3.834 0 0 0-.513 1.923c0 1.326.67 2.497 1.686 3.183a3.74 3.74 0 0 1-1.717-.479v.049a3.818 3.818 0 0 0 3.04 3.75 3.747 3.747 0 0 1-1.711.065 3.8 3.8 0 0 0 3.541 2.656 7.564 7.564 0 0 1-5.612 1.584 10.655 10.655 0 0 0 5.81 1.718c6.973 0 10.786-5.827 10.786-10.881 0-.166-.003-.331-.01-.495a7.725 7.725 0 0 0 1.891-1.98z" />
    </svg>
);
