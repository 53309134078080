import { transformRooms, venueCapacities } from '../utilities/capacities';

export function selectVenues(state) { // eslint-disable-line require-jsdoc
    return state.capacities.data.map(({ name, slug, rooms }) => ({
        name,
        capacities: venueCapacities(rooms),
        rooms: transformRooms(rooms),
        slug
    }));
}
