import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
        <title>
            theatre
        </title>
        <defs>
            <path id="a" d="M0 .391h7.769v7.768H0z" />
            <path id="c" d="M0 .293h7.769v7.768H0z" />
            <path id="e" d="M0 .428h7.769v7.768H0z" />
            <path id="g" d="M0 .391h7.769v7.768H0z" />
            <path id="i" d="M0 .195h7.769v7.769H0z" />
            <path id="k" d="M0 .232h7.769V8H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(15 11.61)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <path fill="currentColor" d="M7.769 4.275a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0" mask="url(#b)" />
            </g>
            <g transform="translate(15 21.61)">
                <mask id="d" fill="#fff">
                    <use xlinkHref="#c" />
                </mask>
                <path fill="currentColor" d="M7.769 4.177a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884" mask="url(#d)" />
            </g>
            <g transform="translate(15 31.61)">
                <mask id="f" fill="#fff">
                    <use xlinkHref="#e" />
                </mask>
                <path fill="currentColor" d="M7.769 4.312a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884" mask="url(#f)" />
            </g>
            <g transform="translate(15 41.61)">
                <mask id="h" fill="#fff">
                    <use xlinkHref="#g" />
                </mask>
                <path fill="currentColor" d="M7.769 4.275a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0" mask="url(#h)" />
            </g>
            <g transform="translate(15 51.61)">
                <mask id="j" fill="#fff">
                    <use xlinkHref="#i" />
                </mask>
                <path fill="currentColor" d="M7.769 4.079A3.884 3.884 0 1 1 0 4.08a3.884 3.884 0 0 1 7.769-.001" mask="url(#j)" />
            </g>
            <path fill="currentColor" d="M33.602 15.884a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0m0 9.902a3.884 3.884 0 1 1-7.77 0 3.884 3.884 0 0 1 7.77 0m0 10.135a3.884 3.884 0 1 1-7.77 0 3.884 3.884 0 0 1 7.77 0m0 9.963a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0m0 9.804a3.884 3.884 0 1 1-7.769.001 3.884 3.884 0 0 1 7.769-.001m21.333-39.803a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0m0 9.901a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884m0 10.135a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884m0 9.963a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0m0 9.804a3.884 3.884 0 1 1-7.768.001 3.884 3.884 0 0 1 7.768-.001M65.77 15.885a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0m0 9.901a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884m0 10.135a3.883 3.883 0 0 1-3.884 3.884 3.884 3.884 0 1 1 0-7.768 3.884 3.884 0 0 1 3.884 3.884m0 9.963a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0m0 9.804a3.884 3.884 0 1 1-7.769.001 3.884 3.884 0 0 1 7.769-.001" />
            <g transform="translate(15 61.61)">
                <mask id="l" fill="#fff">
                    <use xlinkHref="#k" />
                </mask>
                <path fill="currentColor" d="M7.769 4.116a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0" mask="url(#l)" />
            </g>
            <path fill="currentColor" d="M33.602 65.725a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0m21.333 0a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0m10.834 0a3.884 3.884 0 1 1-7.768 0 3.884 3.884 0 0 1 7.768 0" />
        </g>
    </svg>
);
