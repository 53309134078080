import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Arrow Right
        </title>
        <path fill="currentColor" fillRule="evenodd" d="M28.964 18.754H5v3.422h23.965l-7.31 7.36 2.38 2.397 11.387-11.467L24.036 9l-2.38 2.397 7.308 7.357z" />
    </svg>
);
