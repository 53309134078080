import {
    CAPACITY_RANGES,
    DEFAULT_CAPACITY_RANGE,
    EVENT_TYPES,
    EVENT_TYPES_WITH_LAYOUT_VARIATIONS,
    LAYOUT_TYPES,
} from 'config/filter';


/**
 * Returns the API label using the event type
 * @param  {string} eventTypeValue  - The event type
 * @return {string} apiLabel        - One of EVENT_TYPES apiLabel values
 */
export function eventTypeValueToApiLabel(eventTypeValue) {
    const eventType = EVENT_TYPES.find((eventType) => eventType.value === eventTypeValue);

    if (eventType) return eventType.apiLabel;

    return EVENT_TYPES[0].apiLabel;
}


/**
 * Returns the API label using the layout type
 * @param  {string} layoutTypeValue  - The event type
 * @return {string} apiLabel        - One of EVENT_TYPES apiLabel values
 */
export function layoutTypeValueToApiLabel(layoutTypeValue) {
    const layoutType = LAYOUT_TYPES.find((layoutType) => layoutType.value === layoutTypeValue);

    if (layoutType) return layoutType.apiLabel;

    return LAYOUT_TYPES[0].apiLabel;
}


/**
 * Returns the capacity based using the event type
 * @param {object} room             - The room object
 * @param {string} eventType        - The event type
 * @param {string} layoutType        - The layout type
 * @returns {object} capacities     - Min and Max capacity
 */
export function getDataByEventType(room, eventType, layoutType = null) {
    const {
        thumbnail,
        event_types: capacitiesByEventType
    } = room;

    let image = thumbnail,
        {
            capacity_min: min,
            capacity_max: max
        } = room;

    if (eventType) {
        const eventTypeApiLabel = eventTypeValueToApiLabel(eventType);

        if (eventTypeApiLabel) {
            const eventTypeObject = capacitiesByEventType
                .find((eventTypeCapacity) => eventTypeCapacity.name === eventTypeApiLabel);

            // Assign new min/max using event type
            if (eventTypeObject) {
                min = eventTypeObject.capacity_min;
                max = eventTypeObject.capacity_max;
                image = eventTypeObject.thumbnail ? eventTypeObject.thumbnail : thumbnail;
            }

            // Assign new min/max using layout type if exists
            if (layoutType && layoutType !== '' && EVENT_TYPES_WITH_LAYOUT_VARIATIONS.includes(eventType)) {
                const layoutTypeApiLabel = layoutTypeValueToApiLabel(layoutType);

                if (layoutTypeApiLabel && eventTypeObject.hasOwnProperty('layout_types')) {
                    const layoutTypeObject = eventTypeObject.layout_types
                        .find((layoutTypeObject) => layoutTypeObject.name === layoutTypeApiLabel);

                    if (layoutTypeObject) {
                        min = layoutTypeObject.capacity_min;
                        // Append layout type label to indicate change
                        max = `${layoutTypeObject.capacity_max} (${layoutTypeApiLabel})`;
                    }
                }
            }
        }
    }

    return {
        min,
        max,
        image
    };
}


/**
 * Returns formatted capacity label using the capacity object
 * @param  {object} capacity    - Capacity object with min and max attributes
 * @return {string}             - Capacity label
 */
export function capacityRangeToLabel({ min, max }) {
    if (min === null || max === null) {
        return 'Enquire';
    }

    return `${min === 0 ? 'Up to' : `${min} -`} ${max}`;
}


/**
 * Returns the eventType object using the filter query string
 * @param  {string} filterQueryString - The filter query string
 * @return {object}                   - With eventType and capacity attributes
 */
export function getEventTypeAndCapacityFromFilterQueryString(filterQueryString) {
    const queryParams = Object.fromEntries(new URLSearchParams(filterQueryString));
    let { event_type: eventType, capacity, layout_type: layoutType } = queryParams;

    if (!EVENT_TYPES.some((eventTypeItem) => eventTypeItem.value === eventType)) {
        eventType = EVENT_TYPES[0].value;
    }

    if (!CAPACITY_RANGES.some((capacityRange) => capacityRange.value === capacity)) {
        capacity = DEFAULT_CAPACITY_RANGE.value;
    }

    if (!LAYOUT_TYPES.some((layoutTypeItem) => layoutTypeItem.value === layoutType)) {
        layoutType = LAYOUT_TYPES[0].value;
    }

    return {
        eventType,
        capacity,
        layoutType
    };
}
