import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Tumblr
        </title>
        <path fill="currentColor" d="M20.448 14.847V10.55h-2.75a1.505 1.505 0 0 0-.092.54c-.03.093-.063.16-.063.253-.282 1.594-1.102 2.705-2.492 3.313-.412.191-.79.22-1.2.191v3.465h2.021c.033 4.875.033 7.388.033 7.515v.29c.254 2.126 1.356 3.4 3.312 3.88.788.22 1.638.318 2.492.318a11.633 11.633 0 0 0 3.25-.572v-4.077c-.628.191-1.2.352-1.704.51-.977.287-1.798.097-2.463-.54-.063-.097-.156-.225-.189-.353a8.839 8.839 0 0 1-.152-1.557V18.31h4.381v-3.464h-4.384z" />
    </svg>
);
