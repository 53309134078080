// This needs to be kept in sync with the page parent options in the CMS.
export default [
    {
        slug: 'news',
        name: 'News'
    },
    {
        slug: 'event-types',
        name: 'Event types'
    },
    {
        slug: 'about-us',
        name: 'About us'
    },
    {
        slug: 'services',
        name: 'Services'
    }
];

