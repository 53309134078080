import classnames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const SLIDE_ANIMATION_DURATION = 600;
const SLIDE_ROTATION_DURATION = 5000;

class Carousel extends React.Component {
    constructor() {
        super();

        this.state = this.getInitialState();

        this.autoRotate = null;
    }

    getInitialState() {
        return {
            activeSlide: 0,
            autoRotate: true,
            nextReady: true
        };
    }


    // Lifecycle
    componentDidMount() {
        if (this.props.items.length > 1) {
            this.startAutoRotate();
        }
    }

    componentWillUnmount() {
        this.stopAutoRotate();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.props.items) {
            this.stopAutoRotate();
            this.setState(this.getInitialState);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items) {
            this.startAutoRotate();
        }
    }


    // Helper
    isNext(index) {
        const { activeSlide, nextReady } = this.state;

        // If last image, first one becomes 'next' as well
        if (nextReady && index === 0 && activeSlide !== index) {
            return true;
        }

        return nextReady && ((activeSlide + 1 === index) || (activeSlide - 1 === index));
    }

    nextReadyAfterTime() {
        setTimeout(() => {
            this.setState({
                nextReady: true
            });
        }, SLIDE_ANIMATION_DURATION);
    }

    startAutoRotate() {
        this.autoRotate = setInterval(() => {
            const shouldRotate = this.state.autoRotate;
            const isLastSlide = (this.state.activeSlide + 1 === this.props.items.length);

            if (shouldRotate && isLastSlide) {
                // Restart carousel
                this.updateSlide(0);
            } else if (shouldRotate) {
                this.nextSlide();
            } else {
                // Stop the rotation
                this.stopAutoRotate();
            }
        }, SLIDE_ROTATION_DURATION);
    }

    stopAutoRotate() {
        clearInterval(this.autoRotate);
    }

    previousSlide() {
        const nextActiveSlide = Math.max(this.state.activeSlide - 1, 0);

        this.updateSlide(nextActiveSlide);
    }

    nextSlide() {
        const { items } = this.props;

        const nextActiveSlide = Math.min(this.state.activeSlide + 1, items.length - 1);

        this.updateSlide(nextActiveSlide);
    }

    updateSlide(slide = 0) {
        this.setState({
            activeSlide: slide,
            nextReady: false
        }, this.nextReadyAfterTime);
    }

    trackUserScroll() {
        const { items } = this.props;
        const { activeSlide } = this.state;

        /* ↓ Tracking carousel scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'gallery_click',
            text: items[activeSlide].file
        });
        /* ↑ Tracking carousel scroll events */
    }


    // Handler
    handlePrevious() {
        this.trackUserScroll();

        this.setState({
            autoRotate: false
        }, this.previousSlide());
    }

    handleNext() {
        this.trackUserScroll();

        this.setState({
            autoRotate: false
        }, this.nextSlide());
    }


    // Render
    render() {
        const { items } = this.props;
        const { activeSlide } = this.state;
        const slideButtonClass = 'icon-button alternative-light large || change-slide';
        const previousButtonClass = classnames(`${slideButtonClass} previous`, {
            'is-disabled': (activeSlide === 0)
        });
        const nextButtonClass = classnames(`${slideButtonClass} next`, {
            'is-disabled': (activeSlide === items.length - 1)
        });

        return (
            <div className="image-carousel-content-block || content-section">
                <div className="constrain-width large">
                    <div className="carousel">
                        <div className="preserve-aspect-ratio">
                            { items.map(({ file, link, title }, index) => {
                                const itemClass = classnames('carousel-media', {
                                    'is-active': (activeSlide === index),
                                    'is-next': this.isNext(index)
                                });

                                return (
                                    <div className={itemClass} key={`carousel-media-${index}`}>
                                        <a className="carousel-image || preserve-aspect-ratio-media || image-overlay" href={link} style={{ backgroundImage: `url(${file})` }}>
                                            <img className="fixed-image" src={file} alt={title} />
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="carousel-info">
                            { items.map(({ link, title, subtitle }, index) => {
                                const itemClass = classnames('carousel-item', {
                                    'is-active': (activeSlide === index),
                                    'is-next': this.isNext(index)
                                });

                                return (
                                    <div className={itemClass} key={`carousel-item-${index}`}>
                                        <h3 className="carousel-title || heading-1 secondary">
                                            <a href={link} tabIndex="-1">{title}</a>
                                        </h3>
                                        <hr className="carousel-break"/>
                                        <p className="carousel-text">{subtitle}</p>
                                    </div>
                                );
                            })}
                        </div>
                        {items.length > 1 &&
                            <div className="carousel-controls">
                                <button
                                    className={previousButtonClass}
                                    onClick={this.handlePrevious.bind(this)}
                                >
                                    <Icon name="arrow-right" title="Previous" />
                                </button>
                                <button
                                    className={nextButtonClass}
                                    onClick={this.handleNext.bind(this)}
                                >
                                    <Icon name="arrow-right" title="Next" />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

Carousel.propTypes = {
    items: PropTypes.array.isRequired
};

export default Carousel;
