import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Tick
        </title>
        <path fill="currentColor" d="M16.4 24.4l11.8-11.9 1.6 1.6-13.4 13.4-6.2-6.2 1.6-1.6z" />
    </svg>
);
