import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Mail
        </title>
        <path fill="currentColor" d="M27.2 13H12.8c-.995 0-1.791.84-1.791 1.875L11 26.125c0 1.036.805 1.875 1.8 1.875h14.4c.995 0 1.8-.84 1.8-1.875v-11.25C29 13.839 28.195 13 27.2 13zm.086 3.53L20 20.94l-7.286-4.412v-1.764L20 19.176l7.286-4.411v1.764z" />
    </svg>
);
