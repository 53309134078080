import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Negative
        </title>
        <path fill="currentColor" d="M20 3C10.616 3 3 10.616 3 20s7.616 17 17 17 17-7.616 17-17S29.384 3 20 3zm2 26h-4v-4h4v4zm0-7h-4V10h4v12z" />
    </svg>
);
