import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Facebook
        </title>
        <path fill="currentColor" d="M24.022 20.626h-2.855v10.46h-4.325v-10.46h-2.057V16.95h2.057v-2.378c0-1.701.808-4.365 4.364-4.365l3.204.014v3.568h-2.325c-.381 0-.917.19-.917 1.002v2.163H24.4l-.378 3.672z" />
    </svg>
);
