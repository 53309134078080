import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Chevron Right
        </title>
        <path fill="currentColor" fillRule="evenodd" d="M12.265 34.552l-.985-.985 13.434-13.434L11.259 6.677l1.99-1.99 15.425 15.424-15.425 15.425z" />
    </svg>
);
