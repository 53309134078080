import EnquiryModal from 'app/components/partials/enquiry-modal.container';
import { fireDelayedHistoryChange } from 'app/utilities/gtm';
import Helmet from 'react-helmet';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import { description as metaDescription } from 'config/seo';
import PropTypes from 'prop-types';
import Tile from 'app/components/partials/tile';
import { EVENT_TYPES_WITH_LAYOUT_VARIATIONS, LAYOUT_TYPES } from 'config/filter';
import { getDataByEventType, getEventTypeAndCapacityFromFilterQueryString } from 'app/utilities/filter';
import React, { Component } from 'react';

const FILTER_ELEMENT_ID = 'filter-group';

class FilterResultsPage extends Component {
    constructor() {
        super();

        this.state = {
            showScrollButton: false,
            scrollTimer: null,
        };

        this.openEnquiryModal = this.openEnquiryModal.bind(this);
    }

    componentDidMount() {
        fireDelayedHistoryChange();
        if (typeof window === undefined) return;

        if (this.filterGroup) {
            // Add the scroll to top functionality
            window.addEventListener('scroll', () => {
                if (this.state.scrollTimer) {
                    clearTimeout(this.state.scrollTimer); // Clear any previous pending timer
                }

                this.setState({
                    scrollTimer: setTimeout(() => {
                        const doc = document.documentElement;
                        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
                        const filterOffset = 1000;

                        if (top > filterOffset) {
                            this.setState({ showScrollButton: true });
                        } else {
                            this.setState({ showScrollButton: false });
                        }
                    }, 100) // eslint-disable-line no-magic-numbers
                });
            });
        }

        const { eventType, capacity, layoutType } = this.getEventTypeAndCapacityFromQueryParams();

        /**
         * Pay attention here:
         *
         * If the `filter` state is filled by SSR, then we don't need to dispatch query, otherwise, we HAVE TO
         * dispatch a new query, or it will present the WRONG rooms (the prev state which not match current
         * search or venues)
         */
        if (window.__INITIAL_STATE__ && window.__INITIAL_STATE__.filter) {
            // Make sure this condition only be trigger once !!!
            delete window.__INITIAL_STATE__.filter;

            // But we still need to check IF has no filter state situation, for example the case below will not
            // have filter state:
            // SSR "/rooms/lower-nzi" --> click on "Find" button --> code run to here --> remove the flag  --> no filter state !!!
            const { getFilterResults } = this.props;

            const filterResults = getFilterResults(eventType, capacity, layoutType);
            if (!filterResults || filterResults.length <= 0) {
                this.props.initiateFilter(eventType, capacity, layoutType);
            }
        } else {
            this.props.initiateFilter(eventType, capacity, layoutType);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { eventType, capacity, layoutType } = this.props;

        if (nextProps.eventType !== eventType || nextProps.capacity !== capacity || nextProps.layoutType !== layoutType) {
            this.props.initiateFilter(nextProps.eventType, nextProps.capacity, nextProps.layoutType);
            fireDelayedHistoryChange();
        }
    }


    // Getters
    getEventTypeAndCapacityFromQueryParams() {
        return getEventTypeAndCapacityFromFilterQueryString(this.props.location.search);
    }


    // Handlers
    handleScrollToFilter(event) {
        event.preventDefault();

        this.setState({
            showScrollButton: false
        });

        window.scrollTo({
            top: 0, // document.getElementById(FILTER_ELEMENT_ID).offsetTop - 100, // elsint-disable-line no-magic-numbers
            left: 0,
            behavior: 'smooth'
        });
    }

    openEnquiryModal(selectedRoom) {
        this.props.setSelectedRoom(selectedRoom);
        this.props.toggleModal();
    }

    getMeetingLayoutTypes(eventTypes) {
        return eventTypes.find((eventType) => eventType.name.toLowerCase() === 'meeting').layout_types;
    }

    // Render
    renderRoom(room, roomIndex, venue) {
        const {
            name,
            short_description: description
        } = room;

        const { eventType, layoutType } = this.getEventTypeAndCapacityFromQueryParams();

        // Only on the filter results page we pass the layout type variable
        const { image, min, max } = getDataByEventType(room, eventType, layoutType);

        let meetingLayoutTypes = eventType === 'meeting' ? this.getMeetingLayoutTypes(room.event_types) : [];

        meetingLayoutTypes = meetingLayoutTypes.sort((a, b) => a.name > b.name);

        const roomDetails = {
            ...room,
            title: name,
            capacity: { min, max },
            thumbnail: image,
            description,
            meetingLayoutTypes,
            venue,
        };

        const enquireButton = (eventType === 'meeting' ? <button className="button alternative || tile-custom-button" onClick={this.openEnquiryModal.bind(this, roomDetails)}>Enquire meeting room</button> : null);

        return (
            <Tile
                key={roomIndex}
                item={{
                    type: 'room',
                    data: room
                }}
                {...roomDetails}
                customButton={enquireButton}
            />
        );
    }

    renderVenue(venue, venueIndex) {
        const {
            name,
            rooms,
            slug
        } = venue.attributes;

        return (
            <section key={venueIndex} className="filter-results-venue">
                <div className="filter-results-venue-header">
                    <h2 className="filter-results-venue-header-title">{name}</h2>
                    <Link className="button alternative" to={`/venues/${slug}`} >
                        View venue
                    </Link>
                </div>
                <div className="tile-block-inner">
                    {rooms.map((room, index) => this.renderRoom(room, index, venue))}
                </div>
            </section>
        );
    }

    renderLayoutFilter() {
        const { eventType, capacity, layoutType } = this.getEventTypeAndCapacityFromQueryParams();
        const {
            isLayoutTypeSelectorActive: isActive,
            setIsLayoutTypeSelectorActive,
            nagivateToFilterResults
        } = this.props;

        if (!EVENT_TYPES_WITH_LAYOUT_VARIATIONS.includes(eventType)) return null;

        return (
            <div className="filter-group" id={FILTER_ELEMENT_ID} ref={(filterGroup) => { this.filterGroup = filterGroup; }}>
                <h4 className="filter-group-title">Filter by layout type</h4>

                <button
                    className={`filter-group-toggle ${isActive ? 'is-active' : ''}`}
                    onClick={() => { setIsLayoutTypeSelectorActive(!isActive); }}>
                    <span className="filter-group-toggle-inner">
                        <span>Filter by layout type</span>
                        <Icon name="chevron-right" />
                    </span>
                </button>

                <div className={`filter-group-actions ${isActive ? 'is-active' : ''}`}>
                    {LAYOUT_TYPES.map(({ label, value, icon }, index) => {
                        return (
                            <button
                                key={index}
                                className={`filter-group-button ${value === layoutType ? 'is-active' : ''}`}
                                onClick={() => { nagivateToFilterResults(eventType, capacity, value); }}>
                                <span className="filter-group-button-inner">
                                    {icon ? <Icon name={icon} type="filter" /> : <span className="icon"></span>}
                                    <span className="filter-group-button-label">
                                        <span className="filter-group-button-label-inner">{label}</span>
                                    </span>
                                </span>
                            </button>
                        );
                    })}
                </div>

                {/* Sroll button to send you back to the layout filter */}
                <button
                    className={`button primary || filter-group-scroll-button ${this.state.showScrollButton ? 'is-visible' : ''}`}
                    onClick={this.handleScrollToFilter.bind(this)}>
                    <Icon name="chevron-right" />
                    <span>Change layout type</span>
                </button>
            </div>
        );
    }

    renderFilterResults() {
        const { getFilterResults, isBusy } = this.props;
        const { capacity, eventType, layoutType } = this.getEventTypeAndCapacityFromQueryParams();

        const isLoading = isBusy(eventType, capacity, layoutType);

        const filterResults = getFilterResults(eventType, capacity, layoutType);
        const noResults = filterResults.length === 0;

        // For other event types
        return (
            <div className="tile-block">
                <h1 className="filter-results-page-title">
                    {isLoading && <Loader type="block primary" />}
                    <span className="filter-results-page-title-text">{noResults ? 'Sorry, there are no results' : 'Here’s what we’ve found for you:'}</span>
                </h1>

                {this.renderLayoutFilter()}

                {!noResults &&
                    <div className="filter-results-venues">
                        {filterResults.map(this.renderVenue.bind(this))}
                    </div>
                }
            </div>
        );
    }

    render() {
        const title = 'Auckland Conventions - Search';
        const keywords = 'filter results';

        return (
            <main className="filter-results-page" role="main">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>{title}</title>
                    <meta name="description" content={metaDescription} />
                    <meta name="keywords" content={keywords} />
                </Helmet>
                <article className="constrain-width">
                    {this.renderFilterResults()}
                </article>
                {this.props.isModalVisible &&
                    <EnquiryModal/>
                }
            </main>
        );
    }
}

FilterResultsPage.propTypes = {
    layoutType: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
    capacity: PropTypes.string.isRequired,
    setLayoutType: PropTypes.func.isRequired,
    setIsLayoutTypeSelectorActive: PropTypes.func.isRequired,
    getFilterResults: PropTypes.func.isRequired,
    initiateFilter: PropTypes.func.isRequired,
    isBusy: PropTypes.func.isRequired,
    isLayoutTypeSelectorActive: PropTypes.bool.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    nagivateToFilterResults: PropTypes.func.isRequired,
    setSelectedRoom: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired
};

export default FilterResultsPage;
