import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Email
        </title>
        <path fill="currentColor" d="M30.1 15v-2.5L20 18.7 9.9 12.5V15L20 21.2 30.1 15zm0-5.1c.7 0 1.2.2 1.7.8.4.6.7 1.1.7 1.8v15c0 .7-.2 1.2-.8 1.7-.5.5-1 .8-1.7.8H9.9c-.7 0-1.2-.3-1.8-.8-.5-.5-.7-1.1-.7-1.7v-15c0-.7.2-1.2.8-1.8S9.3 10 10 10l20.1-.1z" />
    </svg>
);
