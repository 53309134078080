import icons from './icons';
import PropTypes from 'prop-types';
import React from 'react';

class Icon extends React.Component {
    render() {
        let options = this.props;

        const { name, type } = this.props;

        // Allow 'type' to determine 'sprite'
        const sprite = icons.hasOwnProperty(type) ? icons[type] : icons.default;
        const iconComponent = sprite[name];

        // Default options should hide SVG
        if (type === 'default') {
            options = {
                'aria-hidden': true,
                'focusable': true,
                ...options
            };
        }

        return iconComponent && iconComponent(options) || null;
    }
}

Icon.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string
};

Icon.defaultProps = {
    className: 'icon',
    type: 'default'
};

export default Icon;
