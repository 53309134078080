import { capacityRangeToLabel } from 'app/utilities/filter';
import { EntityLink } from 'rfa-react-core';
import PropTypes from 'prop-types';
import React from 'react';
import TileIcon from 'app/components/partials/tile-icon';
import truncate from 'app/utilities/truncate';

const DESCRIPTION_CHARACTER_LIMIT = 120;

function Tile({ item, title, description, thumbnail, children, capacity, featured, customButton }) { // eslint-disable-line require-jsdoc
    let icon = null,
        style = {};

    if (thumbnail) {
        style = { backgroundImage: `url(${thumbnail})` };
    }

    if (capacity) {
        icon = (
            <TileIcon
                name="capacity"
                title="Capacity"
                description={capacityRangeToLabel(capacity)}
            />
        );
    }

    return (
        <article className="tile">
            <EntityLink
                item={item}
                promoterBuName="Auckland Conventions"
                className="tile-link-wrap"
            >
                {featured && <p className="tile-badge || feature-room-badge || heading-6">
                    Featured
                </p>}
                {children}
                <div className="tile-thumbnail" style={style}></div>
                <div className="tile-inner || image-overlay" style={style}>
                    <div className="tile-title">
                        <div className="tile-title-inner">
                            <h3 className="tile-title-description">{title}</h3>
                            {icon}
                        </div>
                    </div>
                    {description && <p className="tile-subtitle">{truncate(description, DESCRIPTION_CHARACTER_LIMIT)}</p>}
                </div>
            </EntityLink>
            {customButton}
        </article>
    );
}

Tile.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    customButton: PropTypes.node, // A button element that is rendered on the bottom of the tile.
    description: PropTypes.string,
    featured: PropTypes.bool,
    capacity: PropTypes.object,
    item: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.string
};

export default Tile;
