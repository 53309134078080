import { connect } from 'react-redux';
import EnquirySection from './enquiry-section';
import selectContactInformation from 'app/selectors/contact-information';
import {
    showEmailAction,
    showPhoneNumberAction
} from 'app/ducks/contact-information';

function mapStateToProps(state) { // eslint-disable-line require-jsdoc
    return {
        contactInformation: selectContactInformation(state)
    };
}

function mapDispatchToProps(dispatch) { // eslint-disable-line require-jsdoc
    return {
        showEmail: () => dispatch(showEmailAction()),
        showPhoneNumber: () => dispatch(showPhoneNumberAction())
    };
}

const VisibleEnquirySection = connect(
    mapStateToProps,
    mapDispatchToProps
)(EnquirySection);

export default VisibleEnquirySection;
