import { LAYOUT_TYPES } from 'config/capacities';

export function venueCapacities(rooms) { // eslint-disable-line require-jsdoc
    const capacitiesMap = {};
    rooms.forEach(({ event_types: eventTypes }) => {
        eventTypes.forEach(({ layout_types: layoutTypes }) => {
            layoutTypes.forEach(({ name, capacity_max: capacityMax }) => {
                capacitiesMap[name] = Math.max(capacitiesMap[name] || 0, capacityMax);
            });
        });
    });

    return LAYOUT_TYPES.map((layoutType) => capacitiesMap[layoutType]);
}

export function roomCapacities(eventTypes, roomsPageFormat = false) { // eslint-disable-line require-jsdoc
    const capacitiesMap = {};
    eventTypes.forEach(({ layout_types: layoutTypes }) => {
        layoutTypes.forEach(({ name, capacity_max: capacityMax }) => {
            capacitiesMap[name] = Math.max(capacitiesMap[name] || 0, capacityMax);
        });
    });

    if (roomsPageFormat) {
        return Object.keys(capacitiesMap).map((key) => {
            return {
                name: key,
                value: capacitiesMap[key]
            };
        });
    }

    return LAYOUT_TYPES.map((layoutType) => capacitiesMap[layoutType]);
}

export function transformRooms(rooms, roomsPageFormat = false) { // eslint-disable-line require-jsdoc
    return rooms.map(({ height, square_meters: squareMeters, name, event_types: eventTypes, slug }) => ({
        name,
        capacities: roomCapacities(eventTypes, roomsPageFormat),
        squareMeters,
        height,
        slug
    }));
}
