import { dataLayerPush } from 'app/utilities/gtm';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import ENQUIRY_FORM_FIELDS, { FIELD_NAMES } from 'config/enquiry-form-fields';

const RESET_ENQUIRY_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY = 10000; // 10 seconds

export const INITIAL_STATE = {
    fields: ENQUIRY_FORM_FIELDS.reduce((acc, { name, type }) => {
        acc[name] = {
            // Empty array or string depending on the field type
            value: type === 'multi-select' ? [] : '',
            touched: false
        };

        return acc;
    }, {}),
    submitted: false,
    submittedSuccessfully: undefined,
    venueOptions: []
};


/*
    Actions
 */
export const SET_ENQUIRY_FORM_FIELD_VALUE = 'rfa-conventions-website/enquiry/SET_ENQUIRY_FORM_FIELD_VALUE';
export const TOUCHED_ENQUIRY_FORM_FIELD = 'rfa-conventions-website/enquiry/TOUCHED_ENQUIRY_FORM_FIELD';
export const SUBMIT_ENQUIRY_FORM = 'rfa-conventions-website/enquiry/SUBMIT_ENQUIRY_FORM';
export const ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED = 'rfa-conventions-website/enquiry/ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED';
export const FAILED_TO_SUBMIT_ENQUIRY_FORM = 'rfa-conventions-website/enquiry/FAILED_TO_SUBMIT_ENQUIRY_FORM';
export const RESET_ENQUIRY_FORM = 'rfa-conventions-website/enquiry/RESET_ENQUIRY_FORM';
export const FETCH_VENUE_OPTIONS = 'rfa-conventions-website/enquiry/FETCH_VENUE_OPTIONS';
export const FETCH_VENUE_OPTIONS_SUCCESS = 'rfa-conventions-website/enquiry/FETCH_VENUE_OPTIONS_SUCCESS';


/*
    Action Creators
 */
export const setEnquiryFormFieldValueAction = (fieldName, value) => ({
    type: SET_ENQUIRY_FORM_FIELD_VALUE,
    fieldName,
    value
});

export const touchedEnquiryFormFieldAction = (fieldName) => ({
    type: TOUCHED_ENQUIRY_FORM_FIELD,
    fieldName
});

export const submitEnquiryFormAction = (formData, captchaToken) => ({
    type: SUBMIT_ENQUIRY_FORM,
    formData,
    captchaToken
});

export const enquiryFormSuccessfullySubmittedAction = () => ({
    type: ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED
});

export const failedToSubmitEnquiryFormAction = () => ({
    type: FAILED_TO_SUBMIT_ENQUIRY_FORM
});

export const resetEnquiryFormAction = () => ({
    type: RESET_ENQUIRY_FORM
});

export const fetchVenueOptionsAction = (location) => ({
    type: FETCH_VENUE_OPTIONS,
    location
});

export const fetchVenueOptionsSuccessAction = (venueOptions) => ({
    type: FETCH_VENUE_OPTIONS_SUCCESS,
    venueOptions
});


/*
    Reducers
 */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ENQUIRY_FORM_FIELD_VALUE:
            return setEnquiryFormFieldValue(state, action.fieldName, action.value);
        case TOUCHED_ENQUIRY_FORM_FIELD:
            return touchedEnquiryFormField(state, action.fieldName);
        case SUBMIT_ENQUIRY_FORM:
            return submitEnquiryForm(state);
        case ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED:
            return enquiryFormSuccessfullySubmitted(state);
        case FAILED_TO_SUBMIT_ENQUIRY_FORM:
            return failedToSubmitEnquiryForm(state);
        case FETCH_VENUE_OPTIONS:
            return fetchVenueOptions(state, action.location);
        case FETCH_VENUE_OPTIONS_SUCCESS:
            return fetchVenueOptionsSuccess(state, action.venueOptions);
        case RESET_ENQUIRY_FORM:
            return resetEnquiryForm(state);
        default:
            return state;
    }
};


/*
    Reducer Helpers
 */
function setEnquiryFormFieldValue(state, fieldName, value) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                value
            }
        }
    };
}

function touchedEnquiryFormField(state, fieldName) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        fields: {
            ...state.fields,
            [fieldName]: {
                ...state.fields[fieldName],
                touched: true
            }
        }
    };
}

function submitEnquiryForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: undefined
    };
}

function enquiryFormSuccessfullySubmitted(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: true,
        fields: INITIAL_STATE.fields
    };
}

function failedToSubmitEnquiryForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: false
    };
}

function fetchVenueOptions(state) { // eslint-disable-line require-jsdoc
    return {
        ...state
    };
}

function fetchVenueOptionsSuccess(state, venueOptions) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        venueOptions
    };
}

function resetEnquiryForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...INITIAL_STATE,
        venueOptions: state.venueOptions || INITIAL_STATE.venueOptions
    };
}


/*
    Epic creators helpers
 */
function trackEnquiryFormSubmission(formData) { // eslint-disable-line require-jsdoc
    /* ↓ Tracking successful form submissions */
    /* eslint-disable camelcase */
    dataLayerPush({
        event: 'formSubmitted', // Overrides the default 'conversionTracking' value.
        form_conversion: 'macro_conversion',
        form_type: 'lead',
        form_action: 'enquiry_form_submission',
        form_data: JSON.stringify(formData),
        form_text: formData.message

    });
    /* eslint-enable camelcase */
    /* ↑ Tracking successful form submissions */
}

const fetchVenueOptionsSuccessActionSequence = (venueOptions, location) => {
    // Enquiry form 'venues' field
    const enquiryFromField = {
        fieldName: FIELD_NAMES.venues,
        value: []
    };

    // Use location object to set current venue if found in venueOptions
    if (location.pathname.indexOf('/venues/') >= 0) {
        const currentVenueRoute = location.pathname.split('/venues/')[1];

        venueOptions.forEach((option) => {
            // Slugify the option value to check
            const slugifyValue = option.value.replace(/ /g, '-').toLowerCase();

            if (currentVenueRoute === slugifyValue) {
                // Pass option value not the slugified value
                enquiryFromField.value.push(option.value);
            }
        });
    }

    return [
        setEnquiryFormFieldValueAction(enquiryFromField.fieldName, enquiryFromField.value),
        fetchVenueOptionsSuccessAction(venueOptions)
    ];
};


/*
    Epic creators
 */

/**
 * @callback enquiryFormSuccessfullySubmittedAction
 * @returns {object} action
 */

/**
 * @callback failedToSubmitEnquiryFormAction
 * @returns {object} action
 */

/**
 * Creates enquiry form submission epic
 * @param {string} enquiryEndpoint - get in touch endpoint
 * @param {enquiryFormSuccessfullySubmittedAction} enquiryFormSuccessfullySubmittedAction - action creator
 * @param {failedToSubmitEnquiryFormAction} failedToSubmitEnquiryFormAction - action creator
 * @returns {observable}
 */
export const createEnquiryFormSubmissionEpic = (enquiryEndpoint, enquiryFormSuccessfullySubmittedAction, failedToSubmitEnquiryFormAction) => {
    return (action$) => action$.pipe(
        ofType(SUBMIT_ENQUIRY_FORM),
        mergeMap(({ formData, captchaToken }) => {
            /**
             Uncomment the following return statement to mock successful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(enquiryFormSuccessfullySubmittedAction());
            //     }, 3000);
            // });

            /**
             Uncomment the following return statement to mock unsuccessful
             form submission.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(failedToSubmitEnquiryFormAction());
            //     }, 3000);
            // });

            return fetch(enquiryEndpoint, null, {
                method: 'POST',
                body: JSON.stringify({
                    ...formData,
                    captchaToken,
                })
            })
                .then(() => {
                    trackEnquiryFormSubmission(formData);

                    return enquiryFormSuccessfullySubmittedAction();
                })
                .catch(() => {
                    return failedToSubmitEnquiryFormAction();
                });
        })
    );
};

export const createScheduleEnquiryFormResettingEpic = (setTimeout, resetEnquiryFormOnSuccessfullSubmissionDelay) => {
    return (action$) => action$.pipe(
        ofType(ENQUIRY_FORM_SUCCESSFULLY_SUBMITTED),
        mergeMap(() => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(resetEnquiryFormAction());
                }, resetEnquiryFormOnSuccessfullSubmissionDelay);
            });
        })
    );
};

export const createFetchVenueOptionsEpic = (venuesEndpoint, fetchVenueOptionsSuccessAction) => {
    return (action$) => action$.pipe(
        ofType(FETCH_VENUE_OPTIONS),
        mergeMap(({ location }) => {
            return (
                fetch(venuesEndpoint())
                    .then((response) => {
                        const venueOptions = formatVenueOptionsResponse(response);

                        return fetchVenueOptionsSuccessActionSequence(venueOptions, location);
                    })
                    .catch(() => {
                        // TODO: Add proper error handling
                        return fetchVenueOptionsSuccessAction();
                    })
            );
        })
    );
};

/*
    Epics
 */
const enquiryFormSubmissionEpic = createEnquiryFormSubmissionEpic(
    ENDPOINTS.ENQUIRY,
    enquiryFormSuccessfullySubmittedAction,
    failedToSubmitEnquiryFormAction
);

const scheduleFormResettingEpic = createScheduleEnquiryFormResettingEpic(
    setTimeout,
    RESET_ENQUIRY_FORM_ON_SUCCESSFULL_SUBMISSION_DELAY
);

const fetchVenueOptionsEpic = createFetchVenueOptionsEpic(
    ENDPOINTS.VENUES,
    fetchVenueOptionsSuccessAction,
    formatVenueOptionsResponse
);

export const epics = combineEpics(
    enquiryFormSubmissionEpic,
    fetchVenueOptionsEpic,
    scheduleFormResettingEpic,
);


/*
    Format
 */

/**
 * Formats the Venues API response into the desired dropdown list shape
 *
 * @param  {object} response      - Response from API
 * @return {array} venueOptions   - Array of the venue options
 */
export function formatVenueOptionsResponse(response) {
    const { data } = response;
    const venueOptions = [];

    // Re-format each response item
    data.forEach(({ attributes }) => {
        const { name, thumbnail } = attributes;

        venueOptions.push({
            label: name,
            value: name,
            thumbnail: thumbnail
        });
    });

    return venueOptions;
}
