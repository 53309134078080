import { Map } from 'immutable';

export const INITIAL_STATE = new Map({
    emailIsVisible: false,
    phoneNumberIsVisible: false
});

// Actions
export const SHOW_EMAIL = 'rfa-conventions-website/contact-information/SHOW_EMAIL';
export const SHOW_PHONE_NUMBER = 'rfa-conventions-website/contact-information/SHOW_PHONE_NUMBER';

// Action Creators
export const showEmailAction = () => ({ type: SHOW_EMAIL });
export const showPhoneNumberAction = () => ({ type: SHOW_PHONE_NUMBER });

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_EMAIL:
            return state.set('emailIsVisible', true);
        case SHOW_PHONE_NUMBER:
            return state.set('phoneNumberIsVisible', true);
        default:
            return state;
    }
};
