import { entityType } from 'app/utilities/format';
import Error from './error/error-page';
import fappageStructuredData from 'app/components/partials/faqpage-structured-data';
import Helmet from 'react-helmet';
import { isAppProd } from 'config/env';
import NotFound from './error/not-found-page';
import printBreadcrumbList from 'app/utilities/print-breadcrumb-list';
import PropTypes from 'prop-types';
import React from 'react';
import { RESPONSE } from 'config/api';
import { siteTitle } from 'config/seo';
import Template from 'app/components/templates/index';

const ContentPage = ({ page }) => {
    // Render not error/not found page
    if (!page || page.error && page.error.status === RESPONSE.INTERNAL_SERVER_ERROR) return <Error />;
    if (page.error && page.error.status === RESPONSE.NOT_FOUND || !page.attributes) return <NotFound />;

    const {
        attributes,
        type
    } = page;

    // Prevent homepage from showing page title
    const pageTitle = attributes.slug === 'home' ? siteTitle : `${attributes.seo_title}`;

    const image = attributes.main_images && attributes.main_images[0] || attributes.thumbnail;

    const isNoIndex = !isAppProd || !attributes.is_published;

    return (
        <main className="page" role="main">
            <Helmet>
                {isNoIndex && <meta name="robots" content="noindex, nofollow" />}

                <title>{pageTitle}</title>
                <meta name="description" content={attributes.seo_description} />
                <meta name="keywords" content={attributes.seo_keywords} />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={attributes.seo_description} />
                {image && <meta name="twitter:image" content={image} />}

                {/* Open Graph */}
                <meta property="og:locale" content="en_NZ" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={attributes.seo_description} />
                {image && <meta property="og:image" content={image} />}
            </Helmet>
            <Template
                type={entityType(type)}
                data={attributes}
            />
            {printBreadcrumbList(page)}
            {attributes.slug === 'faq' && fappageStructuredData(attributes)}
        </main>
    );
};

ContentPage.propTypes = {
    page: PropTypes.object.isRequired
};

export default ContentPage;
