/* eslint-disable require-jsdoc */
import { buildUrl } from 'app/utilities/url';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import SearchForm from './search-form';
import {
    selectCapacity,
    selectEventType
} from 'app/selectors/filter';
import {
    setCapacityAction,
    setEventTypeAction
} from 'app/ducks/filter';

function mapStateToProps(state) {
    return {
        capacity: selectCapacity(state),
        eventType: selectEventType(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setEventType: (eventType) => dispatch(setEventTypeAction(eventType)),
        setCapacity: (capacity) => dispatch(setCapacityAction(capacity)),
        nagivateToFilterResults: (eventType, capacity) => {
            dispatch(push(buildUrl('/filter', {
                event_type: eventType, // eslint-disable-line camelcase
                capacity,
                layoutType: null
            })));
        }
    };
}

const VisibleSearchForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchForm);

export default VisibleSearchForm;
