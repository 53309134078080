import { getYtVideoId } from 'app/utilities/yt';
import PropTypes from 'prop-types';
import React from 'react';

class Video extends React.Component {
    get credit() {
        if (this.props.data.credit) return (<h6 className="credit">{this.props.data.credit}</h6>);
    }

    get title() {
        if (this.props.data.title) return (<h5 className="title">{this.props.data.title}</h5>);
    }

    render() {
        const { credit, title, video_url: videoUrl } = this.props.data;

        return (
            <section className="video-content-block || block-section dark">
                <div className="constrain-width">
                    <header className="section-header center">
                        <div className="section-header-inner">
                            <h2 className="section-header-title">{title}</h2>
                            <p className="section-header-subtitle">{credit}</p>
                        </div>
                    </header>
                    <div className="video-content-block-inner">
                        <div className="preserve-aspect-ratio">
                            <div className="preserve-aspect-ratio-media || tile-frame">
                                <iframe
                                    title={title}
                                    className="tile-frame-image || video"
                                    src={`https://www.youtube.com/embed/${getYtVideoId(videoUrl)}?rel=0&controls=1&showinfo=0`}
                                    allowFullScreen={true}
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Video.propTypes = {
    data: PropTypes.object.isRequired
};

export default Video;
