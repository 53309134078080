import classnames from 'classnames';
import Icon from './icon';
import { LAYOUT_TYPES } from 'config/capacities';
import PropTypes from 'prop-types';
import VenueCapacitiesBlockRoom from './venue-capacities-block-room';
import React, { Component } from 'react';
import { transformRooms, venueCapacities } from 'app/utilities/capacities';


class VenueCapacitiesBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingRooms: false
        };
    }


    // Handlers
    showRoomsOrCloseButtonClick() {
        const { showingRooms } = this.state;
        this.setState({ showingRooms: !showingRooms });
    }


    // Renderers
    renderRoom({ name, capacities, squareMeters, height, slug }, key) {
        return (
            <VenueCapacitiesBlockRoom
                key={key}
                name={name}
                capacities={capacities}
                squareMeters={squareMeters}
                height={height}
                slug={slug}
            />
        );
    }

    renderRooms() {
        const { rooms } = this.props;
        const roomCapacities = transformRooms(rooms);

        return (
            <div>
                {roomCapacities.map(this.renderRoom.bind(this))}
            </div>
        );
    }

    render() {
        const { name, rooms } = this.props;
        const capacities = venueCapacities(rooms);

        const { showingRooms } = this.state;

        return (
            <section className="venue-capacities-grid || content-section">
                <h2 className="venue-capacities-grid-title || heading-1">Capacities</h2>
                <div className="venue-capacities-grid-block">
                    <div className="venue-capacities-grid-heading-wrapper">
                        <div className="venue-capacities-grid-heading venue-capacities-grid-row">
                            <div className="venue-capacities-grid-text-cell">
                                <div className="venue-capacities-grid-text-cell-inner">
                                    Venue / room capacity
                                </div>
                            </div>
                            {LAYOUT_TYPES.map((layoutType, layoutTypeIndex) => (
                                <div
                                    key={layoutTypeIndex}
                                    className="venue-capacities-grid-cell"
                                >
                                    <div className="venue-capacities-grid-cell-inner">
                                        <Icon name={layoutType.toLowerCase()} type="filter" />
                                        {layoutType}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={classnames('venue-capacities-grid-venue-and-rooms', { 'is-active': showingRooms })}>
                        <div className="venue-capacities-grid-venue venue-capacities-grid-row">
                            <div className="venue-capacities-grid-text-cell">
                                <div className="venue-capacities-grid-text-cell-inner">
                                    {name}
                                </div>
                            </div>
                            {capacities.map((capacity, capacityIndex) => (
                                <div
                                    key={capacityIndex}
                                    className="venue-capacities-grid-cell"
                                >
                                    <div className="venue-capacities-grid-cell-value">
                                        <div className="venue-capacities-grid-cell-value-inner">{capacity || '–'}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="venue-capacities-grid-rooms">
                            {this.renderRooms()}
                        </div>
                        <button
                            type="button"
                            onClick={this.showRoomsOrCloseButtonClick.bind(this)}
                            className="venue-capacities-grid-venue-and-rooms-button || button primary"
                        >
                            <Icon name="plus-capacity" />
                            {showingRooms ? 'Close' : 'Show rooms'}
                        </button>
                    </div>
                </div>
            </section>
        );
    }
}

VenueCapacitiesBlock.propTypes = {
    name: PropTypes.string.isRequired,
    rooms: PropTypes.array.isRequired
};

export default VenueCapacitiesBlock;
