import classnames from 'classnames';
import { clientEnv } from 'config/env';
import { createValidationDataObject } from 'app/utilities/form-validation';
import ENQUIRY_FORM_FIELDS from 'config/enquiry-form-fields';
import Icon from 'app/components/partials/icon';
import Loader from 'app/components/partials/loader';
import MultiSelect from 'app/components/partials/multi-select';
import PropTypes from 'prop-types';
import React from 'react';

class EnquiryForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    // Life cycle
    componentDidMount() {
        // Fetch the venue options for the dropdown
        this.props.fetchVenueOptions(this.props.location);
    }

    UNSAFE_componentWillReceiveProps({ isBusy, location, submitted }) {
        // Only update the value if not busy or submitting
        if (!isBusy && !submitted && location !== this.props.location) {
            this.props.fetchVenueOptions(location);
        }
    }

    // Handlers
    handleSubmit(event) {
        event.preventDefault();

        const { getFieldValue, location, submitForm } = this.props;

        const formData = ENQUIRY_FORM_FIELDS.reduce((acc, field) => {
            // Format the field on submission if applicable
            if (field.hasOwnProperty('formatValue')) {
                acc[field.name] = field.formatValue(getFieldValue(field.name));
            } else {
                acc[field.name] = getFieldValue(field.name);
            }

            return acc;
        }, {});

        formData.origin = location.pathname;

        // eslint-disable-next-line no-undef
        grecaptcha.ready(function() {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'submit' }).then(function(token) {
                submitForm(formData, token);
            });
        });
    }

    // Rendering helpers
    renderField({ name, label, placeholder, type, validator }) {
        const { getFieldTouched, getFieldValue, setFieldValue, touchedField, venueOptions } = this.props;

        const touched = getFieldTouched(name);
        const value = getFieldValue(name);
        const validationData = touched ? validator(value) : createValidationDataObject();

        return (
            <div className={classnames('field', { checkbox: type === 'checkbox' })} key={name}>
                <label className="label" htmlFor={name}>
                    <span className="label-static">{label}</span>
                    {!validationData.valid && (
                        <span className="label-validation">{validationData.message}</span>
                    )}
                </label>

                {type === 'multi-line' &&
                    <textarea
                        id={name}
                        className={validationData.valid ? '' : 'is-invalid'}
                        value={value}
                        onChange={(event) => {
                            setFieldValue(name, event.target.value);
                            touchedField(name);
                        }}
                        placeholder={placeholder}
                        onBlur={() => touchedField(name)}
                    />
                }

                {type === 'multi-select' &&
                    <MultiSelect
                        handleToggleValue={(newValue) => {
                            // If value in array then remove/add
                            const inArrayPosition = value.indexOf(newValue);

                            if (inArrayPosition > -1) {
                                // Remove
                                value.splice(inArrayPosition, 1);
                            } else {
                                // Add
                                value.push(newValue);
                            }

                            setFieldValue(name, value);
                        }}
                        isValid={validationData.valid}
                        name={name}
                        options={venueOptions}
                        placeholder={placeholder}
                        values={value}
                    />
                }

                {['tel', 'email', 'text', 'checkbox'].includes(type) &&
                    <input
                        id={name}
                        type={type}
                        className={validationData.valid ? '' : 'is-invalid'}
                        value={value}
                        onChange={(event) => {
                            setFieldValue(name, type === 'checkbox' ? event.target.checked : event.target.value);
                            touchedField(name);
                        }}
                        placeholder={placeholder}
                        onBlur={() => touchedField(name)}
                    />
                }

                {type === 'checkbox' &&
                    <span className="checkbox-button" aria-hidden="true">
                        <Icon className="icon" name="tick" />
                    </span>
                }
            </div>
        );
    }

    renderSubmitButton() {
        const valid = ENQUIRY_FORM_FIELDS.every(({ name, validator }) => {
            return validator(this.props.getFieldValue(name)).valid;
        });

        return (
            <button
                key="button"
                type="submit"
                className="form-submission-button || button alternative"
                disabled={!valid}
            >Submit</button>
        );
    }

    renderSubmitSection() {
        const { isBusy, submitted, submittedSuccessfully } = this.props;

        if (isBusy) {
            return [
                <p key="validation" className="form-submission-validation is-inactive"></p>,
                <button key="form-button" type="button" className="form-submission-button || button alternative">
                    <Loader type="small" />
                </button>
            ];
        }

        if (submitted) {
            if (submittedSuccessfully) {
                return [
                    <p key="validation" className="form-submission-validation is-active">
                        Thanks for getting in touch!
                        <br />
                        A member of our team will be in touch to discuss your requirements as soon as possible.
                    </p>,
                    <button key="form-button" type="button" className="form-submission-button || button alternative">
                        <Icon name="tick" /><span>Sent</span>
                    </button>
                ];
            }

            return [
                <p key="validation" className="form-submission-validation is-active error">Sorry, we didn’t get your message. Please try again later.</p>,
                this.renderSubmitButton()
            ];
        }

        return (
            <div className="form-submission">
                <p key="validation" className="form-submission-validation is-inactive"></p>
                {this.renderSubmitButton()}
            </div>
        );
    }


    // Rendering
    render() {
        return (
            <form
                action="#"
                method="POST"
                className="form"
                onSubmit={this.handleSubmit.bind(this)}
            >
                {ENQUIRY_FORM_FIELDS.map(this.renderField.bind(this))}
                <p className="privacy-policy">We manage your name and email details in accordance with our <a href="/privacy-policy">Privacy Policy</a>.</p>
                <p className="privacy-policy">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                {this.renderSubmitSection()}
            </form>
        );
    }
}

EnquiryForm.propTypes = {
    getFieldTouched: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    fetchVenueOptions: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
    submittedSuccessfully: PropTypes.bool,
    touchedField: PropTypes.func.isRequired,
    venueOptions: PropTypes.array.isRequired
};

export default EnquiryForm;
