import ContentBlocks from 'app/components/content-blocks/index';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import Strip from 'app/components/partials/strip';
import React, { useEffect, useRef, useState } from 'react';

const Home = ({ data }) => {
    const [isStripActive, setIsStripActive] = useState(false);
    const heroCarouselRef = useRef(null);

    const checkHeroCarouselInView = () => {
        const heroCarouselOffset = heroCarouselRef.current.getBoundingClientRect();
        if (heroCarouselOffset.top < 0 && heroCarouselOffset.bottom < 0) return setIsStripActive(false);

        setIsStripActive(true);
    };

    useEffect(() => {
        checkHeroCarouselInView();
        window.addEventListener('scroll', checkHeroCarouselInView);

        return () => window.removeEventListener('scroll', checkHeroCarouselInView);
    }, []);

    const {
        blocks,
        hero_cta_item_id: heroCtaUrl,
        hero_cta_title: heroCtaTitle,
        hero_type: heroType,
        hero_video: heroVideo,
        name: title,
        main_images: images,
        short_description: description
    } = data;

    return (
        <div className="content-page">
            <PageHeader
                homepage
                title={title}
                introduction={description}
                images={images}
                heroCtaUrl={heroCtaUrl}
                heroCtaTitle={heroCtaTitle}
                heroType={heroType}
                heroVideo={heroVideo}
                ref={heroCarouselRef}
            />
            <ContentBlocks data={blocks} />
            <Strip isActive={isStripActive} />
        </div>
    );
};

Home.propTypes = {
    data: PropTypes.object.isRequired
};

export default Home;
