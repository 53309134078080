import EnquiryForm from 'app/components/partials/enquiry-form.container';
import getInTouch from 'static/images/get-in-touch.png';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const EnquirySection = (props) => {
    const location = useLocation();

    // Hide this enquiry form if we are on the dedicated Contact Us page
    if (location?.pathname === '/contact') {
        return null;
    }

    const { contactInformation, showEmail, showPhoneNumber } = props;
    const { emailIsVisible, phoneNumberIsVisible } = contactInformation;

    return (
        <section id="enquire" className="block-section light">
            <div className="constrain-width">
                <header className="section-header">
                    <h2 className="heading-1">Get in touch</h2>
                </header>
                <div className="enquiry-section">
                    <div className="enquiry-card">
                        <img
                            className="enquiry-card-photo"
                            src={getInTouch}
                            width="960"
                            height="685"
                            alt="Get in touch"
                            title="Get in touch"
                        />
                        <address className="enquiry-card-contacts">
                            {emailIsVisible ? (
                                <a
                                    className="enquiry-card-contact"
                                    href="mailto:events@aucklandconventions.co.nz"
                                    /* ↓ Tracking attributes */
                                    data-id="macro_conversion"
                                    data-type="lead"
                                    data-action="email_click"
                                    data-text="events@aucklandconventions.co.nz"
                                    /* ↑ Tracking attributes */
                                >
                                    <Icon name="email" /> events@aucklandconventions.co.nz
                                </a>
                            ) : (
                                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                    className="enquiry-card-contact"
                                    href="#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        showEmail();
                                    }}
                                    /* ↓ Tracking attributes */
                                    data-id="macro_conversion"
                                    data-type="lead"
                                    data-action="email_unhide"
                                    data-text="events@aucklandconventions.co.nz"
                                    /* ↑ Tracking attributes */
                                >
                                    <Icon name="email" /> Show email
                                </a>
                            )}
                            {phoneNumberIsVisible ? (
                                <div className="enquiry-card-contact">
                                    <a
                                        className="phone"
                                        href="tel:+6493092677"
                                        /* ↓ Tracking attributes */
                                        data-id="macro_conversion"
                                        data-type="lead"
                                        data-action="phone_click"
                                        data-text="NZ: +64 9 309 2677"
                                        /* ↑ Tracking attributes */
                                    >
                                        NZ: +64 9 309 2677
                                    </a>
                                    <a
                                        className="phone"
                                        href="tel:1800549898"
                                        /* ↓ Tracking attributes */
                                        data-id="macro_conversion"
                                        data-type="lead"
                                        data-action="phone_click"
                                        data-text="AU: 1800 549 898"
                                        /* ↑ Tracking attributes */
                                    >
                                        AU: 1800 549 898
                                    </a>
                                    <Icon className="phone-icon icon" name="phone" />
                                </div>
                            ) : (
                                <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                    className="enquiry-card-contact"
                                    href="#"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        showPhoneNumber();
                                    }}
                                    /* ↓ Tracking attributes */
                                    data-id="macro_conversion"
                                    data-type="lead"
                                    data-action="phone_unhide"
                                    data-text="NZ: +64 9 307 5444, AU: 1800 549 898"
                                    /* ↑ Tracking attributes */
                                >
                                    <Icon className="phone-icon icon" name="phone" /> Show phone number
                                </a>
                            )}
                            <div className="enquiry-card-contact">
                                <Icon name="building" />Aotea – Te Pokapū | Aotea Centre <br />
                                Level 4 - 50 Mayoral Drive,<br />
                                Auckland, 1010
                            </div>
                        </address>
                        <div className="enquiry-card-button">
                            <Link className="button" to="/about-us">About us</Link>
                        </div>
                    </div>
                    <EnquiryForm />
                </div>
            </div>
        </section>
    );
};

EnquirySection.propTypes = {
    contactInformation: PropTypes.object.isRequired,
    showEmail: PropTypes.func.isRequired,
    showPhoneNumber: PropTypes.func.isRequired
};

export default EnquirySection;
