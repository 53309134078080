import { createValidationDataObject } from 'app/utilities/form-validation';
import Moment from 'moment';

const MAX_CAPACITY = 9999;

export const FIELD_NAMES = {
    date: 'date',
    otherDays: 'otherDays',
    layout: 'layout',
    attendees: 'attendees',
    includeWithTheRoom: 'includeWithTheRoom',
    equipmentHire: 'equipmentHire',
    extraEquipment: 'extraEquipment',
    additionalInformation: 'additionalInformation',
    extraCatering: 'extraCatering',
    firstName: 'firstName',
    lastName: 'lastName',
    phoneNumber: 'phoneNumber',
    email: 'email'
};

export const STEP_NAMES = {
    AVAILABILITY: 'availability',
    EQUIPMENT: 'equipment',
    CATERING: 'catering',
    CONTACT: 'contact'
};

export const STEPS = [
    {
        title: 'Availability',
        subtitle: 'When and who?',
        name: STEP_NAMES.AVAILABILITY
    },
    {
        title: 'Equipment',
        subtitle: 'What’s available and what you need',
        name: STEP_NAMES.EQUIPMENT
    },
    {
        title: 'Catering',
        subtitle: 'Anything to eat, drink?',
        name: STEP_NAMES.CATERING
    },
    {
        title: 'Contact',
        subtitle: 'Your contact and overview',
        name: STEP_NAMES.CONTACT
    }
];

export const AVAILABILITY_FIELDS = [
    {
        name: FIELD_NAMES.date,
        label: 'Select a date*',
        placeholder: '',
        type: 'date',
        validator: (date) => {
            if (date) {
                if (Moment(date, 'DD/MM/YYYY').isBefore(Moment(), 'day')) {
                    return createValidationDataObject(false, 'Select a day from today');
                }

                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.otherDays,
        label: 'I’m open to other days',
        placeholder: '',
        type: 'checkbox',
        validator: () => createValidationDataObject()
    },
    {
        name: FIELD_NAMES.layout,
        label: 'Select layout*',
        placeholder: '',
        type: 'radio',
        validator: () => createValidationDataObject()
    },
    {
        name: FIELD_NAMES.attendees,
        label: 'Number of attendees*',
        placeholder: '',
        type: 'number',
        validator: (attendees, min = 0, max = MAX_CAPACITY) => {
            if (parseInt(attendees) >= parseInt(min)
                && parseInt(attendees) <= parseInt(max)) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, `Enter amount between ${min} - ${max}`);
        }
    }
];

export const EQUIPMENT_INCLUDED = [
    {
        label: 'Room setup',
        type: 'list-item'
    },
    {
        label: 'Duty operations manager',
        type: 'list-item'
    },
    {
        label: 'Pens, pads',
        type: 'list-item'
    },
    {
        label: 'Fresh water',
        type: 'list-item'
    }
];

export const EQUIPMENT_HIRE = [
    {
        name: 'stageOperator',
        label: 'Stage operator',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'avOperator',
        label: 'AV operator',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'whiteboard',
        label: 'Whiteboard',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'tv',
        label: 'TV',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'lectern',
        label: 'Lectern',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    }
];

export const EQUIPMENT_FIELDS = [
    {
        name: FIELD_NAMES.extraEquipment,
        label: 'Extra equipment',
        placeholder: 'Something that’s not in the list or any special equipment you want to use',
        type: 'multi-line',
        validator: () => createValidationDataObject()
    }
];

export const CATERING_OPTIONS = [
    {
        name: 'arrivalCoffee',
        label: 'Arrival coffee',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'morningTea',
        label: 'Morning tea',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'lunch',
        label: 'Lunch',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'afternoonTea',
        label: 'Afternoon tea',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'postEventDrinks',
        label: 'Post event drinks',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    },
    {
        name: 'dinner',
        label: 'Dinner',
        type: 'checkbox-list',
        validator: () => createValidationDataObject()
    }
];

export const CATERING_FIELDS = [
    {
        name: FIELD_NAMES.extraCatering,
        label: 'Additional information',
        placeholder: 'Dietary requirements, specific food, barista coffee. Requests or something different that’s not on the list.',
        type: 'multi-line',
        validator: () => createValidationDataObject()
    }
];

export const CONTACT_FIELDS = [
    {
        name: FIELD_NAMES.firstName,
        label: 'First name*',
        placeholder: '',
        type: 'text',
        validator: (name) => {
            if (name) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.lastName,
        label: 'Last name*',
        placeholder: '',
        type: 'text',
        validator: (name) => {
            if (name) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.phoneNumber,
        label: 'Phone number*',
        placeholder: '',
        type: 'tel',
        validator: (tel) => {
            if (tel) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.email,
        label: 'Email*',
        placeholder: '',
        type: 'email',
        validator: (email) => {
            if (email) {
                // https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
                if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) { // eslint-disable-line
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.additionalInformation,
        label: 'Additional information',
        placeholder: '',
        type: 'multi-line',
        validator: () => createValidationDataObject()
    }
];
