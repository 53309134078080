/* eslint-disable camelcase */

import { dataLayerPush } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const BYTE_BASE = 1024;
const DECIMAL_PLACES = 1;
const KILO_BYTE = 1024;
const MEGA_BYTE = KILO_BYTE * BYTE_BASE;
const GIGA_BYTE = MEGA_BYTE * BYTE_BASE;

class FileDownload extends Component {
    get fileInfo() {
        const bytes = this.props.filesize;
        const extension = this.props.extension.toUpperCase();
        const extensionText = `${extension} file`;

        if ((bytes >= KILO_BYTE) && (bytes < MEGA_BYTE)) {
            return `${(bytes / KILO_BYTE).toFixed(DECIMAL_PLACES)} KB ${extensionText}`;
        } else if ((bytes >= MEGA_BYTE) && (bytes < GIGA_BYTE)) {
            return `${(bytes / MEGA_BYTE).toFixed(DECIMAL_PLACES)} MB ${extensionText}`;
        } else if (bytes > GIGA_BYTE) {
            return `${(bytes / GIGA_BYTE).toFixed(DECIMAL_PLACES)} GB ${extensionText}`;
        }

        return `${bytes} B ${extensionText}`;
    }

    // Helpers
    trackFileDownload() {
        const { filename } = this.props;

        /* ↓ Tracking file download events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'pdf_download',
            text: filename
        });
        /* ↑ Tracking file download events */
    }

    render() {
        const { display_name, file, filename } = this.props;
        const fileInfo = this.fileInfo;

        return (
            <section className="file-content-block || content-section || constrain-width">
                <div className="file-download">
                    <div className="file-download-details">
                        <Icon name="pdf" className="file-download-icon" />
                        <h5 className="file-download-title">{display_name || filename}</h5>
                        <p className="file-download-text">{fileInfo}</p>
                    </div>
                    <a
                        className="icon-button"
                        href={file}
                        download={filename}
                        target="_blank"
                        onClick={this.trackFileDownload.bind(this)}
                    >
                        <span className="icon-button-text">Download</span>
                        <Icon name="download" />
                    </a>
                </div>
            </section>
        );
    }
}

FileDownload.propTypes = {
    display_name: PropTypes.string,
    extension: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    filesize: PropTypes.string.isRequired
};

export default FileDownload;
