import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Download
        </title>
        <path fill="currentColor" fillRule="evenodd" d="M28.797 14.77l-7.518 7.386V4.807h-3.498v17.349l-7.517-7.386-2.449 2.405L19.53 28.684l11.716-11.509zM10.165 35.658h19.233v-3.435H10.165z" />
    </svg>
);

