/**
 * Configuration for the /capacities page.
 */

export const LAYOUT_TYPES = [
    'Theatre',
    'Classroom',
    'U-Shape',
    'Cabaret',
    'Banquet',
    'Cocktail',
    'Boardroom'
];
