import classnames from 'classnames';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';
import { HEADER_CTA, NAVIGATION_HEADER, NAVIGATION_ITEM_TYPE__BUTTON_PRIMARY } from 'config/navigation.js';
import { Link, NavLink } from 'react-router-dom'; // eslint-disable-line

class Navigation extends React.Component {
    constructor(props) {
        super(props);
    }


    // Handlers
    handleToggleChildNavigation(index) {
        const { itemOpen, updateOpenItem } = this.props;

        updateOpenItem(itemOpen === index ? null : index);
    }

    // Render
    renderNavigationItem({ children, label, link }, index) {
        const itemClass = classnames('navigation-item', {
            'is-active': this.props.itemOpen === index
        });

        return (
            <div className={itemClass} key={index}>
                <NavLink
                    className={({ isActive }) => classnames('navigation-link', { 'is-active': isActive })}
                    to={link}
                >
                    {label}
                </NavLink>
                {children &&
                    <button type="button" className="sub-navigation-toggle" onClick={this.handleToggleChildNavigation.bind(this, index)}>
                        <Icon name="chevron-right" title="Open subpages" />
                    </button>}
                {children &&
                    <nav className="sub-navigation">
                        {children.map(({ label, link, type }, subIndex) => {
                            if (type === NAVIGATION_ITEM_TYPE__BUTTON_PRIMARY) {
                                return (
                                    <div key={`${index}-${subIndex}`} className="sub-navigation-button-item-wrapper">
                                        <Link className="button primary" to={link}>
                                            {label}
                                        </Link>
                                    </div>
                                );
                            }

                            // Render Link external links
                            if (link.indexOf('http') > -1) {
                                return (
                                    <a className="sub-navigation-item" href={link} key={`${index}-${subIndex}`}>{label}</a>
                                );
                            }

                            return (
                                <NavLink
                                    className={({ isActive }) => classnames('sub-navigation-item', { 'is-active': isActive })}
                                    to={link}
                                    key={`${index}-${subIndex}`}>
                                    {label}
                                </NavLink>
                            );
                        })}
                    </nav>}
            </div>
        );
    }

    render() {
        const { isActive } = this.props;

        const navigationClass = classnames('navigation-bar', {
            'is-active': isActive
        });

        return (
            <div className={navigationClass}>
                <div className="header-actions">
                    <button type="button" className="action-button" onClick={this.props.toggleActive}>
                        <span className="action-button-label || heading-6">Menu</span>
                        <Icon name="menu" />
                    </button>
                </div>
                <div className="navigation-bar-inner">
                    <div className="header-actions">
                        <button
                            type="button"
                            className="action-button"
                            onClick={this.props.toggleActive}>
                            <span className="action-button-label || heading-6">Close</span>
                            <Icon name="cross" />
                        </button>
                    </div>
                    <nav className="navigation">
                        {NAVIGATION_HEADER.map((item, index) => {
                            return this.renderNavigationItem(item, index);
                        })}
                        <Link
                            className="navigation-item || button alternative inverse"
                            to={HEADER_CTA.link}
                            /* ↓ Tracking attributes */
                            {...HEADER_CTA.trackingAttributes}
                            /* ↑ Tracking attributes */
                        >{HEADER_CTA.label}</Link>
                    </nav>
                </div>
            </div>
        );
    }
}

Navigation.propTypes = {
    isActive: PropTypes.bool.isRequired,
    itemOpen: PropTypes.number, // Not required so we can pass null
    toggleActive: PropTypes.func.isRequired,
    updateOpenItem: PropTypes.func.isRequired,
};

export default Navigation;
