/* eslint-disable camelcase */
import { buildUrl } from 'app/utilities/url';
import env from './env';

export const { API_BASE_URL, CLIENT_ID, CLIENT_SECRET } = env;

export const ENDPOINTS = {
    // we can use same API as Auckland Live for parking availability
    PARKING_AVAILABILITY: `${API_BASE_URL}/live/parking-availability`,
    CAPACITIES: `${API_BASE_URL}/conventions/rooms/capacity-chart`,
    ENQUIRY: `${API_BASE_URL}/conventions/get-in-touch`,
    ENQUIRY_ROOM: `${API_BASE_URL}/conventions/rooms/enquiry`,
    FILTER: (eventType, capacity, layoutType, venueId) => {
        return buildUrl(`${API_BASE_URL}/conventions/rooms/filter`, {
            event_type: eventType,
            layout_type: layoutType,
            external_venue_id: venueId,
            capacity
        });
    },
    NOTIFICATIONS: `${API_BASE_URL}/conventions/notifications`,
    ROOMS_ALL: `${API_BASE_URL}/conventions/rooms?limit=999`,
    ROOMS_FILTER_BY_CONDITION: (paramObj) => {
        return buildUrl(`${API_BASE_URL}/conventions/rooms/filter`, paramObj);
    },
    ROOMS_FILTER_BY_VENUES_ID: (venueId) => `${API_BASE_URL}/conventions/rooms/filter?external_venue_id=${venueId}`,
    PAGES: (slug, parent = '') => `${API_BASE_URL}/conventions/pages?slug=${encodeURIComponent(slug)}&parent=${encodeURIComponent(parent)}`,
    PAGES_ALL: `${API_BASE_URL}/conventions/pages?limit=999`,
    ROOMS: (slug) => `${API_BASE_URL}/conventions/rooms?slug=${encodeURIComponent(slug)}`,
    ROOMS_BY_EXTERNAL_VENUE_ID: (externalVenueId) => `${API_BASE_URL}/conventions/rooms?external_venue_id=${encodeURIComponent(externalVenueId)}`,
    TOKEN: `${API_BASE_URL}/oauth/token`,
    VENUES: (slug = null) => {
        return buildUrl(`${API_BASE_URL}/conventions/venues`, {
            slug: slug
        });
    },
    VENUES_ALL: `${API_BASE_URL}/conventions/venues?limit=999`,
    NEWS: (slug = null) => {
        return buildUrl(`${API_BASE_URL}/conventions/news`, {
            slug: slug
        });
    },
    NEWS_ALL: `${API_BASE_URL}/conventions/news?limit=999`,
    VENUES_BY_EXTERNAL_VENUE_ID: (externalVenueId) => `${API_BASE_URL}/conventions/venues?external_venue_id=${encodeURIComponent(externalVenueId)}`,
    SITEMAP: `${API_BASE_URL}/conventions/sitemap`,
};

export const RESPONSE = {
    OK: 200,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
};

export const TIMEOUT = 0;
