import classnames from 'classnames';
import { formatArrayToOrderedString } from 'app/utilities/format';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const SCROLL_PADDING = 80;

class MultiSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false
        };
    }

    handleClose() {
        this.setState({ isActive: false });
    }

    handleToggleOpen() {
        this.setState({ isActive: !this.state.isActive }, () => {
            const { isActive } = this.state;

            if (isActive) {
                window.scroll({
                    top: this.component.offsetTop - SCROLL_PADDING,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        });
    }

    render() {
        const { isActive } = this.state;
        const { handleToggleValue, isValid, name, options, placeholder, values } = this.props;

        const multiSelectClass = classnames('multi-select', {
            'is-invalid': !isValid,
            'is-active': isActive
        });

        const readableValueLabels = formatArrayToOrderedString(options, values);

        return (
            <div className={multiSelectClass} ref={(ref) => { this.component = ref; }}>
                {/* Toggle Button */}
                <button
                    id={name}
                    type="button"
                    className="multi-select-toggle"
                    onClick={this.handleToggleOpen.bind(this)}
                >
                    {values.length > 0 &&
                        <span className="multi-select-value">{readableValueLabels}</span>
                    ||
                        <span className="multi-select-placeholder">{placeholder}</span>
                    }
                    <Icon className="icon" name="chevron-right" />
                </button>

                {/* Options */}
                <div className="multi-select-options">
                    {options.map((item, index) => {
                        const { label, value, thumbnail } = item;
                        const style = thumbnail ? { backgroundImage: `url(${thumbnail})` } : null;

                        const itemClass = classnames('multi-select-item', {
                            'is-active': values && values.includes(value)
                        });

                        return (
                            <button
                                type="button"
                                className={itemClass}
                                onClick={() => { handleToggleValue(value); }}
                                key={index}>
                                <span className="multi-select-item-inner">
                                    {style && <span className="multi-select-item-image" style={style}></span>}
                                    <span className="multi-select-item-title">{label}</span>
                                    <span className="multi-select-item-checkbox">
                                        <Icon className="icon" name="tick" />
                                    </span>
                                </span>
                            </button>
                        );
                    })}
                </div>

                {/* Close Overlay */}
                {isActive &&
                    <button
                        type="button"
                        className="multi-select-close-overlay"
                        onClick={() => { this.handleClose(); }}
                    >
                        {/* Label for screen readers only */}
                        <span className="multi-select-close-overlay-label">Close Multi Select Dialog</span>
                    </button>
                }

                {/* Hidden Input */}
                <input
                    tabIndex="-1"
                    className="multi-select-hidden-input"
                    aria-hidden={true}
                    name={name}
                    type="text"
                    value={readableValueLabels}
                />
            </div>
        );
    }
}

MultiSelect.propTypes = {
    handleToggleValue: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    values: PropTypes.array
};

export default MultiSelect;
