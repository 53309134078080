import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Capacity
        </title>
        <path fill="currentColor" d="M29.285 15.271c.766 0 1.385.62 1.381 1.384l-.521 5.783-.01.089v.01a.897.897 0 0 1-.885.76v.001h-.344a.595.595 0 0 0-.558.58l-.482 6.004-.013.174a.895.895 0 0 1-.882.736h-2.07a.682.682 0 0 1-.683-.682v-.034l.004-.041.294-3.676.01-.102c-.003 0-.003 0 0 0a.68.68 0 0 1 .45-.542l.036-.011a2.4 2.4 0 0 0 .826-.466 2.38 2.38 0 0 0 .796-1.43c.003-.025.007-.048.009-.073a.383.383 0 0 0 .005-.038l.009-.1.594-6.588a2.876 2.876 0 0 0-.079-.818v-.003a1.086 1.086 0 0 0-.032-.12.682.682 0 0 1 .672-.797h1.473zm-4.396-1.307a.682.682 0 0 1-.402-.969c.013-.02.025-.043.038-.065 0-.002.003-.003.003-.005a4.824 4.824 0 0 0 .403-3.99V8.93a.722.722 0 0 1-.052-.261.681.681 0 0 1 .656-.679 3.062 3.062 0 1 1 .256 6.11 3.03 3.03 0 0 1-.902-.137zm-.61 10.493h-.302l-.043-.001-.044.001a.688.688 0 0 0-.633.596.465.465 0 0 0-.005.066l-.547 6.844-.016.196a1.02 1.02 0 0 1-1.004.841H18.99c-.507 0-.93-.374-1.007-.86l-.013-.154-.548-6.856a.72.72 0 0 0-.061-.275.684.684 0 0 0-.576-.397h-.398c-.497 0-.914-.36-1.003-.832l-.018-.197-.59-6.541c0-.87.706-1.576 1.576-1.576h7.965c.871 0 1.575.705 1.575 1.576l-.594 6.59-.01.101v.012a1.02 1.02 0 0 1-1.009.866zm-3.946-10.481a3.475 3.475 0 0 1-2.8-1.411 3.488 3.488 0 1 1 6.291-2.077 3.485 3.485 0 0 1-3.49 3.488zm-3.891 16.053l.007.056a.683.683 0 0 1-.681.705h-2.07a.897.897 0 0 1-.885-.754L12.8 29.9l-.48-6.015a.6.6 0 0 0-.56-.589l-.039-.001-.04.001h-.269v-.001a.897.897 0 0 1-.881-.729l-.015-.172-.517-5.74c0-.765.619-1.384 1.382-1.384h1.473a.682.682 0 0 1 .673.791c-.011.044-.025.088-.034.133a2.913 2.913 0 0 0-.084.693c0 .04.003.08.007.121l.588 6.541.018.198a2.39 2.39 0 0 0 1.611 1.95h.004c.024.01.047.017.072.023.225.086.394.29.43.534v.002l.01.101v.001l.292 3.67zm-1.565-15.928a3.063 3.063 0 0 1 0-6.124 2.655 2.655 0 0 1 .313.016l.021.004a.681.681 0 0 1 .552.863l-.026.072v.004a4.833 4.833 0 0 0 .403 3.99l.003.006c.013.02.026.044.038.066a.679.679 0 0 1-.395.965l-.007.002a3.067 3.067 0 0 1-.902.135z" />
    </svg>
);

