import { EVENT_TYPES } from 'config/filter';
import { getDataByEventType } from 'app/utilities/filter';
import Icon from 'app/components/partials/icon';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import Tile from 'app/components/partials/tile';
import React, { Component } from 'react';

class Rooms extends Component {
    // Lifecycle
    componentDidMount() {
        if (typeof window === undefined) return;

        /**
         * Pay attention here:
         *
         * If the `rooms` state is filled by SSR, then we don't need to dispatch query, otherwise, we HAVE TO
         * dispatch a new query, or it will present the WRONG rooms (the prev state which not match current
         * search or venues)
         */
        if (window.__INITIAL_STATE__ && window.__INITIAL_STATE__.rooms) {
            // Make sure this condition only be trigger once !!!
            delete window.__INITIAL_STATE__.rooms;

            // But we still need to check IF has no rooms state situation, for example the case below will not
            // have rooms state:
            // SSR "/venues" --> click on any venues --> code run to here --> remove the flag  --> no rooms state !!!
            const { getRooms, eventType } = this.props;
            const rooms = getRooms(eventType);
            if (!rooms || rooms.length <= 0) {
                this.setRooms(this.props);
            }
        } else {
            this.setRooms(this.props);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.venueId !== nextProps.venueId) {
            this.setRooms(nextProps);
        }
    }


    // Handlers
    handleFilterChange(event) {
        this.props.setEventType(event.target.value);

        return this.props.fetchRooms(this.props.venueId, event.target.value);
    }


    // Helpers
    setRooms({ filterEventType, setEventType, eventType, fetchRooms, venueId }) {
        if (filterEventType) {
            setEventType(filterEventType);

            return fetchRooms(venueId, filterEventType);
        }

        fetchRooms(venueId, eventType);
    }


    // Renders
    renderRoom(data, index, eventType) {
        const {
            name,
            short_description: description,
            featured
        } = data;

        const { image, min, max } = getDataByEventType(data, eventType);

        return (
            <Tile
                key={index}
                item={{
                    type: 'room',
                    data
                }}
                title={name}
                description={description}
                capacity={{ min, max }}
                thumbnail={image}
                featured={featured}
            />
        );
    }

    renderRooms(rooms, eventType) {
        if (rooms && rooms.length) {
            return (
                <div className="tile-block-inner">
                    {rooms.map((room, index) => this.renderRoom(room, index, eventType))}
                </div>
            );
        }

        return (
            <div className="rooms-filter-empty">
                <p className="heading-3">
                    Sorry, there are no rooms for this event type.
                </p>
            </div>
        );
    }

    renderLoader() {
        return (
            <Loader type="block primary || rooms-filter-empty" />
        );
    }

    render() {
        const { getRooms, eventType, isBusy } = this.props;
        const rooms = getRooms(eventType);

        return (
            <section className="tile-block || block-section">
                <header className="section-header || rooms-filter || constrain-width">
                    <h2 className="heading-1">Rooms</h2>
                    <div className="rooms-filter-fields">
                        <label className="rooms-filter-label" htmlFor="event-type">filter by</label>
                        <div className="select-wrapper">
                            <select
                                name="event_type"
                                id="event-type"
                                aria-label="Event type"
                                value={eventType}
                                onChange={this.handleFilterChange.bind(this)}
                            >
                                {EVENT_TYPES.map((event, index) => (
                                    <option key={index} value={event.value}>{event.label}</option>
                                ))}
                            </select>
                            <label htmlFor="event-type" className="chevron">
                                <Icon name="chevron-right" />
                            </label>
                        </div>
                    </div>
                </header>
                <div className="constrain-width">
                    {isBusy ? this.renderLoader() : this.renderRooms(rooms, eventType)}
                </div>
            </section>
        );
    }
}

Rooms.propTypes = {
    eventType: PropTypes.string,
    fetchRooms: PropTypes.func.isRequired,
    filterEventType: PropTypes.string,
    getRooms: PropTypes.func.isRequired,
    isBusy: PropTypes.bool.isRequired,
    setEventType: PropTypes.func.isRequired,
    venueId: PropTypes.number.isRequired
};

export default Rooms;
