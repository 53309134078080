import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import SearchForm from 'app/components/partials/search-form.container';

class Search extends React.Component {
    render() {
        const { isActive, toggleActive } = this.props;

        const searchClass = classnames('search', {
            'is-active': isActive
        });

        return (
            <div className={searchClass}>
                <button type="button" className="search-toggle || button primary block" onClick={toggleActive}>
                    Find a venue
                    <Icon name="arrow-right" />
                </button>
                <div className="search-inner">
                    <div className="header-actions">
                        <button
                            type="button"
                            className="action-button"
                            onClick={this.props.toggleActive}>
                            <span className="action-button-label || heading-6">Close</span>
                            <Icon name="cross" />
                        </button>
                    </div>
                    <div className="constrain-width large">
                        <SearchForm finishInitiateFilter={() => {
                            if (isActive) toggleActive();
                        }}/>
                    </div>
                </div>
            </div>
        );
    }
}

Search.propTypes = {
    isActive: PropTypes.bool.isRequired,
    toggleActive: PropTypes.func.isRequired
};

export default Search;
