import PropTypes from 'prop-types';
import React from 'react';

class PagebreakQuote extends React.Component {
    render() {
        const { cite, file, quote } = this.props;

        return (
            <section className={`pagebreak-quote-content-block ${file ? '' : 'no-image'}`}>
                <div className="block-section dark">
                    <div className="pagebreak-quote-content-block-inner || constrain-width">
                        <div className="pagebreak-quote-content-block-section">
                            <p className="heading-2 secondary">&quot;{quote}&quot;</p>
                            <p className="cite">{cite}</p>
                        </div>
                        {file &&
                            <div className="pagebreak-quote-content-block-section">
                                <div className="preserve-aspect-ratio portrait">
                                    {/* Items must be at the same level for z-index purposes */}
                                    <div className="tile-frame"></div>
                                    <div className="preserve-aspect-ratio-media || pagebreak-quote-content-block-media" style={{ backgroundImage: `url(${file})` }}></div>
                                    <img className="fixed-image" src={file} alt={cite}/>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

PagebreakQuote.propTypes = {
    cite: PropTypes.string.isRequired,
    file: PropTypes.string,
    quote: PropTypes.string.isRequired,
};

export default PagebreakQuote;
