/* eslint-disable require-jsdoc */
export function selectFieldTouched(state, fieldName) {
    return state.enquiryForm.fields[fieldName].touched;
}

export function selectFieldValue(state, fieldName) {
    return state.enquiryForm.fields[fieldName].value;
}

export function selectSubmitted(state) {
    return state.enquiryForm.submitted;
}

export function selectIsBusy(state) {
    const { enquiryForm } = state;

    return enquiryForm.submitted && enquiryForm.submittedSuccessfully === undefined;
}

export function selectSubmittedSuccessfully(state) {
    return state.enquiryForm.submittedSuccessfully;
}

export function selectVenueOptions(state) {
    return state.enquiryForm.venueOptions;
}
