import CapacitiesSection from 'app/components/partials/capacities-section.container';
import ContentBlocks from 'app/components/content-blocks/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Capacities extends Component {
    render() {
        const {
            blocks,
            name: title
        } = this.props.data;

        return (
            <div className="content-page">
                <header className="section-header center pad">
                    <div className="section-header-inner">
                        <h1 className="section-header-title || heading-2">{title}</h1>
                    </div>
                </header>
                <CapacitiesSection />
                <ContentBlocks data={blocks} />
            </div>
        );
    }
}

Capacities.propTypes = {
    data: PropTypes.object.isRequired
};

export default Capacities;
