import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    data: undefined
};


// Actions
export const INITIATE_CAPACITIES_FETCH = 'rfa-conventions-website/capacities/INITIATE_CAPACITIES_FETCH';
export const CAPACITIES_FETCH_SUCCESS = 'rfa-conventions-website/capacities/CAPACITIES_FETCH_SUCCESS';


// Action Creators
export const initiateCapacitiesFetchAction = () => ({
    type: INITIATE_CAPACITIES_FETCH
});

export const capacitiesFetchSuccessAction = (capacities) => ({
    type: CAPACITIES_FETCH_SUCCESS,
    payload: capacities
});


// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CAPACITIES_FETCH_SUCCESS:
            return capacitiesFetchSuccess(state, action.payload);
        default:
            return state;
    }
};

function capacitiesFetchSuccess(state, capacities) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        data: capacities
    };
}


// Epic creator

/**
 * @callback formatCapacities
 * @param {object} response
 * @returns {array} capacitiesData - capacities array
 */

/**
 * @callback capacitiesFetchSuccessAction
 * @param {array} capacitiesData - capacities array
 * @returns {object} action
 */

/**
 * Creates capacities epic
 * @param {string} capacitiesEndpoint - capacities endpoint
 * @param {formatCapacities} formatCapacities - response formatter function
 * @param {capacitiesFetchSuccessAction} capacitiesFetchSuccessAction - action creator
 */
export const createCapacitiesEpic = (capacitiesEndpoint, formatCapacities, capacitiesFetchSuccessAction) => {
    return (action$) => action$.pipe(
        ofType(INITIATE_CAPACITIES_FETCH),
        mergeMap(() => {
            return (
                fetch(capacitiesEndpoint)
                    .then((response) => {
                        return capacitiesFetchSuccessAction(formatCapacities(response));
                    })
                    .catch(() => {
                    // TODO: Add proper error handling
                        return capacitiesFetchSuccessAction([]);
                    })
            );
        })
    );
};


// Epics
const capacitiesEpic = createCapacitiesEpic(
    ENDPOINTS.CAPACITIES,
    formatCapacities,
    capacitiesFetchSuccessAction
);


// Helpers

/**
 * Reformats the API response
 * @param {object} response
 * @returns {array} capacitiesData - capacities array
 */
function formatCapacities(response) {
    if (response && response.data && response.data.length) {
        return response.data;
    }

    return [];
}

export const epics = combineEpics(capacitiesEpic);
