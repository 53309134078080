import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Email Footer
        </title>
        <path fill="currentColor" d="m18.0876494 4.05970149v-1.99004975l-8.0478088 4.93532338-8.04780873-4.93532338v1.99004975l8.04780873 4.93532339zm0-4.05970149c.5577689 0 .9561753.15920398 1.3545817.63681592.3187251.47761194.5577689.87562189.5577689 1.43283582v11.94029846c0 .557214-.1593625.9552239-.6374502 1.3532339-.3984064.3980099-.7968127.6368159-1.3545817.6368159h-16.01593623c-.55776892 0-.9561753-.238806-1.43426295-.6368159-.39840637-.39801-.55776892-.8756219-.55776892-1.3532339v-11.94029846c0-.55721393.15936255-.95522388.6374502-1.43283582s.87649402-.55721393 1.43426295-.55721393z" transform="translate(10 12)"/>
    </svg>
);

