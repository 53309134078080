import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            Cross
        </title>
        <path fill="currentColor" d="M27.875 7.204l4.18 4.179-8.276 8.275 8.234 8.234-4.179 4.18-8.234-8.235-8.275 8.275-4.18-4.179 8.276-8.275-8.234-8.234 4.179-4.18L19.6 15.48z" />
    </svg>
);
