import { dataLayerPush } from 'app/utilities/gtm';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import {
    AVAILABILITY_FIELDS,
    CATERING_FIELDS,
    CATERING_OPTIONS,
    CONTACT_FIELDS,
    EQUIPMENT_FIELDS,
    EQUIPMENT_HIRE,
} from 'config/enquiry-modal-form-fields';
import { combineEpics, ofType } from 'redux-observable';

const fieldsReducerHelper = (acc, { name, type }) => {
    const isCheckbox = type === 'checkbox' || type === 'checkbox-list';

    acc[name] = {
        value: isCheckbox ? false : '', // Checkbox should be default false
        touched: false
    };

    return acc;
};

export const INITIAL_STATE = {
    availability: AVAILABILITY_FIELDS.reduce(fieldsReducerHelper, {}),
    equipment: {
        ...EQUIPMENT_HIRE.reduce(fieldsReducerHelper, {}),
        ...EQUIPMENT_FIELDS.reduce(fieldsReducerHelper, {}),
    },
    catering: {
        ...CATERING_OPTIONS.reduce(fieldsReducerHelper, {}),
        ...CATERING_FIELDS.reduce(fieldsReducerHelper, {}),
    },
    contact: CONTACT_FIELDS.reduce(fieldsReducerHelper, {}),
    submitted: false,
    submittedSuccessfully: undefined
};


/*
    Actions
 */
export const SET_ENQUIRY_MODAL_FORM_FIELD_VALUE = 'rfa-conventions-website/enquiry-modal/SET_ENQUIRY_MODAL_FORM_FIELD_VALUE';
export const TOUCHED_ENQUIRY_MODAL_FORM_FIELD = 'rfa-conventions-website/enquiry-modal/TOUCHED_ENQUIRY_MODAL_FORM_FIELD';
export const SUBMIT_ENQUIRY_MODAL_FORM = 'rfa-conventions-website/enquiry-modal/SUBMIT_ENQUIRY_MODAL_FORM';
export const ENQUIRY_MODAL_FORM_SUCCESSFULLY_SUBMITTED = 'rfa-conventions-website/enquiry-modal/ENQUIRY_MODAL_FORM_SUCCESSFULLY_SUBMITTED';
export const FAILED_TO_SUBMIT_ENQUIRY_MODAL_FORM = 'rfa-conventions-website/enquiry-modal/FAILED_TO_SUBMIT_ENQUIRY_MODAL_FORM';
export const RESET_ENQUIRY_MODAL_FORM = 'rfa-conventions-website/enquiry-modal/RESET_ENQUIRY_MODAL_FORM';


/*
    Action Creators
 */
export const setEnquiryFormFieldValueAction = (fieldName, value, step) => ({
    type: SET_ENQUIRY_MODAL_FORM_FIELD_VALUE,
    fieldName,
    value,
    step
});

export const touchedEnquiryFormFieldAction = (fieldName, step) => ({
    type: TOUCHED_ENQUIRY_MODAL_FORM_FIELD,
    fieldName,
    step
});

export const submitEnquiryFormAction = (formData, captchaToken) => ({
    type: SUBMIT_ENQUIRY_MODAL_FORM,
    formData,
    captchaToken
});

export const enquiryFormSuccessfullySubmittedAction = () => ({
    type: ENQUIRY_MODAL_FORM_SUCCESSFULLY_SUBMITTED
});

export const failedToSubmitEnquiryFormAction = () => ({
    type: FAILED_TO_SUBMIT_ENQUIRY_MODAL_FORM
});

export const resetEnquiryFormAction = () => ({
    type: RESET_ENQUIRY_MODAL_FORM
});


/*
    Reducers
 */
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ENQUIRY_MODAL_FORM_FIELD_VALUE:
            return setEnquiryFormFieldValue(state, action.fieldName, action.value, action.step);
        case TOUCHED_ENQUIRY_MODAL_FORM_FIELD:
            return touchedEnquiryFormField(state, action.fieldName, action.step);
        case SUBMIT_ENQUIRY_MODAL_FORM:
            return submitEnquiryForm(state);
        case ENQUIRY_MODAL_FORM_SUCCESSFULLY_SUBMITTED:
            return enquiryFormSuccessfullySubmitted(state);
        case FAILED_TO_SUBMIT_ENQUIRY_MODAL_FORM:
            return failedToSubmitEnquiryForm(state);
        case RESET_ENQUIRY_MODAL_FORM:
            return resetEnquiryForm(state);
        default:
            return state;
    }
};


/*
    Reducer Helpers
 */
function setEnquiryFormFieldValue(state, fieldName, value, step) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        [step]: {
            ...state[step],
            [fieldName]: {
                ...state[step][fieldName],
                value
            }
        }
    };
}

function touchedEnquiryFormField(state, fieldName, step) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        [step]: {
            ...state[step],
            [fieldName]: {
                ...state[step][fieldName],
                touched: true
            }
        }
    };
}

function submitEnquiryForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: undefined
    };
}

function enquiryFormSuccessfullySubmitted(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: true,
        fields: INITIAL_STATE.fields
    };
}

function failedToSubmitEnquiryForm(state) { // eslint-disable-line require-jsdoc
    return {
        ...state,
        submitted: true,
        submittedSuccessfully: false
    };
}

function resetEnquiryForm() { // eslint-disable-line require-jsdoc
    return {
        ...INITIAL_STATE
    };
}


/*
    Epic creators helpers
 */
function trackEnquiryFormSubmission(formData) { // eslint-disable-line require-jsdoc
    /* ↓ Tracking successful enquiry meeting room form submissions */
    /* eslint-disable camelcase */
    dataLayerPush({
        event: 'formSubmitted', // Overrides the default 'conversionTracking' value.
        form_conversion: 'macro_conversion',
        form_type: 'lead',
        form_action: 'enquiry_meeting_room_form_submission',
        form_data: JSON.stringify(formData)

    });
    /* eslint-enable camelcase */
    /* ↑ Tracking successful form submissions */
}


/*
    Epic creators
 */

/**
 * @callback enquiryFormSuccessfullySubmittedAction
 * @returns {object} action
 */

/**
 * @callback failedToSubmitEnquiryFormAction
 * @returns {object} action
 */

/**
 * Creates enquiry meeting room form submission epic
 * @param {string} enquiryEndpoint - conventions/rooms/enquiry endpoint
 * @param {enquiryFormSuccessfullySubmittedAction} enquiryFormSuccessfullySubmittedAction - action creator
 * @param {failedToSubmitEnquiryFormAction} failedToSubmitEnquiryFormAction - action creator
 * @returns {observable}
 */
export const createEnquiryFormSubmissionEpic = (enquiryEndpoint, enquiryFormSuccessfullySubmittedAction, failedToSubmitEnquiryFormAction) => {
    return (action$) => action$.pipe(
        ofType(SUBMIT_ENQUIRY_MODAL_FORM),
        mergeMap(({ formData, captchaToken }) => {
            return fetch(enquiryEndpoint, null, {
                method: 'POST',
                body: JSON.stringify({
                    ...formData,
                    captchaToken,
                })
            })
                .then(() => {
                    trackEnquiryFormSubmission(formData);

                    return enquiryFormSuccessfullySubmittedAction();
                })
                .catch(() => {
                    return failedToSubmitEnquiryFormAction();
                });
        })
    );
};


/*
    Epics
 */
const enquiryFormSubmissionEpic = createEnquiryFormSubmissionEpic(
    ENDPOINTS.ENQUIRY_ROOM,
    enquiryFormSuccessfullySubmittedAction,
    failedToSubmitEnquiryFormAction
);


export const epics = combineEpics(
    enquiryFormSubmissionEpic
);
