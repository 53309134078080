import ACTrackingCode from 'app/utilities/ac-tracking';
import canUseDom from './dom';

/**
 * The function pushes data to the GTM data layer.
 * The dataLayer object is defined in the global scope by the GTM script.
 * @param {Object} data - Data layer record.
 * @return {any}
 */
export const dataLayerPush = (data) => {
    try {
        dataLayer.push({ // eslint-disable-line no-undef
            event: 'conversionTracking',
            ...data
        });
    } catch (e) {} // eslint-disable-line no-empty
};

/**
 * This fires the GTM event 'delayedHistoryChange' and ActiveCampaign tracking.
 * This event is used to update the data layer with the most recent page data.
 * @return {any}
 */
export const fireDelayedHistoryChange = () =>
    setTimeout(() => {
        dataLayerPush({
            event: 'delayedHistoryChange'
        });

        // Only fire on client side
        if (canUseDom()) ACTrackingCode();
    }, 100); // eslint-disable-line no-magic-numbers
