import ContentBlocks from 'app/components/content-blocks/index';
import OtherRoomsInVenue from 'app/components/partials/other-rooms-in-venue';
import OtherVenues from 'app/components/partials/other-venues';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import RelatedRooms from 'app/components/partials/related-rooms.container';
import RoomCapacititiesBlock from 'app/components/partials/room-capacities-block';
import { transformRooms } from 'app/utilities/capacities';
import React, { Component } from 'react';

class Room extends Component {
    render() {
        const {
            blocks,
            hero_cta_item_id: heroCtaUrl,
            hero_cta_title: heroCtaTitle,
            hero_type: heroType,
            hero_video: heroVideo,
            name: title,
            main_images: images,
            short_description: description,
            description: content,
            description_aside: aside
        } = this.props.data;

        const roomsCapacities = transformRooms([this.props.data], true)[0];

        return (
            <div className="content-page">
                <PageHeader
                    title={title}
                    introduction={description}
                    images={images}
                    heroCtaUrl={heroCtaUrl}
                    heroCtaTitle={heroCtaTitle}
                    heroType={heroType}
                    heroVideo={heroVideo}
                />

                <RoomCapacititiesBlock {...roomsCapacities} content={content} aside={aside} />
                <ContentBlocks data={blocks} />
                <div>
                    <OtherRoomsInVenue selectedRoom={this.props.data} />
                    <OtherVenues selectedRoom={this.props.data} />
                </div>
            </div>
        );
    }
}

Room.propTypes = {
    data: PropTypes.object.isRequired
};

export default Room;
