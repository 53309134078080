import ContentBlocks from 'app/components/content-blocks/index';
import Location from 'app/components/partials/location';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import Rooms from 'app/components/partials/rooms.container';
import VenueCapacitiesBlock from 'app/components/partials/venue-capacities-block';
import React, { Component } from 'react';

class Venue extends Component {
    render() {
        const {
            address,
            blocks,
            capacity,
            external_venue_id: venueId,
            hero_cta_item_id: heroCtaUrl,
            hero_cta_title: heroCtaTitle,
            hero_type: heroType,
            hero_video: heroVideo,
            latitude,
            longitude,
            location_column_1: content1,
            location_column_2: content2,
            location_column_3: content3,
            name: title,
            main_images: images,
            place_id: placeId,
            short_description: description
        } = this.props.data;

        // Put Parking availability content block last of the page
        const parkingAvailabilityBlock = blocks.filter((block) => block.type === 'parkingavailability');

        const blocksNoParkingAvailability = blocks.filter((block) => block.type !== 'parkingavailability');

        return (
            <div className="content-page">
                <PageHeader
                    title={title}
                    introduction={description}
                    images={images}
                    heroCtaUrl={heroCtaUrl}
                    heroCtaTitle={heroCtaTitle}
                    heroType={heroType}
                    heroVideo={heroVideo}
                />
                <ContentBlocks data={blocksNoParkingAvailability} />
                <Rooms venueId={venueId} />
                <VenueCapacitiesBlock name={title} rooms={capacity} />
                <Location
                    lat={latitude}
                    long={longitude}
                    place={address}
                    placeId={placeId}
                    content={[content1, content2, content3]}
                />
                {parkingAvailabilityBlock && parkingAvailabilityBlock.length !== 0 && <ContentBlocks data={parkingAvailabilityBlock} />}
            </div>
        );
    }
}

Venue.propTypes = {
    data: PropTypes.object.isRequired
};

export default Venue;
