import classnames from 'classnames';
import PageHeaderCarousel from './page-header-carousel';
import PageHeaderVideo from './page-header-video';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const DESKTOP_SCREEN_WIDTH = 1024; // Used to avoid mobile and iPad/tablet landscape

class PageHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDektopView: false
        };

        this.heroCtaClickHandler = this.heroCtaClickHandler.bind(this);
    }

    componentDidMount() {
        this.setState({ isDektopView: window.innerWidth > DESKTOP_SCREEN_WIDTH });
    }

    renderMedia() {
        const { homepage, images, heroType, heroVideo } = this.props;
        const { isDektopView } = this.state;

        if (heroType === 'video' && isDektopView) {
            return <PageHeaderVideo video={heroVideo} />;
        }

        return <PageHeaderCarousel homepage={homepage} images={images} />;
    }

    heroCtaClickHandler(event) {
        const { heroCtaUrl } = this.props;
        if (heroCtaUrl.startsWith('#')) {
            event.preventDefault();
            const targetId = heroCtaUrl.slice(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
                window.location.hash = targetId;
            }
        }
    }

    render() {
        const { author, homepage, introduction, title, heroCtaUrl, heroCtaTitle } = this.props;

        return (
            <header role="heading" className={classnames('page-header', { homepage })} ref={this.props.innerRef}>
                <div className="page-header-media">
                    {this.renderMedia()}
                </div>
                <div className="page-header-inner || constrain-width no-pad">
                    <div className="page-header-card">
                        <div className="page-header-card-background-shadow" aria-hidden="true" />
                        <div className="page-header-card-background-box" aria-hidden="true" />
                        <div className="page-header-card-content">
                            <h1 className="page-header-title">{title}</h1>
                            <div className="page-header-extra">
                                <div className="page-header-intro-block">
                                    {author && <p className="page-header-author">{author}</p>}
                                    {introduction && <p className="page-header-intro">{introduction}</p>}
                                </div>
                                {heroCtaTitle && heroCtaUrl && (
                                    <div className="page-header-button">
                                        <a className="button primary" href={heroCtaUrl} onClick={(event) => this.heroCtaClickHandler(event)}>{heroCtaTitle}</a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

PageHeader.propTypes = {
    author: PropTypes.string,
    homepage: PropTypes.bool,
    images: PropTypes.array.isRequired,
    introduction: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    heroCtaUrl: PropTypes.string,
    heroCtaTitle: PropTypes.string.isRequired,
    heroType: PropTypes.string.isRequired,
    heroVideo: PropTypes.string.isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ])
};


const PageHeaderForwardingRef = forwardRef((props, ref) => <PageHeader innerRef={ref} {...props} />);

PageHeaderForwardingRef.displayName = 'PageHeader';

export default PageHeaderForwardingRef;
