import Button from './button';
import CallToAction from './call-to-action';
import Carousel from './carousel';
import ContentSet from './content-set';
import Data from 'app/components/partials/data';
import Faq from './faq';
import FileDownload from './file-download';
import Gallery from './gallery';
import Jotform from './jotform';
import PagebreakQuote from './pagebreak-quote';
import ParkingAvailability from './parking-availability';
import Pricing from './pricing';
import PropTypes from 'prop-types';
import React from 'react';
import StacklaWidget from './stackla-widget';
import TextContent from './text-content';
import Video from './video';

class ContentBlocks extends React.Component {
    constructor(props) {
        super(props);
    }

    renderBlock(block, index) {
        switch (block.type) {
            case 'button':
                return <Button key={index} {...block.data} />;
            case 'calltoaction':
                return <CallToAction key={index} {...block} />;
            case 'carousel':
                return <Carousel key={index} items={block.data} />;
            case 'content':
            case 'contentaside':
            case 'contentquote':
                return <TextContent key={index} {...block} />;
            case 'contentset':
                return <ContentSet key={index} {...block.data} />;
            case 'faq':
                return <Faq key={index} items={block.data} />;
            case 'file':
                return <FileDownload key={index} { ...block.data } />;
            case 'gallery':
                return <Gallery key={index} items={block.data} />;
            case 'jotform':
                return <Jotform key={index} {...block.data} />;
            case 'stackla':
                return <StacklaWidget key={index} {...block.data} />;
            case 'ticketpricing':
                return <Pricing key={index} data={block.data} />;
            case 'video':
                return <Video key={index} {...block} />;
            case 'pagebreakquote':
                return <PagebreakQuote key={index} {...block.data} />;
            case 'parkingavailability':
                return <ParkingAvailability key={index} {...block.data} />;
            default:
                return (
                    <Data key={index} title={`Missing content block type: ${block.type}`} />
                );
        }
    }

    render() {
        const { data } = this.props;

        if (!data.length) {
            return null;
        }

        return (
            <div className="content-blocks">
                {data.map((data, index) => {
                    return this.renderBlock(data, index);
                })}
            </div>
        );
    }
}

ContentBlocks.propTypes = {
    data: PropTypes.array.isRequired,
    isLarge: PropTypes.bool,
    isNews: PropTypes.bool
};

ContentBlocks.defaultProps = {
    isLarge: false,
    isNews: false
};

export default ContentBlocks;
