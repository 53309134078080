/* eslint-disable camelcase, quotes */
export default {
    VIDEO_PROPS: {
        data: {
            title: 'Video Section Title',
            credit: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
            video_url: 'https://youtu.be/E8nfCBqS2V4'
        }
    },
    TEXT_PROPS: {
        data: {
            content: '<h1>Text Only</h1><p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>'
        }
    },
    TEXT_ASIDE_PROPS: {
        data: {
            content: '<h1>Text with Aside</h1><p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
            aside: '<h2>Aside content</h2><ul><li>Example list item one.</li><li>Example list item two.</li><li>Example list item three.</li><li>Example list item four.</li></ul>'
        }
    },
    TEXT_QUOTE_PROPS: {
        data: {
            content: '<h1>Text with Quote</h1><p>Lorem ipsum dolor sit amet, <strong>consectetur adipiscing elit</strong>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
            quote: 'Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse stie consequat',
            citation: 'Example citation text'
        }
    },
    CAROUSEL_PROPS: {
        items: [
            {
                'file': 'https://unsplash.it/1200/901',
                'title': 'Auckland Viaduct Events Center',
                'subtitle': 'Something about it being awesome',
                'link': '#'
            },
            {
                'file': 'https://unsplash.it/1200/902',
                'title': 'Aotea Center',
                'subtitle': 'It\'s in the center of Auckland',
                'link': '#'
            }
        ]
    },
    GALLERY_PROPS: {
        items: [
            {
                file: 'https://unsplash.it/800/600?id=10',
                creditline: '<p><strong>Example Slide 1</strong></p><p>Duis aute irure dolor in reprehenderit</p>\r\n'
            },
            {
                file: 'https://unsplash.it/800/600?id=11',
                creditline: '<p><strong>Example Slide 2</strong></p><p>Duis aute irure dolor in reprehenderit</p>\r\n'
            },
            {
                file: 'https://unsplash.it/800/600?id=12',
                creditline: '<p><strong>Example Slide 3</strong></p><p>Duis aute irure dolor in reprehenderit</p>\r\n'
            },
            {
                file: 'https://unsplash.it/800/600?id=13',
                creditline: ''
            }
        ]
    },
    CONTENT_SET_PROPS: {
        items: [
            {
                id: '...',
                name: '...',
                type: 'page',
                data: {
                    name: 'About Aotea Centre',
                    slug: 'about-aotea-centre',
                    thumbnail: 'https://assets.aucklandlive.co.nz/assets/media/aoteacentre-hero.jpg',
                    seo_keywords: '...',
                    is_published: true,
                    seo_description: '...',
                    short_description: 'A page.',
                    id: 1,
                    disabled: false,
                    main_images: [],
                    featured: true
                }
            },
            {
                id: '...',
                name: '...',
                type: 'venue',
                data: {
                    name: 'Viaduct Events Centre',
                    slug: 'viaduct-events-centre',
                    thumbnail: 'http://dbijapkm3o6fj.cloudfront.net/resources/11331,1004,1,6,4,0,600,450/-3830-/20160530114934/anz-viaduct-events-centre.jpeg',
                    seo_keywords: '...',
                    is_published: true,
                    seo_description: '...',
                    short_description: 'Some description text for the venue.',
                    id: 1,
                    disabled: false,
                    main_images: [],
                    featured: true
                }
            },
            {
                id: '...',
                name: '...',
                type: 'venue',
                data: {
                    name: 'Auckland Art Gallery',
                    slug: 'auckland-art-gallery',
                    thumbnail: 'https://nz5.architecturemedia.net/site_media/media/cache/f4/f5/f4f5d4b9f4ef04f45f61f25951a9d893.jpg',
                    seo_keywords: '...',
                    is_published: true,
                    seo_description: '...',
                    short_description: 'Some description text for the venue. More text. More description.',
                    id: 1,
                    disabled: false,
                    main_images: [],
                    featured: true
                }
            }
        ],
        title: "Featured rooms"
    },
    FAQ_PROPS: {
        items: [
            {
                content: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi …',
                heading: 'Stadiums FAQs',
                title: 'How can I find a stadium?'
            },
            {
                content: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi …',
                heading: 'Stadiums FAQs',
                title: 'How can I find a room in a stadium?'
            }
        ]
    },
    HERO_CAROUSEL_PROPS: {
        title: 'Page title',
        introduction: '(Short description) Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.',
        images: [
            'https://unsplash.it/1600/901',
            'https://unsplash.it/1600/902',
            'https://unsplash.it/1600/903',
        ],
        heroType: 'carousel',
        heroCtaTitle: 'Hero CTA title',
        heroCtaUrl: 'https://google.com'
    },
    PAGE_BREAK_QUOTE_PROPS: {
        file: 'https://cms.aucklandunlimited.com/assets/media/galap-touching-rectangle.jpg',
        theme: 'blue',
        quote: 'Quote text',
        cite: 'Cite text'
    },
    CALL_TO_ACTION_PROPS: {
        data: {
            content: '<h6>WEDDING</h6><h1>We craft magical moments</h1><p>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</p><p><a class="small primary button" href="#" target="_blank">ENQUIRE NOW</a></p>',
            file: 'https://unsplash.it/800/600'
        }
    },
    LOCATION_PROPS: {
        lat: -36.8689143,
        long: 174.7609242,
        place: "Aotea Centre",
        placeId: "ChIJcVHWhu9HDW0RsK-bJGLvAA8",
        content: [
            '<h2>Section 1</h2><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.</p>',
            '<h2>Section 2</h2><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.</p>',
            '<h2>Section 3</h2><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.</p>',
        ]
    },
    ROOM_TILES: [
        {
            capacity: {
                max: 250,
                min: 100
            },
            children: null,
            description: 'Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
            featured: false,
            link: '/',
            thumbnail: 'https://unsplash.it/600/400',
            title: 'Example Room'
        },
        {
            capacity: {
                max: 250,
                min: 100
            },
            children: null,
            description: 'Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
            featured: false,
            link: '/',
            thumbnail: 'https://unsplash.it/600/401',
            title: 'Example Room'
        },
        {
            capacity: {
                max: 250,
                min: 100
            },
            children: null,
            description: 'Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
            featured: true,
            link: '/',
            thumbnail: 'https://unsplash.it/600/402',
            title: 'Example Room: Featured'
        },
        {
            capacity: {
                max: 250,
                min: 100
            },
            children: null,
            description: 'Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
            featured: false,
            link: '/',
            thumbnail: 'https://unsplash.it/600/403',
            title: 'Example Room: Long Title'
        },
        {
            capacity: {
                max: 250,
                min: 100
            },
            children: null,
            description: '',
            featured: false,
            link: '/',
            thumbnail: 'https://unsplash.it/600/404',
            title: 'Example Room: No Description'
        },
        {
            capacity: {
                max: 1000,
                min: 100
            },
            children: null,
            description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
            featured: false,
            link: '/',
            thumbnail: 'https://unsplash.it/600/405',
            title: 'Example Room: Long Description'
        }
    ],
    ENQUIRY_DROPDOWN_OPTIONS: [
        {
            label: 'Option One',
            value: 'option-one',
            thumbnail: 'https://unsplash.it/200/100',
        },
        {
            label: 'Option Two: Long title which expands two lines',
            value: 'option-two',
            thumbnail: 'https://unsplash.it/200/101',
            selected: false
        },
        {
            label: 'Option Three: No image',
            value: 'option-three',
        },
        {
            label: 'Option Four',
            value: 'option-four',
            thumbnail: 'https://unsplash.it/200/103',
        },
        {
            label: 'Option Five',
            value: 'option-five',
            thumbnail: 'https://unsplash.it/200/104',
        }
    ],
    STACKLA_WIDGET: {
        hash: '5abc38a6dfeba',
        id: '39460',
        subtitle: 'Tag your photos and posts with #AucklandConventions to display your photos here.',
        title: '#AucklandConventions',
        type: 'grid'
    }
};
