import Button from 'app/components/content-blocks/button';
import CallToAction from 'app/components/content-blocks/call-to-action';
import Carousel from 'app/components/content-blocks/carousel';
import ContentSet from 'app/components/content-blocks/content-set';
import Faq from 'app/components/content-blocks/faq';
import FileDownload from 'app/components/content-blocks/file-download';
import Gallery from 'app/components/content-blocks/gallery';
import Helmet from 'react-helmet';
import Icon from 'app/components/partials/icon';
import Location from 'app/components/partials/location';
import MOCK_DATA from 'config/mock-data';
import MultiSelect from 'app/components/partials/multi-select';
import PagebreakQuote from 'app/components/content-blocks/pagebreak-quote';
import PageHeader from 'app/components/partials/page-header';
import StacklaWidget from 'app/components/content-blocks/stackla-widget';
import Text from 'app/components/content-blocks/text-content';
import Tile from 'app/components/partials/tile';
import Video from 'app/components/content-blocks/video';
import React, { Component } from 'react';


class StyleGuidePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            multiSelectValues: []
        };
    }

    handleToggleValue(value) {
        const { multiSelectValues } = this.state;

        // If value in array then remove/add
        const inArrayPosition = multiSelectValues.indexOf(value);

        if (inArrayPosition > -1) {
            // Remove
            multiSelectValues.splice(inArrayPosition, 1);
        } else {
            // Add
            multiSelectValues.push(value);
        }

        this.setState({ multiSelectValues });
    }

    render() {
        return (
            <main className="page" role="main">
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <section className="style-guide-section">
                    <PageHeader
                        title="Style Guide"
                        introduction="A working site style guide built to manage design and code quality control."
                        images={[
                            'https://unsplash.it/1600/901',
                            'https://unsplash.it/1600/902',
                            'https://unsplash.it/1600/903',
                        ]}
                    />
                </section>
                <section className="style-guide-section || constrain-width">
                    <header className="style-guide-section-header">
                        <h2>Colour</h2>
                        <hr />
                    </header>

                    {/* Colours */}
                    <div className="style-guide-swatches" role="group">
                        <div className="swatch colour-primary">
                            <div className="swatch-inner">
                                <p className="swatch-title">Primary</p>
                                <div role="group" className="swatch-variations">
                                    <span className="swatch-variation tint-1"></span>
                                    <span className="swatch-variation shade-1"></span>
                                </div>
                            </div>
                        </div>
                        <div className="swatch colour-secondary">
                            <div className="swatch-inner">
                                <p className="swatch-title">Secondary</p>
                            </div>
                        </div>
                        <div className="swatch colour-small colour-dark">
                            <div className="swatch-inner">
                                <p className="swatch-title">Dark</p>
                                <div role="group" className="swatch-variations">
                                    <span className="swatch-variation tint-3"></span>
                                    <span className="swatch-variation tint-2"></span>
                                    <span className="swatch-variation tint-1"></span>
                                </div>
                            </div>
                        </div>
                        <div className="swatch small outline colour-light">
                            <div className="swatch-inner">
                                <p className="swatch-title">Light</p>
                                <div role="group" className="swatch-variations">
                                    <span className="swatch-variation shade-1"></span>
                                    <span className="swatch-variation shade-2"></span>
                                    <span className="swatch-variation shade-3"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="style-guide-section || constrain-width">
                    <header className="style-guide-section-header">
                        <h2>Typography</h2>
                        <hr />
                    </header>

                    {/* Heading Styles: h1, h2, h3, h4, h5, h6 */}
                    <h1>Heading 1</h1>
                    <h1 className="primary">Heading 1 (Primary)</h1>
                    <h2>Heading 2</h2>
                    <h2 className="secondary">Heading 2 (Secondary)</h2>
                    <h3>Heading 3</h3>
                    <h4>Heading 4</h4>
                    <h5>Heading 5</h5>
                    <h6>Heading 6</h6>
                    <br />

                    {/* Paragraph: strong, em, a */}
                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                    <p>Ut wisi enim ad minim veniam, quis nostrud <strong>exerci tation ullamcorper suscipit</strong> lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit <em>in vulputate velit esse molestie</em> consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue.</p>
                    <p>Nihil imperdiet doming id <a href="#" title="Link">quod mazim placerat</a> facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                </section>

                <section className="style-guide-section || constrain-width">
                    <header className="style-guide-section-header">
                        <h2>Lists</h2>
                        <hr />
                    </header>

                    {/* Lists: strong, em, a */}
                    <h4>Unordered</h4>
                    <ul>
                        <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                        <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                        <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                        <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="#">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                    </ul>

                    <h4>Ordered</h4>
                    <ol>
                        <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                        <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                        <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                        <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="#">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                    </ol>
                </section>

                <section className="style-guide-section || constrain-width">
                    <header className="style-guide-section-header">
                        <h2>Line</h2>
                        <hr />
                    </header>

                    <h4>Default</h4>
                    <hr />

                    <h4>Small</h4>
                    <hr className="small" />
                </section>

                <section className="style-guide-section || constrain-width">
                    <header className="style-guide-section-header">
                        <h2>Blockquote</h2>
                        <hr />
                    </header>

                    {/* Blockquote */}
                    <blockquote>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet</p>
                        <p><em>Authors Name, Company</em></p>
                    </blockquote>
                </section>

                <section className="style-guide-section || constrain-width">
                    <header className="style-guide-section-header">
                        <h2>Icons</h2>
                        <hr />
                    </header>

                    <div className="style-guide-icons">
                        {/* Default Icons */}
                        <Icon name="arrow-right" />
                        <Icon name="building" />
                        <Icon name="capacity" />
                        <Icon name="chevron-capacity" />
                        <Icon name="chevron-right" />
                        <Icon name="close" />
                        <Icon name="cross" />
                        <Icon name="expand" />
                        <Icon name="download" />
                        <Icon name="email" />
                        <Icon name="facebook" />
                        <Icon name="height" />
                        <Icon name="linkedin" />
                        <Icon name="mail" />
                        <Icon name="menu" />
                        <Icon name="pdf" />
                        <Icon name="phone" />
                        <Icon name="pintrest" />
                        <Icon name="plus-capacity" />
                        <Icon name="plus" />
                        <Icon name="size" />
                        <Icon name="tick" />
                        <Icon name="tumblr" />
                        <Icon name="twitter" />
                        {/* Filter Icons */}
                        <Icon name="banquet" type="filter" />
                        <Icon name="boardroom" type="filter" />
                        <Icon name="cabaret" type="filter" />
                        <Icon name="classroom" type="filter" />
                        <Icon name="cocktail" type="filter" />
                        <Icon name="theatre" type="filter" />
                        <Icon name="u-shape" type="filter" />
                    </div>
                </section>

                <section className="style-guide-section | || constrain-width">
                    <header className="style-guide-section-header">
                        <h2>Buttons</h2>
                        <hr />
                    </header>

                    {/* Buttons: primary, secondary, disabled */}
                    <h4 className="style-guide-sub-heading">Default</h4>
                    <p>This is the default button style.</p>
                    <div role="group" className="style-guide-button-group">
                        <a className="button" href="#">Default</a>
                        <a className="button is-active" href="#">Active</a>
                        <a className="button is-disabled" href="#">Disabled</a>
                    </div>

                    <h4 className="style-guide-sub-heading">Primary</h4>
                    <p>This style is specifically for <strong>{'"searching"'}</strong> type button actions.</p>
                    <div role="group" className="style-guide-button-group">
                        <a className="button primary" href="#">Primary</a>
                        <a className="button primary is-active" href="#">Active</a>
                        <a className="button primary is-disabled" href="#">Disabled</a>
                    </div>

                    <h4 className="style-guide-sub-heading">Alternative</h4>
                    <p>This style is specifically for <strong>{'"enquiry"'}</strong> type button actions on <strong>light</strong> backgrounds.</p>
                    <div role="group" className="style-guide-button-group">
                        <a className="button alternative" href="#">Alternative</a>
                        <a className="button alternative is-active" href="#">Active</a>
                        <a className="button alternative is-disabled" href="#">Disabled</a>
                    </div>

                    <h4 className="style-guide-sub-heading">Alternative: Inverse</h4>
                    <p>This style is specifically for <strong>{'"enquiry"'}</strong> type button actions on <strong>dark</strong> backgrounds.</p>
                    <div role="group" className="style-guide-button-group dark">
                        <a className="button alternative inverse" href="#">Inverse</a>
                        <a className="button alternative inverse is-active" href="#">Active</a>
                        <a className="button alternative inverse is-disabled" href="#">Disabled</a>
                    </div>

                    <h4 className="style-guide-sub-heading">Block</h4>
                    <div role="group" className="style-guide-button-group">
                        <button className="button block" href="#">
                            Default
                            <Icon name="plus" />
                        </button>
                        <button className="button block primary" href="#">
                            Primary
                            <Icon name="plus" />
                        </button>
                    </div>

                    <h4 className="style-guide-sub-heading">Sizes</h4>
                    <div role="group" className="style-guide-button-group">
                        <a className="button large" href="#">Large</a>
                        <a className="button small" href="#">Small</a>
                    </div>

                    <h4 className="style-guide-sub-heading">Icon buttons</h4>
                    <div role="group" className="style-guide-button-group">
                        <div className="icon-button">
                            <span className="icon-button-text">Download</span>
                            <Icon name="download" />
                        </div>
                        <div className="icon-button primary">
                            <span className="icon-button-text">Download</span>
                            <Icon name="download" />
                        </div>
                        <div className="icon-button alternative">
                            <span className="icon-button-text">Download</span>
                            <Icon name="download" />
                        </div>
                    </div>
                    <h4 className="style-guide-sub-heading">Icon buttons (small)</h4>
                    <div role="group" className="style-guide-button-group">
                        <div className="icon-button small">
                            <Icon name="plus" />
                        </div>
                        <div className="icon-button primary small">
                            <Icon name="plus" />
                        </div>
                    </div>
                </section>

                <section className="style-guide-section || constrain-width">
                    <header className="style-guide-section-header">
                        <h2>Constraint Widths</h2>
                        <hr />
                    </header>

                    {/* Constrain Widths */}
                    <div className="style-guide-lines">
                        <p className="style-guide-line || constrain-width no-pad x-large">X Large</p>
                        <p className="style-guide-line || constrain-width no-pad large">Large</p>
                        <p className="style-guide-line || constrain-width no-pad">Default</p>
                        <p className="style-guide-line || constrain-width no-pad medium">Medium</p>
                        <p className="style-guide-line || constrain-width no-pad small">Small</p>
                    </div>
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>File Download</h2>
                        <hr />
                    </header>
                    <div className="content-blocks">
                        <FileDownload extension="JPG"
                            file="north-harbour-stadium-layout.jpg"
                            filename="north-harbour-stadium-layout.jpg"
                            filesize="986489" />
                        <FileDownload extension="JPG"
                            file="north-harbour-stadium-layout.jpg"
                            filename="north-harbour-stadium-layout-but-with-a-really-long-filename.jpg"
                            filesize="9864890000" />
                    </div>
                </section>

                <section className="style-guide-section">
                    <div className="constrain-width">
                        <header className="style-guide-section-header">
                            <h2>Section Title</h2>
                            <hr />
                        </header>
                        <header className="section-header">
                            <div className="section-header-inner">
                                <h2 className="section-header-title">Section Title</h2>
                                <p className="section-header-subtitle">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                            </div>
                        </header>
                        <header className="section-header center">
                            <div className="section-header-inner">
                                <h2 className="section-header-title">Section Title: Center</h2>
                                <p className="section-header-subtitle">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                            </div>
                        </header>
                    </div>
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Video Content Block</h2>
                        <hr />
                    </header>

                    {/* Video */}
                    <Video {...MOCK_DATA.VIDEO_PROPS}/>
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Text Content Block</h2>
                        <hr />
                    </header>

                    {/* Text */}
                    <Text {...MOCK_DATA.TEXT_PROPS} />

                    {/* Text – Aside */}
                    <Text {...MOCK_DATA.TEXT_ASIDE_PROPS} />

                    {/* Text – Quote */}
                    <Text {...MOCK_DATA.TEXT_QUOTE_PROPS} />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Image Gallery</h2>
                        <hr />
                        <p>The images should be 1400px x 640px (landscape, HQ).</p>
                        <p>Max size: 400kb.</p>
                    </header>

                    {/* Image Gallery */}
                    <Gallery {...MOCK_DATA.GALLERY_PROPS} />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Content Set</h2>
                        <hr />
                        <p>The tiles use Thumbnail images as backgrounds. In the CMS, please use images of size 640px x 960px (portrait, MQ) when selecting Thumbnail images for pages.</p>
                    </header>

                    {/* Content Set */}
                    <ContentSet {...MOCK_DATA.CONTENT_SET_PROPS} />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>FAQs</h2>
                        <hr />
                    </header>

                    {/* Faq */}
                    <Faq {...MOCK_DATA.FAQ_PROPS} />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Custom button</h2>
                        <hr />
                    </header>

                    {/* Custom button */}
                    <Button title="Enquire now" url="http://www.aucklandconventions.co.nz" />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Call to Action</h2>
                        <hr />
                        <p>The image should be 1920px x 1280px (min: 1600px x 500px) (landscape, HQ).</p>
                        <p>Max size: 400kb.</p>
                    </header>

                    {/* Call To Action */}
                    <CallToAction {...MOCK_DATA.CALL_TO_ACTION_PROPS} />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Pagebreak Quote Block</h2>
                        <hr />
                        <p>The image should be 640px x 960px (portrait, MQ).</p>
                        <p>Max size: 400kb.</p>
                    </header>

                    {/* Page Break Quote */}
                    <PagebreakQuote
                        cite="Somebody with a really long name, Somewhere with another long name"
                        file="https://unsplash.it/800/600"
                        quote="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Image carousel</h2>
                        <hr />
                        <p>The image should be 1920px x 1280px (min: 1400px x 600px) (landscape, HQ).</p>
                        <p>Max size: 400kb.</p>
                    </header>

                    {/* Carousel */}
                    <Carousel {...MOCK_DATA.CAROUSEL_PROPS} />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Hero Image Carousel</h2>
                        <hr />
                        <p>The image should be 1920px x 1280px (landscape, HQ).</p>
                    </header>

                    {/* Hero Carousel */}
                    <PageHeader {...MOCK_DATA.HERO_CAROUSEL_PROPS} />
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Location</h2>
                        <hr />
                    </header>

                    {/* Location */}
                    <Location {...MOCK_DATA.LOCATION_PROPS} />
                </section>

                <section className="style-guide-section" id="room-tiles">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Room Tiles</h2>
                        <hr />
                    </header>

                    <div className="tile-block || constrain-width">
                        <div className="tile-block-inner">
                            {MOCK_DATA.ROOM_TILES.map((item, index) => {
                                return (
                                    <Tile key={index} item={item} />
                                );
                            })}
                        </div>
                    </div>
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Stackla Widget</h2>
                        <hr />
                    </header>

                    {/* Stackla Widget */}
                    <StacklaWidget {...MOCK_DATA.STACKLA_WIDGET} />
                </section>

                <section className="style-guide-section" id="form-fields">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Form Fields</h2>
                        <hr />
                    </header>

                    <div className="form || constrain-width small">
                        <div className="field">
                            <label className="label" htmlFor="example-text-field">
                                <span className="label-static">Example Text Field</span>
                                <span className="label-validation">Example validation message</span>
                            </label>

                            <input
                                id="example-text-field"
                                type="text"
                                placeholder="Example Text Field" />
                        </div>

                        <div className="field">
                            <label className="label" htmlFor="example-email-field">
                                <span className="label-static">Example Email Field</span>
                            </label>

                            <input
                                id="example-email-field"
                                type="email"
                                placeholder="your-name@example.com" />
                        </div>

                        <div className="field">
                            <label className="label" htmlFor="example-phone-number">
                                <span className="label-static">Example Phone Number</span>
                            </label>

                            <input
                                id="example-phone-number"
                                type="tel" placeholder="Phone Number"/>
                        </div>

                        <div className="field">
                            <label className="label" htmlFor="example-textarea">
                                <span className="label-static">Example Textarea</span>
                            </label>

                            <textarea
                                id="example-textarea"
                                placeholder="Example Textarea" />
                        </div>

                        <div className="field">
                            <label className="label" htmlFor="example-multi-select">
                                <span className="label-static">Example Multi Select</span>
                            </label>

                            <MultiSelect
                                handleToggleValue={this.handleToggleValue.bind(this)}
                                isValid={true}
                                name="example-multi-select"
                                options={MOCK_DATA.ENQUIRY_DROPDOWN_OPTIONS}
                                placeholder="Multi Select Placeholder"
                                values={this.state.multiSelectValues}
                            />
                        </div>
                    </div>
                </section>

                <section className="style-guide-section">
                    <header className="style-guide-section-header || constrain-width">
                        <h2>Contact us card</h2>
                        <hr />
                        <p>The image should be 750px x 536px (min: 375px x 268px).</p>
                        <p>Max size: 300kb.</p>
                    </header>
                </section>
            </main>
        );
    }
}

export default StyleGuidePage;
