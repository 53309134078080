import ContentBlocks from 'app/components/content-blocks/index';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Page extends Component {
    render() {
        const {
            blocks,
            hero_cta_item_id: heroCtaUrl,
            hero_cta_title: heroCtaTitle,
            hero_type: heroType,
            hero_video: heroVideo,
            name: title,
            main_images: images,
            short_description: description
        } = this.props.data;

        return (
            <div className="content-page">
                <PageHeader
                    title={title}
                    introduction={description}
                    images={images}
                    heroCtaUrl={heroCtaUrl}
                    heroCtaTitle={heroCtaTitle}
                    heroType={heroType}
                    heroVideo={heroVideo}
                />
                <ContentBlocks data={blocks} />
            </div>
        );
    }
}

Page.propTypes = {
    data: PropTypes.object.isRequired
};

export default Page;
