const browserSupportsAllFeatures = window.Promise && window.fetch;

window.__ENV__.BROWSER_SUPPORTS_ALL_FEATURES = browserSupportsAllFeatures;

// scrollIntoView polyfill
if (!window.scrollIntoView) {
    require('smoothscroll-polyfill').polyfill();
}

// TODO: Code a better solution to load the polyfills only on IE
if (!browserSupportsAllFeatures) {
    require('core-js/stable');
    require('whatwg-fetch');
    require('svgxuse');
    console.info('Polyfills loaded'); // eslint-disable-line
}
