import classnames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const BUTTON_CLASS = 'button small';

class Faq extends Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            itemsOpen: null
        };
    }


    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.props.items) {
            this.setState(this.getInitialState);
        }
    }


    // Handler
    handleToggle(index) {
        let { itemsOpen } = this.state;
        const { items } = this.props;

        if (itemsOpen === index) {
            itemsOpen = null;
        } else {
            itemsOpen = index;

            /* ↓ Tracking FAQ question expansion events */
            dataLayerPush({
                id: 'micro_conversion',
                type: 'engaging_content',
                action: 'faq_click',
                text: items[itemsOpen].title
            });
            /* ↑ Tracking FAQ question expansion events */
        }

        this.setState({
            itemsOpen
        });
    }


    // Render
    render() {
        const { itemsOpen } = this.state;
        const { items } = this.props;

        if (items.length < 1) {
            return null;
        }

        // TODO: Heading should not be nested withing each item?
        const heading = items[0].heading === '' ? null : items[0].heading;

        return (
            <section className="faq-content-block || content-section || constrain-width">
                <div className="inner">
                    <div className="content">
                        <div className="accordion">
                            {heading && <h1 className="heading-1">{heading}</h1>}
                            {items.map((item, index) => {
                                const { content, title } = item;

                                const itemClassName = 'accordion-item';
                                const itemClass = classnames(itemClassName, {
                                    'is-active': itemsOpen === index
                                });

                                return (
                                    <div className={itemClass} key={index}>
                                        <button
                                            className={`${itemClassName}-header`}
                                            onClick={this.handleToggle.bind(this, index)}
                                        >
                                            <h5 className={`${itemClassName}-title`}>{title}</h5>
                                            <div className="icon-button small">
                                                <Icon name="plus" />
                                            </div>
                                        </button>
                                        <div className={`${itemClassName}-content`}>
                                            <div className="constrain-content" dangerouslySetInnerHTML={{ __html: formatContent(content, null, BUTTON_CLASS) }}></div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Faq.propTypes = {
    items: PropTypes.array.isRequired
};

export default Faq;
