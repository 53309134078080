import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import contactInformation from './contact-information';
import enquiryModal from './enquiry-modal';
import status from './status';
import strip from './strip';
import capacities, { epics as capacitiesEpics } from './capacities';
import enquiryForm, { epics as enquiryFormEpics } from './enquiry-form';
import enquiryModalForm, { epics as enquiryModalFormEpics } from './enquiry-modal-form';
import filter, { epics as filterEpics } from './filter';
import notifications, { epics as notificationsEpics } from './notifications';
import pages, { epics as pageEpics } from './page';
import rooms, { epics as roomsEpics } from './rooms';

export const epics = combineEpics(
    capacitiesEpics,
    enquiryFormEpics,
    enquiryModalFormEpics,
    filterEpics,
    notificationsEpics,
    pageEpics,
    roomsEpics
);

const createRootReducer = (routerReducer) => combineReducers({
    router: routerReducer,
    capacities,
    contactInformation,
    enquiryForm,
    enquiryModalForm,
    enquiryModal,
    filter,
    notifications,
    pages,
    rooms,
    status,
    strip
});

export default createRootReducer;
