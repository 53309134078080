import React from 'react';

const createFaqJsonLd = (pageData) => {
    const { blocks } = pageData;
    if (blocks) {
        const faqData = blocks.reduce((acc, block) => {
            if (block.type === 'faq') {
                acc.push(...block.data);
            }

            return acc;
        }, []);

        const mainEntity = faqData.map((item) => ({
            '@type': 'Question',
            'name': item.title,
            'acceptedAnswer':
            {
                '@type': 'Answer',
                'text': item.content
            }
        }));

        return {
            '@context': 'http://schema.org',
            '@type': 'FAQPage',
            mainEntity
        };
    }

    return '';
};


export default (data) => ( // eslint-disable-line react/display-name
    <div>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(createFaqJsonLd(data)) }}
        />
    </div>
);
