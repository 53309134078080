import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import { CAPACITY_RANGES, DEFAULT_CAPACITY_RANGE, EVENT_TYPES } from 'config/filter';

class SearchForm extends React.Component {
    renderEventTypeSelect() {
        const { eventType, setEventType } = this.props;

        return (
            <select
                name="event_type"
                id="event-type"
                aria-label="Event type"
                value={eventType}
                onChange={(event) => { setEventType(event.target.value); }}
            >
                {EVENT_TYPES.map((eventTypeItem, eventTypeItemIndex) => (
                    <option key={eventTypeItemIndex} value={eventTypeItem.value}>{eventTypeItem.label}</option>
                ))}
            </select>
        );
    }

    renderCapacityRangeSelect() {
        const { capacity, setCapacity } = this.props;

        return (
            <select
                name="capacity"
                id="capacity"
                aria-label="Capacity"
                value={capacity}
                onChange={(event) => { setCapacity(event.target.value); }}
            >
                <option value={DEFAULT_CAPACITY_RANGE.value}>{DEFAULT_CAPACITY_RANGE.label}</option>
                {CAPACITY_RANGES.map((capacityRange, capacityRangeIndex) => (
                    <option key={capacityRangeIndex} value={capacityRange.value}>{`${capacityRange.label} people`}</option>
                ))}
            </select>
        );
    }

    submit(event) {
        event.preventDefault();

        const {
            eventType,
            capacity,
            finishInitiateFilter,
            nagivateToFilterResults
        } = this.props;

        nagivateToFilterResults(eventType, capacity);
        finishInitiateFilter();
    }

    render() {
        const {
            eventType,
            capacity
        } = this.props;

        return (
            <form
                action="/filter"
                method="GET"
                className="search-form"
                onSubmit={this.submit.bind(this)}
            >
                <div className="search-form-label">I’m planning a</div>
                <div className="select-wrapper">
                    { this.renderEventTypeSelect() }
                    <label htmlFor="event-type" className="chevron">
                        <Icon name="chevron-right" />
                    </label>
                </div>
                <div className="search-form-label">for</div>
                <div className="select-wrapper">
                    { this.renderCapacityRangeSelect() }
                    <label htmlFor="capacity" className="chevron">
                        <Icon name="chevron-right" />
                    </label>
                </div>

                <button
                    type="submit"
                    className="button || find-button"
                    /* ↓ Tracking attributes */
                    data-id="micro_conversion"
                    data-type="lead"
                    data-action="search_widget_click"
                    data-text={JSON.stringify({ eventType, capacity })}
                    /* ↑ Tracking attributes */
                >Find</button>
            </form>
        );
    }
}

SearchForm.propTypes = {
    capacity: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
    nagivateToFilterResults: PropTypes.func.isRequired,
    setCapacity: PropTypes.func.isRequired,
    setEventType: PropTypes.func.isRequired,
    finishInitiateFilter: PropTypes.func.isRequired
};

export default SearchForm;
