import PropTypes from 'prop-types';
import React from 'react';
import YoutubePlayer from './youtube-player';

class PageHeaderVideo extends React.Component {
    render() {
        const { video } = this.props;

        return (
            <div className="page-header-video">
                <YoutubePlayer key={video} url={video} />
            </div>
        );
    }
}

PageHeaderVideo.propTypes = {
    video: PropTypes.string.isRequired,
};

export default PageHeaderVideo;
