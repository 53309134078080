import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

function TileIcon({ name, title, description }) {
    return (
        <span className="tile-icon">
            <Icon name={name} title={title} />{description}
        </span>
    );
}

TileIcon.propTypes = {
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default TileIcon;
