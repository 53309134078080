/* eslint-disable require-jsdoc */
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { getEventTypeAndCapacityFromFilterQueryString } from 'app/utilities/filter';
import { LOCATION_CHANGE } from 'redux-first-history';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    cache: [
        // {
        //     eventType: '... some event type ...',
        //     capacity: '... some capacity ...',
        //     layoutType: '... some capacity ...',
        //     filterResults: [ ... filter results ... ]
        // }
    ],
    eventType: '',
    capacity: '',
    layoutType: '',
    isLayoutTypeSelectorActive: true
};

// Actions
export const SET_EVENT_TYPE = 'rfa-conventions-website/filter/SET_EVENT_TYPE';
export const SET_LAYOUT_TYPE = 'rfa-conventions-website/filter/SET_LAYOUT_TYPE';
export const SET_LAYOUT_TYPE_SELECTOR = 'rfa-conventions-website/filter/SET_LAYOUT_TYPE_SELECTOR';
export const SET_CAPACITY = 'rfa-conventions-website/filter/SET_CAPACITY';
export const INITIATE_FILTER_RESULT_FETCH = 'rfa-conventions-website/filter/INITIATE_FILTER_RESULT_FETCH';
export const FILTER_RESULT_FETCH_SUCCESS = 'rfa-conventions-website/filter/FILTER_RESULT_FETCH_SUCCESS';

// Action Creators
export const setEventTypeAction = (eventType) => ({
    type: SET_EVENT_TYPE,
    eventType
});

export const setCapacityAction = (capacity) => ({
    type: SET_CAPACITY,
    capacity
});

export const setLayoutTypeAction = (layoutType) => ({
    type: SET_LAYOUT_TYPE,
    layoutType
});

export const setIsLayoutTypeSelectorAction = (isLayoutTypeSelectorActive) => ({
    type: SET_LAYOUT_TYPE_SELECTOR,
    isLayoutTypeSelectorActive
});

export const initiateFilterResultFetchAction = (eventType, capacity, layoutType) => ({
    type: INITIATE_FILTER_RESULT_FETCH,
    eventType,
    capacity,
    layoutType
});

export const filterResultFetchSuccessAction = (eventType, capacity, layoutType, filterResults) => ({
    type: FILTER_RESULT_FETCH_SUCCESS,
    eventType,
    capacity,
    layoutType,
    filterResults
});

// Reducer creator
export const createReducer = (getEventTypeAndCapacityFromFilterQueryString) => {
    return (state = INITIAL_STATE, action) => {
        switch (action.type) {
            case SET_EVENT_TYPE:
                return setEventType(state, action.eventType);
            case SET_CAPACITY:
                return setCapacity(state, action.capacity);
            case SET_LAYOUT_TYPE:
                return setLayoutType(state, action.layoutType);
            case SET_LAYOUT_TYPE_SELECTOR:
                return setLayoutTypeSelector(state, action.isLayoutTypeSelectorActive);
            case FILTER_RESULT_FETCH_SUCCESS:
                return filterResultFetchSuccess(state, action.eventType, action.capacity, action.layoutType, action.filterResults);
            case LOCATION_CHANGE:
                return setEventTypeAndCapacityIfNavigatedToFilterResultsPage(state, action.payload, getEventTypeAndCapacityFromFilterQueryString);
            default:
                return state;
        }
    };
};

// Reducers
export default createReducer(getEventTypeAndCapacityFromFilterQueryString);

function setEventType(state, eventType) {
    return {
        ...state,
        eventType
    };
}

function setCapacity(state, capacity) {
    return {
        ...state,
        capacity
    };
}

function setLayoutType(state, layoutType) {
    return {
        ...state,
        layoutType
    };
}

function setLayoutTypeSelector(state, isLayoutTypeSelectorActive) {
    return {
        ...state,
        isLayoutTypeSelectorActive
    };
}

function filterResultFetchSuccess(state, eventType, capacity, layoutType, filterResults) {
    return {
        ...state,
        cache: [
            ...state.cache,
            {
                eventType,
                capacity,
                layoutType,
                filterResults
            }
        ]
    };
}

function setEventTypeAndCapacityIfNavigatedToFilterResultsPage(state, payload, getEventTypeAndCapacityFromFilterQueryString) {
    const { location } = payload;

    if (location && location.pathname === '/filter') {
        const { eventType, capacity, layoutType } = getEventTypeAndCapacityFromFilterQueryString(location.search);

        return {
            ...state,
            eventType,
            capacity,
            layoutType
        };
    }

    return state;
}

// Epic creator

/**
 * @callback filterEndpoint
 * @param {string} eventType
 * @param {string} capacity
 * @returns {string} endpoint - generated filter endpoint
 */

/**
 * @callback formatFilterResults
 * @param {object} response
 * @returns {array} filterResults - filter result array
 */

/**
 * @callback filterResultFetchSuccessAction
 * @param {string} eventType
 * @param {string} capacity
 * @param {array} filterResults - filter result array
 * @returns {object} action
 */

/**
 * Creates Filter epic
 * @param {filterEndpoint} filterEndpoint - filter endpoint generator
 * @param {formatFilterResults} formatFilterResults - response formatter function
 * @param {filterResultFetchSuccessAction} filterResultFetchSuccessAction - action creator
 */
export const createFilterEpic = (filterEndpoint, formatFilterResults, filterResultFetchSuccessAction) => {
    return (action$) => action$.pipe(
        ofType(INITIATE_FILTER_RESULT_FETCH),
        mergeMap(({ eventType, capacity, layoutType }) => {
            /**
             To test mock the filter results, uncomment the following return
             statement.
             */
            // return new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(filterResultFetchSuccessAction(eventType, capacity, Math.random() < 0.5 ? [ // eslint-disable-line no-magic-numbers
            //             {
            //                 name: 'Venue 1',
            //                 slug: 'aotea-centre',
            //                 rooms: [
            //                     {
            //                         name: 'Aotea Centre',
            //                         slug: 'aotea-centre',
            //                         thumbnail: 'https://assets.aucklandlive.co.nz/assets/media/aoteacentre-hero.jpg',
            //                         short_description: 'A page.', // eslint-disable-line camelcase
            //                         capacity: [
            //                             20, // eslint-disable-line no-magic-numbers
            //                             200 // eslint-disable-line no-magic-numbers
            //                         ]
            //                     },
            //                     {
            //                         name: 'Rangitoto 1',
            //                         slug: 'rangitoto-1',
            //                         thumbnail: 'http://www.aucklandconventions.co.nz/PublishingImages/Rooms/ViaductEventsCentre/Rangitoto1/Rangitotoroom1_3.jpg',
            //                         short_description: 'Some enormous description text for the room. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description.', // eslint-disable-line camelcase
            //                         capacity: [
            //                             100, // eslint-disable-line no-magic-numbers
            //                             250 // eslint-disable-line no-magic-numbers
            //                         ]
            //                     },
            //                     {
            //                         name: 'Aotea Centre',
            //                         slug: 'aotea-centre',
            //                         thumbnail: 'https://assets.aucklandlive.co.nz/assets/media/aoteacentre-hero.jpg',
            //                         short_description: 'A page.', // eslint-disable-line camelcase
            //                         capacity: [
            //                             20, // eslint-disable-line no-magic-numbers
            //                             200 // eslint-disable-line no-magic-numbers
            //                         ]
            //                     },
            //                     {
            //                         name: 'Rangitoto 1',
            //                         slug: 'rangitoto-1',
            //                         thumbnail: 'http://www.aucklandconventions.co.nz/PublishingImages/Rooms/ViaductEventsCentre/Rangitoto1/Rangitotoroom1_3.jpg',
            //                         short_description: 'Some enormous description text for the room. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description.', // eslint-disable-line camelcase
            //                         capacity: [
            //                             100, // eslint-disable-line no-magic-numbers
            //                             250 // eslint-disable-line no-magic-numbers
            //                         ]
            //                     }
            //                 ]
            //             },
            //             {
            //                 name: 'Venue 2',
            //                 slug: 'aotea-centre',
            //                 rooms: [
            //                     {
            //                         name: 'Aotea Centre',
            //                         slug: 'aotea-centre',
            //                         thumbnail: 'https://assets.aucklandlive.co.nz/assets/media/aoteacentre-hero.jpg',
            //                         short_description: 'A page.', // eslint-disable-line camelcase
            //                         capacity: [
            //                             20, // eslint-disable-line no-magic-numbers
            //                             200 // eslint-disable-line no-magic-numbers
            //                         ]
            //                     },
            //                     {
            //                         name: 'Rangitoto 1',
            //                         slug: 'rangitoto-1',
            //                         thumbnail: 'http://www.aucklandconventions.co.nz/PublishingImages/Rooms/ViaductEventsCentre/Rangitoto1/Rangitotoroom1_3.jpg',
            //                         short_description: 'Some enormous description text for the room. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description.', // eslint-disable-line camelcase
            //                         capacity: [
            //                             100, // eslint-disable-line no-magic-numbers
            //                             250 // eslint-disable-line no-magic-numbers
            //                         ]
            //                     },
            //                     {
            //                         name: 'Aotea Centre',
            //                         slug: 'aotea-centre',
            //                         thumbnail: 'https://assets.aucklandlive.co.nz/assets/media/aoteacentre-hero.jpg',
            //                         short_description: 'A page.', // eslint-disable-line camelcase
            //                         capacity: [
            //                             20, // eslint-disable-line no-magic-numbers
            //                             200 // eslint-disable-line no-magic-numbers
            //                         ]
            //                     },
            //                     {
            //                         name: 'Rangitoto 1',
            //                         slug: 'rangitoto-1',
            //                         thumbnail: 'http://www.aucklandconventions.co.nz/PublishingImages/Rooms/ViaductEventsCentre/Rangitoto1/Rangitotoroom1_3.jpg',
            //                         short_description: 'Some enormous description text for the room. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description. More text. More description.', // eslint-disable-line camelcase
            //                         capacity: [
            //                             100, // eslint-disable-line no-magic-numbers
            //                             250 // eslint-disable-line no-magic-numbers
            //                         ]
            //                     }
            //                 ]
            //             }
            //         ] : []));
            //     }, 1000); // eslint-disable-line no-magic-numbers
            // });
            return (
                fetch(filterEndpoint(eventType, capacity, layoutType))
                    .then((response) => {
                        return filterResultFetchSuccessAction(eventType, capacity, layoutType, formatFilterResults(response));
                    })
                    .catch(() => {
                        // TODO: Add proper error handling
                        return filterResultFetchSuccessAction(eventType, capacity, layoutType, []);
                    })
            );
        })
    );  
};

// Epics
const filterEpic = createFilterEpic(
    ENDPOINTS.FILTER,
    formatFilterResults,
    filterResultFetchSuccessAction
);

// Helpers
/**
 * Format the API response
 * @param  {object} response - response from API
 * @return {array}           - list of filter results
 */
export function formatFilterResults(response) {
    if (response && response.data && response.data.length) {
        return response.data;
    }

    return [];
}

export const epics = combineEpics(filterEpic);
