import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

class CallToAction extends React.Component {
    render() {
        const { content, file } = this.props.data;

        return (
            <section className="call-to-action">
                <div className="call-to-action-inner">
                    <div className="call-to-action-media || preserve-aspect-ratio" style={{ backgroundImage: `url(${file})` }}>
                        <div className="preserve-aspect-ratio-media">
                            <img className="fixed-image" src={file} alt={file} />
                        </div>
                    </div>
                    <div className="call-to-action-content || rte-content light">
                        <div className="inner" dangerouslySetInnerHTML={{ __html: formatContent(content, 'call-to-action') }}></div>
                    </div>
                </div>
            </section>
        );
    }
}

CallToAction.propTypes = {
    data: PropTypes.object.isRequired
};

export default CallToAction;
