/* eslint-disable require-jsdoc */
import { connect } from 'react-redux';
import { initiateFilterResultFetchAction } from 'app/ducks/filter';
import RelatedRooms from './related-rooms';
import { resetEnquiryFormAction } from 'app/ducks/enquiry-modal-form';
import { selectIsVisible } from 'app/selectors/enquiry-modal';
import {
    selectCapacity,
    selectEventType,
    selectFilterResults,
    selectIsBusy
} from 'app/selectors/filter';
import {
    setSelectedRoomAction,
    setSelectedStepAction,
    toggleModalAction
} from 'app/ducks/enquiry-modal';

function mapStateToProps(state) {
    return {
        eventType: selectEventType(state),
        capacity: selectCapacity(state),
        isBusy: (eventType, capacity) => selectIsBusy(eventType, capacity, null, state),
        getFilterResults: (eventType, capacity) => selectFilterResults(eventType, capacity, null, state),
        isModalVisible: selectIsVisible(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initiateFilter: (eventType, capacity) => {
            dispatch(initiateFilterResultFetchAction(eventType, capacity));
        },
        setSelectedRoom: (selectedRoom) => dispatch(setSelectedRoomAction(selectedRoom)),
        setSelectedStep: (selectedStep) => dispatch(setSelectedStepAction(selectedStep)),
        toggleModal: () => dispatch(toggleModalAction()),
        resetForm: () => dispatch(resetEnquiryFormAction())
    };
}

const RelatedRoomsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { pure: true }
)(RelatedRooms);

export default RelatedRoomsContainer;
