import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import { LAYOUT_TYPES } from 'config/capacities';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class VenueCapacitiesBlockRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingRooms: false
        };
    }


    // Handlers
    showRoomsOrCloseButtonClick() {
        const { showingRooms } = this.state;
        this.setState({ showingRooms: !showingRooms });
    }


    // Render
    render() {
        const { name, capacities, squareMeters, height, slug } = this.props;
        const { showingRooms } = this.state;

        return (
            <div className={classnames('venue-capacities-grid-room venue-capacities-grid-row', { 'is-active': showingRooms })}>
                <div className="venue-capacities-grid-text-cell">
                    <div className="venue-capacities-grid-text-cell-inner">
                        <Link to={`/rooms/${slug}`}>
                            {name}
                            <Icon name="chevron-capacity" />
                        </Link>
                        <div className="venue-capacities-grid-room-meta-desktop">
                            Size: <span className="venue-capacities-grid-room-meta-desktop-value">{squareMeters ? `${squareMeters}m²` : '–'}</span><br />
                            Ceiling height: <span className="venue-capacities-grid-room-meta-desktop-value">{height ? `${height}m` : '–'}</span>
                        </div>
                    </div>
                </div>
                {capacities.map((capacity, capacityIndex) => (
                    <div
                        key={capacityIndex}
                        className="venue-capacities-grid-cell"
                    >
                        <div className="venue-capacities-grid-cell-label">
                            <div className="venue-capacities-grid-cell-label-inner">
                                <Icon name={LAYOUT_TYPES[capacityIndex].toLowerCase()} type="filter" />
                                {LAYOUT_TYPES[capacityIndex]}
                            </div>
                        </div>
                        <div className="venue-capacities-grid-cell-value">
                            <div className="venue-capacities-grid-cell-value-inner">{capacity || '–'}</div>
                        </div>
                    </div>
                ))}
                <div className="venue-capacities-grid-room-meta-mobile">
                    <div className="venue-capacities-grid-cell">
                        <div className="venue-capacities-grid-cell-label">
                            <div className="venue-capacities-grid-cell-label-inner">
                                <Icon name="size" />
                                Size
                            </div>
                        </div>
                        <div className="venue-capacities-grid-cell-value">
                            <div className="venue-capacities-grid-cell-value-inner">{squareMeters ? `${squareMeters}m²` : '–'}</div>
                        </div>
                    </div>
                    <div className="venue-capacities-grid-cell">
                        <div className="venue-capacities-grid-cell-label">
                            <div className="venue-capacities-grid-cell-label-inner">
                                <Icon name="height" />
                                Ceiling height
                            </div>
                        </div>
                        <div className="venue-capacities-grid-cell-value">
                            <div className="venue-capacities-grid-cell-value-inner">{height ? `${height}m` : '–'}</div>
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={this.showRoomsOrCloseButtonClick.bind(this)}
                    className="venue-capacities-grid-button || button primary"
                >
                    <Icon name="plus-capacity" />
                    {showingRooms ? 'Close' : 'Show capacity'}
                </button>
            </div>
        );
    }
}

VenueCapacitiesBlockRoom.propTypes = {
    name: PropTypes.string.isRequired,
    capacities: PropTypes.array.isRequired,
    squareMeters: PropTypes.any.isRequired,
    height: PropTypes.any.isRequired,
    slug: PropTypes.string.isRequired
};

export default VenueCapacitiesBlockRoom;
