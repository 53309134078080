/* eslint-disable require-jsdoc */
require('./polyfills');
const fromJS = require('immutable').fromJS;
const getApp = require('app/app').default;
const ReactDOM = require('react-dom');

initialise();

/*
    Main function
 */
function initialise() {
    const initialState = window.__INITIAL_STATE__;
    initialState.contactInformation = fromJS(initialState.contactInformation);
    initialState.pages = fromJS(initialState.pages);
    initialState.status = fromJS(initialState.status);
    const app = getApp('client', null, initialState).app;

    ReactDOM.hydrate(app, document.getElementById('root'));
}
