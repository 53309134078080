import classnames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

class Gallery extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        this.imageCarousel = undefined;
        this.lightboxImageCarousel = undefined;
    }

    getInitialState() {
        return {
            currentItem: 0,
            lightboxItem: -1
        };
    }


    // Lifecycle
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.imageCarousel && nextProps.items !== this.props.items) {
            this.imageCarousel.slickGoTo(0);
            if (this.lightboxImageCarousel) {
                this.lightboxImageCarousel.slickGoTo(0);
            }
            this.setState(this.getInitialState);
        }
    }


    // Helper
    isNextDisabled() {
        return false;
    }

    isPreviousDisabled() {
        return false;
    }


    // Handler
    handleNext() {
        if (this.isNextDisabled()) {
            return false;
        }

        const { items } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'gallery_click',
            text: items[currentItem].file
        });
        /* ↑ Tracking gallery scroll events */

        this.imageCarousel.slickNext();
        if (this.lightboxImageCarousel) {
            this.lightboxImageCarousel.slickNext();
        }
    }

    handlePrevious() {
        if (this.isPreviousDisabled()) {
            return false;
        }

        const { items } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'gallery_click',
            text: items[currentItem].file
        });
        /* ↑ Tracking gallery scroll events */

        this.imageCarousel.slickPrev();
        if (this.lightboxImageCarousel) {
            this.lightboxImageCarousel.slickPrev();
        }
    }

    goToSlide(index) {
        const { items } = this.props;
        const { currentItem } = this.state;

        /* ↓ Tracking gallery scroll events */
        dataLayerPush({
            id: 'micro_conversion',
            type: 'engaging_content',
            action: 'gallery_click',
            text: items[currentItem].file
        });
        /* ↑ Tracking gallery scroll events */

        this.imageCarousel.slickGoTo(index);
        if (this.lightboxImageCarousel) {
            this.lightboxImageCarousel.slickGoTo(index);
        }
    }


    // Render
    renderLightboxItem() {
        const { items } = this.props;
        const { lightboxItem } = this.state;

        return (
            <div className={classnames('lightbox', { 'is-active': lightboxItem >= 0 })}>
                <Slider
                    className="lightbox-image-carousel"
                    ref={(me) => { this.lightboxImageCarousel = me; }}
                    {...IMAGE_CAROUSEL_CONFIG}
                    infinite={items.length > 1}
                    beforeChange={(...args) => {
                        if (lightboxItem >= 0) {
                            this.setState({ currentItem: args[1] });
                            this.imageCarousel.slickGoTo(args[1]);
                        }
                    }}
                >
                    {items.map(({ file }, index) => {
                        return (
                            <div key={index}>
                                <div
                                    className="lightbox-image-carousel-item"
                                    style={{
                                        backgroundImage: `url(${file})`
                                    }}
                                />
                            </div>
                        );
                    })}
                </Slider>
                {items.length > 1 &&
                    <button
                        className={`icon-button || lightbox-button previous ${this.isPreviousDisabled() ? 'is-disabled' : ''}`}
                        onClick={this.handlePrevious.bind(this)}
                    >
                        <Icon name="arrow-right" title="Previous" />
                    </button>
                }
                {items.length > 1 &&
                    <button
                        className={`icon-button || lightbox-button next ${this.isNextDisabled() ? 'is-disabled' : ''}`}
                        onClick={this.handleNext.bind(this)}
                    >
                        <Icon name="arrow-right" title="Next" />
                    </button>
                }
                <button
                    type="button"
                    className="icon-button large || lightbox-button close"
                    onClick={() => {
                        this.setState({ lightboxItem: -1 });
                    }}
                >
                    <Icon name="close" />
                </button>
            </div>
        );
    }

    render() {
        const { items } = this.props;
        const { currentItem, lightboxItem } = this.state;

        const onlyOneItem = items.length === 1;

        return (
            <section className="gallery || block-section light">
                <div className="gallery-inner || constrain-width no-pad">
                    <Slider
                        className="image-carousel"
                        ref={(me) => { this.imageCarousel = me; }}
                        {...IMAGE_CAROUSEL_CONFIG}
                        infinite={!onlyOneItem}
                        beforeChange={(...args) => {
                            if (lightboxItem < 0) {
                                this.setState({ currentItem: args[1] });
                                if (this.lightboxImageCarousel) {
                                    this.lightboxImageCarousel.slickGoTo(args[1]);
                                }
                            }
                        }}
                    >
                        {items.map(({ file, link, title }, index) => {
                            return (
                                <div key={index}>
                                    <div
                                        className="image-carousel-item"
                                        style={{
                                            backgroundImage: `url(${file})`,
                                            position: 'relative'
                                        }}
                                    >
                                        <a
                                            className="image-carousel-item-inner"
                                            href={link}
                                            title={title}
                                            onClick={(event) => {
                                                if (index !== currentItem) {
                                                    event.preventDefault();
                                                    this.goToSlide(index);
                                                }
                                            }}
                                        >
                                            <span>{title}</span>
                                        </a>
                                        {index === currentItem && (
                                            <button
                                                type="button"
                                                className="icon-button large || image-carousel-item-expand"
                                                onClick={() => {
                                                    this.setState({ lightboxItem: index });
                                                }}
                                                title="Expand"
                                            >
                                                <Icon name="expand" title="Expand" />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                    <div className="caption-carousel">
                        {items.map(({ creditline }, index) => {
                            return creditline && (
                                <div key={index} className={`caption-carousel-item ${currentItem === index ? 'is-active' : ''}`}>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: creditline }}></div>
                                </div>
                            );
                        })}
                        {items.length > 1 &&
                            <button
                                className={`icon-button || caption-carousel-change-slide previous ${this.isPreviousDisabled() ? 'is-disabled' : ''}`}
                                onClick={this.handlePrevious.bind(this)}
                            >
                                <Icon name="arrow-right" title="Previous" />
                            </button>
                        }
                        {items.length > 1 &&
                            <button
                                className={`icon-button || caption-carousel-change-slide next ${this.isNextDisabled() ? 'is-disabled' : ''}`}
                                onClick={this.handleNext.bind(this)}
                            >
                                <Icon name="arrow-right" title="Next" />
                            </button>
                        }
                        {items.length > 1 &&
                            <div className="caption-carousel-buttons">
                                {items.map((item, index) => {
                                    return (
                                        <button
                                            key={index}
                                            className={`slide-button ${currentItem === index ? 'is-active' : ''}`}
                                            onClick={this.goToSlide.bind(this, index)}>
                                        </button>
                                    );
                                })}
                            </div>
                        }
                    </div>
                    {this.renderLightboxItem()}
                </div>
            </section>
        );
    }
}

Gallery.propTypes = {
    items: PropTypes.array.isRequired
};

const IMAGE_CAROUSEL_CONFIG = {
    accessibility: false, // We are using native keyboard navigation instead of arrows
    adaptiveHeight: false,
    adaptiveWidth: true,
    dots: false,
    draggable: true,
    pauseOnHover: true,
    speed: 600, // Set in CSS
    swipe: true,
    usecSS: true,
    arrows: false
};

export default Gallery;
