import AUCKLAND_UNLIMITED_LOGO from 'static/images/auckland-unlimited-logo-black.svg';
import classnames from 'classnames';
import conventionsLogo from 'static/images/auckland-conventions-logo-dark.svg';
import Icon from 'app/components/partials/icon';
import { NAVIGATION_FOOTER } from 'config/navigation';
import { partners } from '../../../config/partners';
import PropTypes from 'prop-types';
import React from 'react';
import WomenRefuge from './women-refuge';
import { Link, NavLink } from 'react-router-dom';
import SOCIAL_LINKS, {
    CLICK_TRACKING_ATTRIBUTES
} from 'config/social';

function renderNavigationItem(item, index) { // eslint-disable-line require-jsdoc
    const { children, label, link } = item;

    return (
        <div className="list" role="group" key={index}>
            <NavLink className="title || heading-6" to={link}>{label}</NavLink>
            {children &&
                <div className="items">
                    {children.map(({ label, link }, subIndex) => {
                        return (
                            <NavLink
                                className="link"
                                to={link}
                                key={subIndex}>
                                {label}
                            </NavLink>
                        );
                    })}
                </div>}
        </div>
    );
}

function renderNavigation() { // eslint-disable-line require-jsdoc
    return (
        <section className="footer-navigation">
            <nav className="footer-navigation-inner || constrain-width" role="group">
                {NAVIGATION_FOOTER.map(renderNavigationItem)}
            </nav>
        </section>
    );
}

function renderSponsors() { // eslint-disable-line require-jsdoc
    return (
        <section className="footer-sponsors">
            <ul className="footer-sponsors-inner || constrain-width">
                <li className="footer-sponsors-list partners">
                    <h6 className="title">Our partners</h6>
                    <div className="items">
                        {partners.map(({ name, link, logo, isLandscape }) => {
                            return (
                                <a className="link" title={name} href={link} key={name} target="_blank" rel="noopener noreferrer">
                                    <img className={classnames('logo', { 'is-landscape': isLandscape })} src={logo} alt={name} />
                                </a>
                            );
                        })}
                    </div>
                </li>
            </ul>
        </section>
    );
}

function renderInformation(phoneNumberIsVisible, showPhoneNumber) { // eslint-disable-line require-jsdoc
    return (
        <section className="footer-information">
            <div className="footer-information-inner || constrain-width">
                <Link className="logo" to="/">
                    <img
                        className="image"
                        src={conventionsLogo}
                        width="145"
                        height="51"
                        alt="Auckland Conventions"
                        title="Auckland Conventions Logo"
                    />
                </Link>
                <div className="copyrights">
                    <p className="footer-information-enquiry || heading-6">
                        General enquiries:
                    </p>
                    {!phoneNumberIsVisible && (
                        <p className="footer-information-enquiry || heading-6">
                            <a // eslint-disable-line jsx-a11y/anchor-is-valid
                                href="#"
                                onClick={(event) => {
                                    event.preventDefault();
                                    showPhoneNumber();
                                }}
                                /* ↓ Tracking attributes */
                                data-id="macro_conversion"
                                data-type="lead"
                                data-action="phone_unhide"
                                data-text="NZ: +64 9 309 2677, AU: 1800 549 898"
                                /* ↑ Tracking attributes */
                            >Show phone number</a>
                        </p>
                    )}
                    {phoneNumberIsVisible && (
                        <p className="footer-information-enquiry || heading-6">
                            NZ:&nbsp;<a
                                className="phone"
                                href="tel:+6493092677"
                                /* ↓ Tracking attributes */
                                data-id="macro_conversion"
                                data-type="lead"
                                data-action="phone_click"
                                data-text="NZ: +64 9 309 2677"
                                /* ↑ Tracking attributes */
                            >+64 9 309 2677</a>
                        </p>
                    )}
                    {phoneNumberIsVisible && (
                        <p className="footer-information-enquiry || heading-6">
                            AU:&nbsp;<a
                                className="phone"
                                href="tel:1800549898"
                                /* ↓ Tracking attributes */
                                data-id="macro_conversion"
                                data-type="lead"
                                data-action="phone_click"
                                data-text="AU: 1800 549 898"
                                /* ↑ Tracking attributes */
                            >1800 549 898</a>
                        </p>
                    )}
                    <p>&copy;{new Date().getFullYear()} Auckland Conventions</p>
                    <p className="policy-links">
                        <Link className="link" to="/privacy-policy">Privacy Policy</Link>|
                        <Link className="link" to="/terms-and-conditions">Terms and Conditions</Link>
                    </p>
                    <div className="footer-information-social-media">
                        <p className="footer-information-link"
                            /* ↓ Tracking attributes */
                            {...CLICK_TRACKING_ATTRIBUTES}
                            data-text="events@aucklandconventions.co.nz"
                            /* ↑ Tracking attributes */
                        >
                            <Icon name="email-footer" />
                            <a className="link" href="mailto:events@aucklandconventions.co.nz">Email</a>
                        </p>
                        <p className="footer-information-link"
                            /* ↓ Tracking attributes */
                            {...CLICK_TRACKING_ATTRIBUTES}
                            data-text="facebook"
                            /* ↑ Tracking attributes */
                        >
                            <Icon name="facebook" />
                            <a className="link" href={SOCIAL_LINKS.facebook}>Facebook</a>
                        </p>
                        <p className="footer-information-link"
                            /* ↓ Tracking attributes */
                            {...CLICK_TRACKING_ATTRIBUTES}
                            data-text="linkedin"
                            /* ↑ Tracking attributes */
                        >
                            <Icon name="linkedin" />
                            <a className="link" href={SOCIAL_LINKS.linkedin}>LinkedIn</a>
                        </p>
                        <p className="footer-information-link"
                            /* ↓ Tracking attributes */
                            {...CLICK_TRACKING_ATTRIBUTES}
                            data-text="instagram"
                            /* ↑ Tracking attributes */
                        >
                            <Icon name="instagram" />
                            <a className="link" href={SOCIAL_LINKS.instagram}>Instagram</a>
                        </p>
                    </div>
                </div>
                <a className="logo rfa" href="https://www.aucklandunlimited.com/" title="View the Tātaki Auckland Unlimited
 website">
                    <img
                        src={AUCKLAND_UNLIMITED_LOGO}
                        alt="Tātaki Auckland Unlimited
"
                    />
                </a>
            </div>
            <WomenRefuge />
        </section>
    );
}

function Footer(props) { // eslint-disable-line require-jsdoc
    const { phoneNumberIsVisible, showPhoneNumber } = props;

    return (
        <footer className="footer">
            {renderNavigation()}
            {renderSponsors()}
            {renderInformation(phoneNumberIsVisible, showPhoneNumber)}
        </footer>
    );
}

Footer.propTypes = {
    phoneNumberIsVisible: PropTypes.bool.isRequired,
    showPhoneNumber: PropTypes.func.isRequired
};

export default Footer;
