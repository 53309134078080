import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import Tile from 'app/components/partials/tile';
import { getDataByEventType, getEventTypeAndCapacityFromFilterQueryString } from 'app/utilities/filter';
import React, { Component } from 'react';

/**
 * This component should only be used in a Room page.
 *
 * This is going to render related rooms based on the selected filter state values or
 * by the selected room page data. The following scenarios exist:
 * - If the Filter state has an eventType and capacity, the related rooms will be based
 * on these two values (rooms that match the selected event type and capacity, just like
 * the original filter-results-page component).
 *
 * - If the Filter has only an eventType selected, the related rooms will be based
 * on the current room's capacity value for this selected eventType.
 *
 * - If the Filter has only a capacity selected, the related rooms will be based only
 * on the selected capacity range.
 */
class RelatedRooms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            capacity: '',
            eventType: ''
        };

        this.resetEnquiryModal = this.resetEnquiryModal.bind(this);
    }


    // Lifecycle
    componentDidMount() {
        if (typeof window === undefined) return;

        /**
         * We use the selected capacity from the Filter when this is defined, otherwise, we fetch the
         * capacity of the selected room page based on the selected eventType (Filter).
         */
        const capacity = this.props.capacity || this.getRoomCapacityByEventType(this.props.selectedRoom, this.props.eventType);

        this.setState({
            capacity,
            eventType: this.props.eventType // Gets the selected eventType from the filter.
        }, () => {
            /**
             * Pay attention here:
             *
             * If the `filter` state is filled by SSR, then we don't need to dispatch query, otherwise, we HAVE TO
             * dispatch a new query, or it will present the WRONG rooms (the prev state which not match current
             * search or venues)
             */
            if (window.__INITIAL_STATE__ && window.__INITIAL_STATE__.filter) {
                // Make sure this condition only be trigger once !!!
                delete window.__INITIAL_STATE__.filter;

                // But we still need to check IF has no filter state situation, for example the case below will not
                // have filter state:
                // SSR "/rooms/lower-nzi" --> click on "Find" button --> code run to here --> remove the flag  --> no filter state !!!
                const { getFilterResults } = this.props;

                const filterResults = getFilterResults(this.state.eventType, this.state.capacity);
                if (!filterResults || filterResults.length <= 0) {
                    this.props.initiateFilter(this.state.eventType, this.state.capacity);
                }
            } else {
                this.props.initiateFilter(this.state.eventType, this.state.capacity);
            }
        });
    }

    /**
     * This should only apply whenever the user switchs to another room page.
     * This will set the new state based on the new room page capacity (if there
     * is no capacity selected on the Filter component).
     *
     * @param {Object} nextProps
     * @returns {void}
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedRoom.external_room_id !== nextProps.selectedRoom.external_room_id) {
            this.setState({
                eventType: this.props.eventType,
                capacity: this.props.capacity || this.getRoomCapacityByEventType(nextProps.selectedRoom, this.props.eventType)
            }, () => {
                this.props.initiateFilter(this.state.eventType, this.state.capacity);
            });
        }
    }


    /**
     * Returns the capacity of a room based on the the event type
     *
     * @param {Object} room - The room object
     * @param {String} eventType - The event type to filter that capacity
     * @returns {String} Room capacity - Min and Max capacity string ('50,100')
     */
    getRoomCapacityByEventType(room, eventType) {
        const data = getDataByEventType(room, eventType);

        return `${data.min},${data.max}`;
    }

    getMeetingLayoutTypes(eventTypes) {
        return eventTypes.find((eventType) => eventType.name.toLowerCase() === 'meeting').layout_types;
    }

    resetEnquiryModal(selectedRoom) {
        this.props.resetForm();
        this.props.setSelectedRoom(selectedRoom);
        this.props.setSelectedStep(0);
    }

    // Renders
    renderRoom(data, index, eventType, venue) {
        const {
            name,
            short_description: description,
        } = data;
        let enquireButton = null,
            meetingLayoutTypes = null;

        // Should only used for Enquiry Meeeting Modal.
        if (this.props.isModalVisible) {
            meetingLayoutTypes = eventType === 'meeting' ? this.getMeetingLayoutTypes(data.event_types) : [];
            meetingLayoutTypes = meetingLayoutTypes.sort((a, b) => a.name > b.name);
        }

        // Only on the filter results page we pass the layout type variable
        const { image, min, max } = getDataByEventType(data, eventType);

        const roomDetails = {
            title: name,
            capacity: { min, max },
            thumbnail: image,
            description,
            meetingLayoutTypes,
            venue,
            ...data
        };

        // Should only used for Enquiry Meeeting Modal.
        if (this.props.isModalVisible) {
            enquireButton = (eventType === 'meeting' ?
                <button
                    className="button alternative || tile-custom-button"
                    onClick={this.resetEnquiryModal.bind(this, roomDetails)}>
                    Enquire meeting room
                </button> : null);
        }

        return (
            <Tile
                key={index}
                item={{
                    type: 'room',
                    data
                }}
                {...roomDetails}
                customButton={enquireButton}
            />
        );
    }

    renderRooms(filterResults) {
        if (filterResults && filterResults.length) {
            const roomsList = [];

            /**
             * Run through each Venue and add each Venue's Room to the roomList array.
             * Also, this will avoid pushing the current room page into the roomList array.
             */
            filterResults.map((venue) => {
                Array.prototype.push.apply(roomsList, venue.attributes.rooms.filter((room) => {
                    room.venue = venue;

                    return this.props.selectedRoom.slug !== room.slug;
                }));
            });

            if (roomsList.length) {
                return (
                    <div className="tile-block-inner">
                        {roomsList.map((room, index) => this.renderRoom(room, index, this.state.eventType))}
                    </div>
                );
            }

            return this.renderNoResults();
        }

        return this.renderNoResults();
    }

    renderNoResults() {
        return (
            <div className="rooms-filter-empty">
                <p className="heading-3">
                    Sorry, there are no related rooms.
                </p>
            </div>
        );
    }

    renderLoader() {
        return (
            <Loader type="block primary || rooms-filter-empty" />
        );
    }

    render() {
        const { getFilterResults, isBusy, title } = this.props;
        const { capacity, eventType } = this.state;

        const rooms = getFilterResults(eventType, capacity);

        return (
            <section className="tile-block || block-section">
                <header className="section-header || rooms-filter || constrain-width">
                    <h2 className="heading-1">{title || 'Other Rooms'}</h2>
                </header>
                <div className="constrain-width">
                    {isBusy(eventType, capacity) ? this.renderLoader() : this.renderRooms(rooms)}
                </div>
            </section>
        );
    }
}

RelatedRooms.propTypes = {
    capacity: PropTypes.string,
    eventType: PropTypes.string,
    getFilterResults: PropTypes.func.isRequired,
    initiateFilter: PropTypes.func.isRequired,
    isBusy: PropTypes.func.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    selectedRoom: PropTypes.object.isRequired,
    setSelectedRoom: PropTypes.func.isRequired,
    setSelectedStep: PropTypes.func.isRequired,
    selected: PropTypes.object.isRequired,
    title: PropTypes.string,
    resetForm: PropTypes.func.isRequired,
};

export default RelatedRooms;
