import { createValidationDataObject } from 'app/utilities/form-validation';

export const FIELD_NAMES = {
    email: 'email',
    phone: 'phone',
    message: 'message',
    firstName: 'firstName',
    lastName: 'lastName',
    venues: 'venues',
    company: 'company',
    marketingOptIn: 'marketingOptIn'
};

export default [
    {
        name: FIELD_NAMES.firstName,
        label: 'First name*',
        placeholder: '',
        type: 'text',
        validator: (firstName) => {
            if (firstName) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.lastName,
        label: 'Last name*',
        placeholder: '',
        type: 'text',
        validator: (lastName) => {
            if (lastName) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.email,
        label: 'Email*',
        placeholder: '',
        type: 'email',
        validator: (email) => {
            if (email) {
                // https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
                if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) { // eslint-disable-line
                    return createValidationDataObject();
                }

                return createValidationDataObject(false, 'Not a valid email address');
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.company,
        label: 'Company',
        placeholder: '',
        type: 'text',
        validator: () => {
            return createValidationDataObject();
        }
    },
    {
        name: FIELD_NAMES.venues,
        label: 'Venues I am interested in',
        placeholder: 'You can choose multiple venues',
        type: 'multi-select',
        formatValue: (value) => {
            return value.join(', ');
        },
        validator: () => {
            return createValidationDataObject();
        }
    },
    {
        name: FIELD_NAMES.phone,
        label: 'Phone*',
        multiline: false,
        placeholder: '',
        type: 'tel',
        validator: (phone) => {
            if (phone) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.message,
        label: 'Message*',
        placeholder: '',
        type: 'multi-line',
        validator: (message) => {
            if (message) {
                return createValidationDataObject();
            }

            return createValidationDataObject(false, 'This is a required field');
        }
    },
    {
        name: FIELD_NAMES.marketingOptIn,
        label: 'Yes, keep me updated with information from Auckland Conventions, Venues & Events.',
        type: 'checkbox',
        validator: () => createValidationDataObject()
    }
    // The form also includes the hidden "origin" field.
];
