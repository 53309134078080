import React from 'react';

export default (props) => ( // eslint-disable-line react/display-name
    <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
        <title>
            LinkedIn
        </title>
        <path fill="currentColor" d="M29.6 21.517v7.016h-3.659v-6.546c0-1.644-.528-2.766-1.853-2.766-1.01 0-1.611.755-1.876 1.487-.097.262-.122.625-.122.992v6.833h-3.659s.05-11.087 0-12.235h3.66v1.734l-.025.04h.025v-.04c.486-.832 1.353-2.021 3.297-2.021 2.407 0 4.212 1.748 4.212 5.506zM14.604 10.4c-1.252 0-2.07.913-2.07 2.114 0 1.174.794 2.114 2.022 2.114h.023c1.277 0 2.07-.94 2.07-2.114-.024-1.2-.793-2.114-2.045-2.114zM12.75 28.533h3.657V16.298H12.75v12.235z" />
    </svg>
);
