import { clientEnv } from 'config/env';
import geoMarkerPng from 'static/images/geo-marker.png';
import PropTypes from 'prop-types';
import React from 'react';
import { defaultZoom, options } from 'config/maps';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

const geoMarkerSvg = 'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIj4NCiAgICA8cGF0aCBmaWxsPSIjQ0U5NzJEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC41IDM3QzEzLjUgMjUuNjcyIDEwIDE4LjExIDEwIDE0LjMxMiAxMCA4LjYxNyAxNC43MDEgNCAyMC41IDRTMzEgOC42MTcgMzEgMTQuMzEzQzMxIDE4LjEwOCAyNy41IDI1LjY3MiAyMC41IDM3em0wLTE3YTUuNSA1LjUgMCAxIDAgMC0xMSA1LjUgNS41IDAgMCAwIDAgMTF6Ii8+DQo8L3N2Zz4=';

function Map({ showMarker, lat, lng, zoom = defaultZoom }) {
    const markerIcon = clientEnv.BROWSER_SUPPORTS_ALL_FEATURES ? geoMarkerSvg : geoMarkerPng;

    return (
        <GoogleMap
            zoom={zoom}
            center={{ lat, lng }}
            defaultOptions={{ styles: options }}
        >
            {showMarker &&
                <Marker
                    icon={{ url: markerIcon }}
                    position={{ lat, lng }}
                />
            }
        </GoogleMap>
    );
}

Map.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    showMarker: PropTypes.bool,
    zoom: PropTypes.number
};

export default withScriptjs(withGoogleMap(Map));
