/* eslint-disable require-jsdoc */
export function selectFieldTouched(state, fieldName, step) {
    return state.enquiryModalForm[step][fieldName].touched;
}

export function selectFieldValue(state, fieldName, step) {
    return state.enquiryModalForm[step][fieldName].value;
}

export function selectSubmitted(state) {
    return state.enquiryModalForm.submitted;
}

export function selectIsBusy(state) {
    const { enquiryModalForm } = state;

    return enquiryModalForm.submitted && enquiryModalForm.submittedSuccessfully === undefined;
}

export function selectSubmittedSuccessfully(state) {
    return state.enquiryModalForm.submittedSuccessfully;
}
