export function selectIsBusy({ rooms }) {
    const { cache, eventType } = rooms;

    return !cache.hasOwnProperty(eventType);
}

export function selectEventType({ rooms }) {
    return rooms.eventType;
}

export function selectRoomsResults(eventType, { rooms }) {
    const { cache } = rooms;

    if (cache.hasOwnProperty(eventType)) {
        return cache[eventType];
    }

    return [];
}
